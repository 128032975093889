import React from 'react';

const LeftArrow = () => {
	return (
		<svg viewBox="0 0 24 24">
			<path d="M11.707 4.293C11.8945 4.48053 11.9998 4.73484 11.9998 5C11.9998 5.26516 11.8945 5.51947 11.707 5.707L6.41403 11H20C20.2652 11 20.5196 11.1054 20.7071 11.2929C20.8947 11.4804 21 11.7348 21 12C21 12.2652 20.8947 12.5196 20.7071 12.7071C20.5196 12.8946 20.2652 13 20 13H6.41403L11.707 18.293C11.8892 18.4816 11.99 18.7342 11.9877 18.9964C11.9854 19.2586 11.8803 19.5094 11.6948 19.6948C11.5094 19.8802 11.2586 19.9854 10.9964 19.9877C10.7342 19.99 10.4816 19.8892 10.293 19.707L3.29303 12.707C3.10556 12.5195 3.00024 12.2652 3.00024 12C3.00024 11.7348 3.10556 11.4805 3.29303 11.293L10.293 4.293C10.4806 4.10553 10.7349 4.00021 11 4.00021C11.2652 4.00021 11.5195 4.10553 11.707 4.293Z"></path>
		</svg>
	);
}

export default LeftArrow;