import React, { Component } from 'react';

import './../scss/ShoppingCartCard.scss';

import PropTypes from 'prop-types';

import ProductQuantity from './ProductQuantity';
import StaticQuantity from './StaticQuantity';

import { ShoppingCartContext } from './ShoppingCartContext';
import { debugLog } from '../../util/logUtils';
import TrashFilled from '../svg/Trash/TrashFilled';


// import {getRelativeUrl, randomImg} from './../../util/lib.js';

class ShoppingCartCard extends Component {
	static propTypes = {
		cards: PropTypes.shape({
			layout: PropTypes.number,
			type: PropTypes.string,
			data: PropTypes.shape({
				item_id: PropTypes.string,
				product_id: PropTypes.string,
				product_key: PropTypes.string,
				name: PropTypes.string,
				quantity: PropTypes.string,
				price: PropTypes.string,
				price_formated: PropTypes.string,
				shipping_price: PropTypes.string,
				shipping_price_formated: PropTypes.string,
				discount: PropTypes.string,
				thumbnail: PropTypes.string,
				update_quantity_link: PropTypes.shape({
					path: PropTypes.string,
					url: PropTypes.string,
				}),
				remove_from_cart_link: PropTypes.shape({
					path: PropTypes.string,
					url: PropTypes.string,
				})
			}),
			meta: PropTypes.shape({
				multipleQuantityYN: PropTypes.bool
			}),
		})
	}

	render() {
		const t = this,
			lProps = t.props,
			card = lProps.card,
			data = card.data,

			thumb = data.thumbnail,
			itemName = data.name,
			costFormated = data.price_formated,
			quantity = parseInt(data.quantity) || 0,
			productKey = data.product_key || '',
			itemID = data.item_id || '',

			updateQuantityObj = data.update_quantity_link || {},
			updateQuantityURL = updateQuantityObj.url,
			removeObj = data.remove_from_cart_link || {},
			removeURL = removeObj.url,

			meta = card.meta,
			multipleQuantity = meta.multipleQuantityYN || false;

		debugLog(lProps);

		return (
			<ShoppingCartContext.Consumer>
				{({ modifyCart }) => {
					return (<div className="v-card shopping-cart-card">
						<div className="container">
							<div className="row">
								<div className="col-3 cart-item-image">
									<img src={thumb} alt={itemName} />
								</div>
								<div className="col-9">
									<div className="cart-item-name">{itemName}</div>
									<div className="cart-item-total">{costFormated}</div>

									{(multipleQuantity && updateQuantityURL) ? <ProductQuantity quantity={quantity} onChange={quantity => {
										debugLog(productKey, itemID, quantity);
										modifyCart(updateQuantityURL, { productKey, itemID, quantity }, (resp) => {
											// debugLog(resp);
										});
									}} /> : <StaticQuantity quantity={quantity} />}
								</div>
							</div>
						</div>
						{removeURL ? <div className="cart-item-remove vt-btn-like" onClick={() => {
							modifyCart(removeURL, { productKey, itemID, val: -1 }, (resp) => {
								debugLog(resp);
							});
						}}>
							<TrashFilled />
						</div> : ''}
					</div>);
				}}
			</ShoppingCartContext.Consumer>
		);
	}
}

export default ShoppingCartCard;

