import React, { useEffect } from "react";
import { getEnvVar } from "../../../util/lib";

const SEO = (props = {}) => {
  const defaultTitle = getEnvVar("REACT_APP_TITLE") || "",
    defaultDescription = getEnvVar("REACT_APP_DESCRIPTION") || "",
    defaultKeywords = getEnvVar("REACT_APP_KEYWORDS") || "",
    defaultImage = `${getEnvVar("REACT_APP_SITE_DOMAIN")}/site_img.png`,
    { seo = {} } = props, // @raja .. let default value be an object or error in line number 12~15
    {
      title = defaultTitle,
      pageTitle = "",
      description = defaultDescription,
      keywords = defaultKeywords,
      image = defaultImage,
    } = seo || {}; // @raja .. just in case

  console.log("SEO widget:", description);

  useEffect(
    () => {
      const titleElements = document.getElementsByTagName("title");
      if (titleElements.length > 0 && title) {
        titleElements[0].innerHTML = title;
      }
    },
    [title]
  );

  return (
    <>
      {/* {pageTitle && <h1 className="background-seo-element">{pageTitle}</h1>}
      {description && <p className="background-seo-element">{description}</p>} */}
    </>
  );
};

export default SEO;
