import React from 'react';
import User from '../../svg/User/User';

const UserBox = () => {
	return (
		<div className="user-box">
			<User></User>
		</div>
	);
};

export default UserBox;