import { useEffect } from 'react'; // Vodshow
// import { GDPRStore } from '../../../contexts/gdprContext/GDPRStore';
import { isGDPREnabled, isOtherTechEnabledSibbo } from '../../../util/gdprUtils';
import { setL } from '../../../util/lib';
import loadJSTag from '../../../util/loadJSTag';
import { debugLog } from '../../../util/logUtils';

// let gaMeasurementIDMod;

const G4_LS_KEY = 'g4_tracker',
	GOOGLE_TAG_MANAGER = 'https://www.googletagmanager.com/gtag/js',
	initReactGA = (gaMeasurementID = '') => {
		loadJSTag(GOOGLE_TAG_MANAGER).then(() => {
			window.dataLayer = window.dataLayer || [];
			window.gtag = function () {
				window.dataLayer.push(arguments);
			};
			window.gtag('js', new Date());
			window.gtag('config', gaMeasurementID, {
				'transport_type': 'beacon'
			});
			// gaMeasurementIDMod = gaMeasurementID;
		});
	};

const GA4Tracker = (props = {}) => {
	const gaMeasurementID = props.gaMeasurementID || '',
		gdprEnabled = isGDPREnabled(),
		googleSibboConfig = isOtherTechEnabledSibbo("google");

	useEffect(() => {
		debugLog('GA4Tracker: gdprEnabled updated: ', gdprEnabled);

		if (gdprEnabled) {
			window['gtag_enable_tcf_support'] = true;
			if (!googleSibboConfig) {
				return;
			}
		}
		initReactGA(gaMeasurementID);
	}, [gdprEnabled, googleSibboConfig]);


	return (null);
};
export default GA4Tracker;

/**
	* This method can be used to dispatch a GA4 'event' tracker
	* @param action The event action to be tracked. Deafult value is an empty string.
	* @param category The category the event action will be groupt to. Deafult value is 'User'.
	* @param value Additioanl value to be sent with the event.
	* @return undefined
*/
export const ga4Event = (event = '', params = '', trackOnReload = false) => {
	if (trackOnReload) {
		const store = { event, params };
		setL(G4_LS_KEY, store);
	} else {
		const ga4 = (window && window.ga4) ? window.ga4 || '' : '',
			gtag = window.gtag || ga4.gtag || '';

		if (gtag) {
			gtag('event', event, params);
		}
	}
};
