import React, { Component, useState } from 'react';

import { HeaderContext } from './HeaderContext';
import { getUrlFromBaseAPI, getSettingsAPI, getAPIData } from '../../../util/lib';

// //Uncomment to hardcode 
// import {MITU} from "./../../../demoData/quadrado/Mitu"
// const demoNavData = MITU;

// class HeaderManager extends Component {
// 	constructor (props) {
// 		super(props);

// 		this.unLoad = () => {
// 			if (this.state.widget) {
// 				this.setState({
// 					widget: '',
// 				});
// 			}
// 		};
// 		this.load = () => {
// 			const navBarURL = getUrlFromBaseAPI('nav_bar');
// 			getSettingsAPI()
// 				.then((settings) => {
// 					this.setState({settingsData: settings}, () => {
// 						if (navBarURL) {
// 							getAPIData(navBarURL, {}, (response = {}) => {
// 								const data = response.data || {},
// 									widget = data.widget || {};

			
// 								if (widget.type === 'NavBar') {
// 									const meta = widget.meta || {},
// 										data = widget.data || {},
// 										siteLogo = this.state.siteLogo || data.site_logo || {};

// 									this.setState({
// 										siteLogo,
// 										widget,
// 										canShowShoppingCartYN: !(!meta.canShowShoppingCartYN)
// 									});
// 								}
// 							});
// 						}
// 					});
// 				}).catch(() => {
// 					console.error("Unable to get Settings Data");
// 				});
// 		};
// 		this.hide = () => {
// 			const hideCount = this.state.hideCount += 1;
// 			this.setState({hideCount})
// 		}
// 		this.setPageMeta = (pageMeta = {}) => {
// 			if (pageMeta !== this.state.pageMeta) {
// 				this.setState({pageMeta});
// 			}
// 		};
// 		this.setPath = (page = '') => {
// 			if (page !== this.state.page) {
// 				this.setState({page});
// 			}
// 		}

// 		this.setPageLoading = (val = false) => {
// 			const pageLoading = !(!val);

// 			if (pageLoading !== this.state.pageLoading) {
// 				this.setState({pageLoading});
// 			}
// 		}
		
// 		this.state = {
// 			isWebview: props.isWebview,
// 			page: props.page || '',
// 			pageMeta: {},
// 			videoDetails: {}, 
// 			siteLogo: '',
// 			widget: {},
// 			settingsData: '',
// 			canShowShoppingCartYN: false,
// 			hideCount: 0,
// 			pageLoading: false,
// 			setPath: this.setPath,
// 			setPageLoading: this.setPageLoading,
// 			setPageMeta: this.setPageMeta,
// 			unLoad: this.unLoad,
// 			load: this.load,
// 			hide: this.hide,
// 		}
// 	}
	

// 	render () {
// 		return (
// 			<HeaderContext.Provider value={this.state}>
// 				{this.props.children}
// 			</HeaderContext.Provider>
// 		);
// 	}
// }

const HeaderManager = (props = {}) => {
	const [ pageMeta, setPageMeta ] = useState({});
	const [ siteLogo, setSiteLogo ] = useState('');
	const [ widget, setWidget ] = useState('');
	const [ settingsData, setSettingsData ] = useState('');
	const [ canShowShoppingCartYN, setCanShowShoppingCartYN ] = useState(false);
	const [ hideCount, setHideCount ] = useState(0);
	const [ pageLoading, setPageLoading ] = useState(false);
	const [ firstPageLoaded, setFirstPageLoaded ] = useState(false);
	const [ headerLoaded, setHeaderLoaded ] = useState(false);
	const [ path, setPath ] = useState(false);
	const [ hidden, setHidden ] = useState(false);

	return (
		<HeaderContext.Provider value={{
			isWebview: props.isWebview,
			page: props.page || '',
			pageMeta,
			setPageMeta,
			videoDetails: {},
			siteLogo,
			widget,
			settingsData,
			canShowShoppingCartYN,
			hideCount,
			headerLoaded,
			pageLoading,
			setPageLoading,
			firstPageLoaded,
			setFirstPageLoaded,
			path,
			setPath,
			unLoad: () => {
				setWidget('');
			},
			load: () => {
				if (!widget) {
					const navBarURL = getUrlFromBaseAPI('nav_bar');
				
					getSettingsAPI()
						.then((settings = '') => {
							setSettingsData(settings);
							if (navBarURL) {
								getAPIData(navBarURL, {}, (response = {}) => {
									const data = response.data || {},
										widgetL = data.widget || {};

				
									if (widgetL.type === 'NavBar') {
										const meta = widgetL.meta || {},
											data = widgetL.data || {},
											siteLogoL = siteLogo || data.site_logo || {};

										setSiteLogo(siteLogoL);
										setWidget(widgetL);
										setCanShowShoppingCartYN(!(!meta.canShowShoppingCartYN));
										setHeaderLoaded(true);
									}
								});
							}
						}).catch(() => {
							console.error("Unable to get Settings Data");
						});
				}
			},
			hide: () => {
				setHideCount(hideCount + 1);
			},
			hidden,
			hideHeader: (value = true) => {
				setHidden(!(!value));
			}

		}}>
			{props.children}
		</HeaderContext.Provider>
	);
}

export default HeaderManager;


// this.state = {
// 	isWebview: props.isWebview,
// 	page: props.page || '',
// 	pageMeta: {},
// 	videoDetails: {}, 
// 	siteLogo: '',
// 	widget: {},
// 	settingsData: '',
// 	canShowShoppingCartYN: false,
// 	hideCount: 0,
// 	hideHeader: this.hideHeader,
// 	hidden: false,
// 	pageLoading: false,
// 	setPath: this.setPath,
// 	setPageLoading: this.setPageLoading,
// 	setPageMeta: this.setPageMeta,
// 	unLoad: this.unLoad,
// 	load: this.load,
// 	hide: this.hide,
// }

// // hideHeader: this.hideHeader,
// // hidden: false,
	
// this.hideHeader = (value = true) => {
// 	this.setState({ hidden: (value) })
// }
// this.hide = () => {
// 	const hideCount = this.state.hideCount += 1;
// 	this.setState({ hideCount })
// }