import React, { createContext, useEffect, useState } from 'react';
import { getAPIData, getUrlFromBaseAPI } from '../../../util/lib';

export const SideMenuContext = createContext();

const SideMenuManager = ({ children }) => {
	const sideMenuURL = getUrlFromBaseAPI('side_menu'),
		[active, setActive] = useState(false),
		[open, setOpen] = useState(false),
		[primarySideMenu, setPrimarySideMenu] = useState(null);

	useEffect(() => {
		if (sideMenuURL) {
			getAPIData(sideMenuURL, {}, (response = {}) => {
				const wData = response.data || null;

				if (wData) {
					const widget = wData.widget || {},
						data = widget.data || {},
						primarySideMenuL = data.primarySideMenu || null;

					if (primarySideMenuL) {
						setActive(true);
					} else {
						setActive(false);
					}
					setPrimarySideMenu(primarySideMenuL);
				} else {
					setActive(false);
				}
			});
		}
	}, [sideMenuURL]);

	useEffect(() => {
		if (open) {
			const onClick = (e) => {
				console.log(e.target.classList.contains('sub-link'));
				if (!e.target.classList.contains('sub-link')) {
					setOpen(false);
				}
			};
			document.addEventListener('click', onClick);
			return () => {
				document.removeEventListener('click', onClick);
			};
		}
	}, [open]);


	return (<SideMenuContext.Provider value={{
		sideMenuData: {
			primarySideMenu,
			active,
			open,
			setOpenState: (val = false) => {
				setOpen(!(!val));
			}
		}
	}}>
		{children}
	</SideMenuContext.Provider>);
};

export default SideMenuManager;
