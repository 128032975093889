import React,{ useState } from 'react';
import Comments from '../../svg/Comments/Comments';
import VideoComments from './VideoComments';
import CommentField from './CommentField';
import { getUserDetails } from '../../../util/lib';
import '../../scss/videoComments/CommentBox.scss';

const COMMENTS = 'Comments',
	SHOW = 'Show',
	HIDE = 'Hide',
	NO_COMMENTS_MSG = `You have turned off ${COMMENTS} section`,
	processUserDetails = (userDetails = '') => {
		if (userDetails) {
			const data = userDetails.data || {},
				id = data.user_id || '',
				name = data.user_name || '';
			
			if (id && name) {
				return {id, name};
			}
		}
		return '';
	},
	CommentBox = (props = {}) => {
		
		const [showCommentsBox, setShowCommentsBox] = useState(true),
			fullScreen = props.fullScreen || false,
			user = processUserDetails(props.userDetails) || getUserDetails() || {},
			userName = user.nickname || user.name || '',
			userID = user.id || '';

		return (
			<div className={`comment-box${fullScreen ? ' full-screen' : ''}`}>
				{!fullScreen ? <div className="box-header">
					<div className="box-title">{COMMENTS}</div>
					<span className="switch-text" onClick={(evt) => {
						setShowCommentsBox(!showCommentsBox);
					}}>{(showCommentsBox ? HIDE : SHOW) + ' ' + COMMENTS}</span>
				</div> : null}
				<div className="box-body">
					{showCommentsBox ? <div className="show-comment">
						<div className="comment-list">
							<VideoComments data={props.data || {}} userID={userID}/>
						</div>
					<div className="comment-field-wrpr">	
						{<CommentField data={props.data || {}} userName={userName} userID={userID} videoID={props.videoID}/>}
					</div>
					</div> : <div className="hidden-comment">
						<div className="comments-icon"><Comments/></div>
						<div className="no-comments">{NO_COMMENTS_MSG}</div>
					</div>}
				</div>
			</div>
		);
	};



export default  CommentBox;