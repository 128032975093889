import React, { useState } from 'react';
import { ON_TOUCH_START } from './constants';

const TouchDetect = (Comp) => {
	return (props = {}) => {
		const [ touchEnabled ] = useState((ON_TOUCH_START in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0) || false);

		return <Comp {...{ ...props, touchEnabled}} />;
	};
};


export default TouchDetect;
