import React, { Component } from 'react';


import EmptyCartCard from './EmptyCartCard';
import ShoppingCartCard from './ShoppingCartCard';
import CartSummaryCard from './CartSummaryCard';

import './../scss/ShoppingCartContent.scss';

import PropTypes from 'prop-types';

class ShoppingCartContent extends Component {
	static propTypes = {
		title: PropTypes.string,
		cards : PropTypes.array
	}

	render() {
		const props = this.props,
			title = props.title || '',
			cards = props.cards || [];
		
		return (
			<span className="v-widget shopping-cart-content">
				<div className="widget-title col-12">
					<h2>{title}</h2>
				</div>
				<div className="col-12">
					{cards && cards.map((item, index) => {
						if (item.type === 'EmptyCartCard') {
							return (
								<EmptyCartCard key={'sc-c-' + index} card={item}/>
							);
						} 
						if (item.type === 'ShoppingCartCard') {
							
							return (
								<ShoppingCartCard key={'sc-c-' + index} card={item}/>
							);
						}
						if (item.type === 'CartSummaryCard') {
							return (
								<CartSummaryCard key={'sc-c-' + index} card={item}/>
							);
						}
						return false;
					})}
				</div>
			</span>
		);
	}
};

export default ShoppingCartContent;

