import React from 'react';
import { Link } from 'react-router-dom';
import { debugLog } from '../../../util/logUtils';
import { RouterContext } from './RouterContext';

const RouterLink = (props = {}) => {
	const { route, to, children, onClick, ...rest } = props;

	return (
		<RouterContext.Consumer>
			{({ setRoute }) => {
				return (<Link to={to} {...rest} onClick={(evt) => {
					setRoute(route || '');
					if (onClick) {
						onClick(evt);
					}
				}}>{children}</Link>);
			}}
		</RouterContext.Consumer>
	);
};

export default RouterLink;