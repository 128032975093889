import React, { useEffect, useState } from 'react';

import './scss/MiniEPGWrapper.scss';
import LeftArrowSlim from '../../svg/LeftArrow/LeftArrowSlim';
import RightArrowSlim from '../../svg/RightArrow/RightArrowSlim';
import MiniEPG from '../epgV3/miniEPG/MiniEPG';


const MiniEPGWrapper = (props = {}) => {
	const [open, setOpen] = useState(false),
		[epgShow, setEpgShow] = useState(false),
		[epgOpen, setEpgOpen] = useState(false);

	useEffect(() => {
		let timeout = window.setTimeout(() => {
			if (open) {
				setEpgOpen(true);
			} else {
				setEpgShow(false)
			}
		}, open ? 200 : 600);

		if (open) {
			setEpgShow(true)
		} else {
			setEpgOpen(false);
		}
		return () => {
			window.clearTimeout(timeout);
		}
	}, [open]);

	return (<div className='mini-epg-wrapper' >
		<div className='arrow-box' onClick={() => {
			setOpen(!open);
		}}>
			{open ? <RightArrowSlim /> : <LeftArrowSlim />}
		</div>
		<div className={`epg-container${epgShow ? ' show' : ''}${epgOpen ? ' open' : ''}`}>
			<MiniEPG {...props} onReload={() => {
				setOpen(false);
			}}/>
		</div>
	</div>);
}

export default MiniEPGWrapper;