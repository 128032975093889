import React from 'react';

export const ShoppingCartContext = React.createContext({
	shopEnabled: false,
	isOpen: false,
	animateIcon: false,
	items: 0,
	cartContent: {},
	open: () => {
		// futureRef
	},
	close: () => {
		// futureRef
	},
	modifyCart: () => {
		// futureRef
	},
	refreshCart: () => {
		// futureRef
	}
});