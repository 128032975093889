import React from 'react';

const Close = () => {
	return (
		<svg viewBox="0 0 24 24">
			<path d="M5.293 5.293c0.188-0.187 0.442-0.293 0.707-0.293s0.519 0.105 0.707 0.293l5.293 5.293 5.293-5.293c0.092-0.096 0.203-0.172 0.325-0.224s0.253-0.080 0.386-0.081c0.133-0.001 0.264 0.024 0.387 0.074s0.234 0.125 0.328 0.218 0.168 0.206 0.218 0.328c0.050 0.123 0.076 0.255 0.075 0.387s-0.029 0.264-0.081 0.386-0.129 0.232-0.224 0.325l-5.293 5.293 5.293 5.293c0.182 0.189 0.283 0.441 0.281 0.703s-0.108 0.513-0.293 0.698c-0.185 0.185-0.436 0.291-0.698 0.293s-0.515-0.099-0.703-0.281l-5.293-5.293-5.293 5.293c-0.189 0.182-0.441 0.283-0.703 0.281s-0.513-0.108-0.698-0.293c-0.185-0.185-0.291-0.436-0.293-0.698s0.099-0.515 0.281-0.703l5.293-5.293-5.293-5.293c-0.187-0.188-0.293-0.442-0.293-0.707s0.105-0.519 0.293-0.707z"></path>
		</svg>
	);
}

export default Close;