import React, { useState } from 'react';
import { SlideinStore } from './SlideinStore';



const SlideInProvider = ({ children }) => {

    const [currentSlidein, setCurrentSlideIn] = useState('');    
    return (
        <SlideinStore.Provider
            value={{
                currentSlidein,
                setCurrentSlideIn
            }}>
            {children}
        </SlideinStore.Provider>
    );
}

export default SlideInProvider;