import React, { useEffect, useState } from 'react';

import { HeaderContext } from './HeaderContext';
import ThemeWrapper, { EARTH } from '../factory/ThemeWrapper';

import Header from './Header';
import HeaderE from '../earth/HeaderE';
import PageLoaderAnimation from '../ui/PageLoaderAnimation';
import HeaderFactory from './HeaderFactory/HeaderFactory';
import { getSettingsAPI } from '../../../util/lib';
import { PAGE_TOP_PADDING, SITE_BODY } from '../util/pagePaddingHackDecorator';

let loaded = false;

const HeaderMaster = (props = {}) => {
	const [isHeaderV2, setIsHeaderV2] = useState(false),
		[settingsLoaded, setSettingsLoaded] = useState(false);

	useEffect(() => {
		getSettingsAPI().then((settings = {}) => {
			setSettingsLoaded(true);
			setIsHeaderV2(!(!settings.isHeaderV2));
		}).catch(() => {
			console.error("Unable to get Settings Data")
		});
	}, []);

	return (<>
		{settingsLoaded && <>
			{isHeaderV2 ? <HeaderContext.Consumer>
				{(headerContextData) => {
					const { pageLoading = false, widget = {}, page = '', pageMeta = {}, hidden = false } = headerContextData;

					if (widget.layout === 3) {
						const siteBody = document.querySelector(`.${SITE_BODY}`);

						if (siteBody) {
							siteBody.classList.add(PAGE_TOP_PADDING);
						}
					}

					return (<>
						{widget && widget.type === 'NavBar' && !hidden && <HeaderFactory data={widget} activePage={page} pageMeta={pageMeta} />}
						{pageLoading && <PageLoaderAnimation />}
					</>);

				}}
			</HeaderContext.Consumer> : <HeaderContext.Consumer>
				{({ page, isWebview, pageLoading, load, siteLogo, widget, pageMeta, videoDetails, settingsData, canShowShoppingCartYN, hideCount, hidden, hideHeader }) => {
					const theme = props.theme || '',
						HComp = (theme === EARTH) ? HeaderE : Header;

					if (!loaded) {
						loaded = true;
						load();
					}

					return (<>
						{!isWebview && siteLogo && <HComp
							page={page}
							settingsData={settingsData}
							siteLogo={siteLogo}
							header={widget}
							pageMeta={pageMeta}
							videoDetails={videoDetails}
							canShowShoppingCartYN={canShowShoppingCartYN}
							hideCount={hideCount}
						/>}
						{pageLoading && <PageLoaderAnimation />}
					</>);
				}}
			</HeaderContext.Consumer>}
		</>}
	</>);
}

export default ThemeWrapper(HeaderMaster);
