import React, { useLayoutEffect } from 'react';
import { getEnvVar } from '../../../util/lib';

const VersionAPI = (Comp) => {
	return (props = {}) => {

		useLayoutEffect(() => {
			window.__ventunoOTTWebGetVersion = () => {
				return `WEB APP VERSION ${getEnvVar('REACT_APP_VERSION')}`;
			}
		}, []);

		return <Comp {...{ ...props}} />;
	};
};


export default VersionAPI;
