import React from 'react';


const MinusCircleFilled = () => {
    return (
        <svg
            viewBox="0 0 418.953 418.953"
        >
            <g>
                <g>
                    <path d="M209.476,0C93.789,0,0,93.789,0,209.477s93.789,209.477,209.476,209.477c115.688,0,209.477-93.789,209.477-209.477
			S325.164,0,209.476,0z M348.133,252.508H70.82v-86.063h277.313V252.508z"/>
                </g>
            </g>
        </svg>
    );
};

export default MinusCircleFilled;