import React, { useState } from 'react';
import EpgStore from './EpgStore';

const EPGProviderV3 = ({ children }) => {

	const [epgActive, setEpgActive] = useState(false),
		[selectedCard, setSelectedCard] = useState(false),
		[refresh, setRefresh] = useState(false),
		[selectedTab, setSelectedTab] = useState(false);

	return (
		<EpgStore.Provider
			value={{
				epgActive,
				setEpgActive,
				setSelectedCard,
				selectedCard,
				refresh,
				setRefresh,
				selectedTab,
				setSelectedTab
			}}>
			{children}
		</EpgStore.Provider>
	)
}

export default EPGProviderV3