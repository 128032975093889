import React, { useContext, useEffect } from 'react';
import { GDPRStore } from '../../../contexts/gdprContext/GDPRStore';
import { LogoutUser } from '../../../util/authUtils';
import { getOtherVendors, getTcData, isOtherTechEnabledSibbo } from '../../../util/gdprUtils';
import { getAPIData, getUrlFromBaseAPI, isLoggedIn } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';
import { showNotification } from '../../../util/notification';

const GDPRMaster = (props = {}) => {

  const { setTcData,
    setOtherVendors,
    // tcData,
    // gdprEnabled,
    // otherVendors 
  } = useContext(GDPRStore);

  useEffect(() => {
    const tcfapi = window.__tcfapi || '';

    //getTcdata and store it in the context
    getTcData((payload) => {
      if (payload) {
        debugLog("test tcdata:", payload)
        if (payload.gdprApplies) {
          debugLog("gdprApplies:", payload.gdprApplies);
          setTcData(payload);
        }
      } else {
        return false;
      }
    });

    //get other vendors
    getOtherVendors((payload) => {
      if (payload) {
        debugLog("other vendors:", payload)
        setOtherVendors(payload);
      } else {
        return false;
      }
    });
    //set event listener to update tcdata when user reconfirms their choice
    if (tcfapi) {
      tcfapi('addEventListener', 2, cmpCallbackGenerator(setTcData, setOtherVendors));
    }
  }, []);

  //functions
  //Logout the user if the store data on device is rejected


  return (
    <></>
  )
}

const cmpCallbackGenerator = (setTcData = false, setOtherVendors = false) => {
  return (tcData = {}, success = false) => {
    //useractioncomplete will triggered if user has confirmed or reconfirmed their choice
    if (success && tcData.eventStatus === 'useractioncomplete') {
      if (setTcData) {
        setTcData(tcData);
      }
      //get other vendors since its not included in the payload of this callback
      getOtherVendors((payload) => {
        if (payload) {
          if (setOtherVendors) {
            setOtherVendors(payload);
          }
        }
      });
      const postData = {
        "gdprApplies": tcData.gdprApplies,
        "user_consent": tcData.tcString,
      }, actionUrl = getUrlFromBaseAPI('gdpr_consent');

      getAPIData(actionUrl,
        postData,
        (response) => {
          const { type: responseType = false } = response;
          if (responseType === 'Success') {
            logoutIfRequired(tcData);
            window.location.reload();
          } else {
            const { error = '' } = response;
            debugLog("responseData:", response);
            showNotification({ type: "error", text: error });
          }
        });
    }
  }
}

const logoutIfRequired = (tcData) => {
  const { publisher = {}, purpose = {}, gdprApplies: gdprEnabled = false } = tcData,
    { consents = {} } = publisher,
    { consents: purposeConsents = {} } = purpose;;
  if (gdprEnabled) {
    if (!consents['1'] || !purposeConsents['1'] || !isOtherTechEnabledSibbo("google")) {
      if (isLoggedIn()) {
        LogoutUser();
      }
    }
  }
};

export default GDPRMaster;