import React, { useEffect, useState } from 'react';
import { getAPIData, getUrlFromBaseAPI, isLoggedIn, setL, clearL } from '../../../util/lib';
import { showNotification } from '../../../util/notification';
import DotLoader from '../AnimatedComponents/DotLoader';
import './scss/CommonAuthHelper.scss';
import './scss/AutoDeviceConnect.scss';

const AutoDeviceConnect = (props = {}) => {
	const message = props.message || 'Connecting to Device ...',
		location = props.location || {},
		search = location.search || {},
		parseSearchQuery = React.useMemo(() => new URLSearchParams(search), [search]),
		displayCode = parseSearchQuery.get('display_code'),
		urlPathnameSearch = `${location.pathname}${location.search}`,
		[success, setSuccess] = useState(false),
		routeToConnect = () => {
			window.location.href = '/connect';
		};

	useEffect(() => {
		if (isLoggedIn()) {
			if (displayCode) {
				const deviceAutoConnectURL = getUrlFromBaseAPI("autoconnect"),
					postData = {
						display_code: displayCode
					};

				if (deviceAutoConnectURL) {
					getAPIData(deviceAutoConnectURL, postData, response => {
						const { type = '', data: responseData = {} } = response;

						if (type === 'Success') {
							const successMessage = responseData.message || 'Connected Successfully';

							showNotification({ type: "success", text: successMessage });
							clearL('postLoginRedirectUrl');
							setSuccess(true);
						} else {
							const { message: errMessage = "Something went wrong" } = responseData;

							showNotification({ type: "error", text: errMessage });
							clearL('postLoginRedirectUrl');
							window.location.href = '/connect';
						}
					});
				} else {
					showNotification({ type: "error", text: "Something went wrong" });
					clearL('postLoginRedirectUrl');
					window.location.href = '/connect';
				}
			} else {
				showNotification({ type: "error", text: "Something went wrong" });
				clearL('postLoginRedirectUrl'); 
				window.location.href = '/connect';
			}
		} else {
			const loginRedirect = () => {
				window.location.href = '/login'
			};

			let windowTimer = setTimeout(loginRedirect, 3500);

			setL("postLoginRedirectUrl", urlPathnameSearch); //
			showNotification({ type: "error", text: "Please login to authenticate" });

			return () => {
				clearTimeout(windowTimer);
				windowTimer = 0;
			};
		}
	}, [displayCode]);

	return (
		<div className='auto-device-connect'>
			{!success && <>
				<div className='fullpageloder'>
					<DotLoader />
					<p className="message">{message}</p>
				</div>
			</>}
			{success && <div className='auth-success-display'>
				<h2>Device Connected Successfully</h2>
				<button className="btn btn-lg vt-btn vt-btn-primary" onClick={routeToConnect}>Connect A New Device</button>
			</div>}
		</div>
	)
}

export default AutoDeviceConnect;