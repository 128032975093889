import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
// import { GDPRStore } from '../../../contexts/gdprContext/GDPRStore';
import { isGDPREnabled } from '../../../util/gdprUtils';
import { getL } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';

const initReactPixel = (fbpTrackerId = '') => {
	ReactPixel.init(fbpTrackerId);

	debugLog('%%%%%%%%%%%%%%%');
	debugLog(fbpTrackerId);
	debugLog('%%%%%%%%%%%%%%%');

	if (window) {
		window.ReactPixel = ReactPixel;
	}
},
	FBPixelTracker = (props = {}, prevProps = {}) => {
		const fbpTrackerId = props.fbpTrackerId || '',
			gdprEnabled = isGDPREnabled(),
			tcData = getL("cmp-tcdata");
		useEffect(() => {


			if (gdprEnabled) {
				const { purpose = {} } = tcData,
					{ consents = {} } = purpose;

				if (consents.hasOwnProperty(1)) {
					if (consents[1]) {
						initReactPixel(fbpTrackerId);
					}
				}
			} else {
				initReactPixel(fbpTrackerId);
			}
		}, [fbpTrackerId, gdprEnabled, tcData]);

		return <></>;
	};

export default FBPixelTracker;