import React, { Component } from 'react';

import './scss/FooterJ.scss';
import PrivacyCheck from '../PrivacyCheck';
import SocialLinksJ from './SocialLinksJ';
import FooterRequest from '../request/FooterRequest';
import FooterMenuJ from './FooterMenuJ';
import FooterLinksJ from './FooterLinksJ';

const processFooterData = (data = {}) => {
	const widgetsRaw = data.widgets || data.widget || [],
		widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [widgetsRaw];
			
	return widgets;
};

class FooterJ extends Component {
	render() {
		const props = this.props,
			widgets = processFooterData(props.footerData),
			{ settingsData } = props;

		return (<footer className="site-footer">
			{widgets.map((widget = {}, index) => {	
				return (<span key={'ftrw' + index}>
					{(widget.type === 'PrivacyCheck') && !props.termsConsentCheck && <PrivacyCheck widget={widget} settingsData={settingsData}/>}
					{(widget.type === 'SocialLinks') && <SocialLinksJ key={'ftrw' + index} widget={widget} settingsData={settingsData}/>}
					{(widget.type === 'FooterLinks') && <FooterLinksJ key={'ftrw' + index} widget={widget} settingsData={settingsData}/>}
					{(widget.type === 'FooterMenu') && <FooterMenuJ key={'ftrw' + index} widget={widget} settingsData={settingsData}/>}
				</span >);
			})}
		</footer>);
	}
}

export default FooterRequest(FooterJ);