import React from 'react';

import '../../scss/ui/CoverScreen.scss';

const CoverScreen = (props = {}) => {
	const onClick = props.onClick || '',
		show = !(!props.show);

	return (<div className={'vtn-cover-screen' + (show ? ' vt-vis' : '')} onClick={() => {
		if (typeof onClick === 'function') {
			onClick();
		}
	}} />);
}

export default CoverScreen;