
import React from 'react';
import Loadable from '@react-loadable/revised';

export const footerLayoutMap = {
	1: Loadable({ loader: () => import('./LayoutOne'), loading: () => <div></div> }),
	2: Loadable({ loader: () => import('./LayoutTwo'), loading: () => <div></div> }),
	3: Loadable({ loader: () => import('./LayoutThree'), loading: () => <div></div> }),
	4: Loadable({ loader: () => import('./LayoutFour'), loading: () => <div></div> }),
	5: Loadable({ loader: () => import('./LayoutFive'), loading: () => <div></div> }),
};