import React from 'react';
import '../../scss/earth/FooterE.scss';
import PrivacyCheck from '../PrivacyCheck';
import FooterMenuE from './FooterMenuE';
import SocialLinksE from './SocialLinksE';
import FooterLinksE from './FooterLinksE';
import FooterRequest from '../request/FooterRequest';
import { debugLog } from '../../../util/logUtils';

const processFooterData = (data = {}) => {
		const widgetsRaw = data.widgets || data.widget || [],
			widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [ widgetsRaw ];
		return widgets;
	},
	FooterE = (props = {}) => {
		const widgets = processFooterData(props.footerData);

		debugLog(widgets);

		return (
			<footer className="site-footer">
				{widgets.map((widget = {}, index = 0) => {
					return (
						<span key={'ftrw' + index}>
							{widget.type === 'PrivacyCheck' &&
							!props.termsConsentCheck && <PrivacyCheck widget={widget} />}
							{widget.type === 'SocialLinks' && <SocialLinksE key={'ftrw' + index} widget={widget} />}
							{widget.type === 'FooterLinks' && <FooterLinksE key={'ftrw' + index} widget={widget} />}
							{widget.type === 'FooterMenu' && <FooterMenuE key={'ftrw' + index} widget={widget} />}
						</span>
					);
				})}
			</footer>
		);
	};

export default FooterRequest(FooterE);
