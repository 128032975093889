const MouseFlow = (props) => {
	const propsData = props.data || {},
		data = propsData.data || {},
		site_id = data.site_id || "",
		mouseflowHtmlDelay = data.snapshot_delay || 0,
		site_url = "//cdn.mouseflow.com/projects/" + site_id + ".js";

	window.mouseflowHtmlDelay = mouseflowHtmlDelay;

	window._mfq = window._mfq || [];
	window._mfq.push(mouseflowHtmlDelay);
	const mf = document.createElement("script");
	mf.type = "text/javascript";
	mf.defer = true;
	mf.src = site_url;
	document.getElementsByTagName("head")[0].appendChild(mf);

	return null;

};

export default MouseFlow;