import React from 'react';
import { getButtonSizeClass } from '../../../../../util/classMapperUtils';

const buttonDecorators = (Compnenet = null) => {
    return (props = {}) => {
        const { buttonMeta = {} } = props,
            { size = true } = buttonMeta,
            sizeClass = getButtonSizeClass(size);
            
        return (Compnenet ? <Compnenet {...props}
            sizeClass={sizeClass} /> : null);
    }
};

export default buttonDecorators;
