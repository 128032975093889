import { createContext } from "react";

const EpgStoreV3 = createContext({
	epgActive: false,
	setEpgActive: false,
	refresh: false,
	setRefresh: false,
	selectedTab: false,
	setSelectedTab: false,
});

export default EpgStoreV3;