import React, { Component } from 'react';
import { getAPIData } from '../../../util/lib';
import Close from '../../svg/IconV2-2/Close';
import WidgetFactory from '../factory/WidgetFactory';
import './Popup.scss';

class Popup extends Component {
	state = {
		active: false,
		contentUrl: '',
		content: '',
		hash: '',
	}

	componentDidMount() {
		this.getContent();
	}

	componentDidUpdate() {
		const location = this.props.location || {},
			hash = location.hash || '';

		if (this.state.hash !== hash) {
			this.setState({ hash }, () => {
				const active = hash.indexOf('#' + this.props.id) !== -1,
					popup = document.querySelector('#' + this.props.id),
					popupClassList = popup ? popup.classList || '' : ''

				if (popup && this.state.active !== active) {
					this.setState({ active }, () => {
						if (this.state.active) {
							popup.style.display = 'block';
							if (popupClassList) {
								popupClassList.add('show');
							}
							// window.$('#' + this.props.id).modal("show");
						} else {
							popup.style.display = 'none';
							if (popupClassList) {
								popupClassList.remove('show');
							}
							// window.$('#' + this.props.id).modal("hide");
						}
					});
				}
			});
		}
	}

	//ge the popup content
	getContent = () => {
		// get the content of the popup
		const wData = this.props.data || {},
			data = wData.data || {},
			actionUrlObj = data.action_url || '',
			actionUrl = actionUrlObj.url || '',
			postData = actionUrlObj.url_params || {},
			widgets = data.widgets || '';

		if (actionUrl) {
			getAPIData(actionUrl, postData, (response) => {
				if (response.type === 'Page') {
					const responseData = response.data || {},
						wData = responseData.widgets || [];

					this.setState({ content: wData });
				}
			});
		} else if (widgets) {
			this.setState({ content: widgets });
		}
	}

	//on popup close handler
	onCloseClick = () => {
		const props = this.props,
			onCloseClick = props.onCloseClick;
			
		if (onCloseClick) {
			this.props.onCloseClick();
		}
	}

	render() {
		const props = this.props,
			id = props.id || '',
			additionalClass = this.props.additionalClass || '',
			size = props.size || 'lg';

		return (
			<div
				className="modal fade vt-modal"
				id={id}
				tabIndex="-1"
				role="dialog"
				data-backdrop={false}
				aria-labelledby={id}
				aria-hidden="true">
				<div
					className={`modal-dialog modal-${size} modal-dialog-centered ${additionalClass}`}
					role="document">
					<div className="modal-content">
						<div className="close-btn"
							onClick={this.onCloseClick}
						>
							<Close />
						</div>
						<div className="modal-body" data-toggle="modal">
							{(this.state.active && this.state.content) ? <WidgetFactory widgets={this.state.content} isInPopup={true}/> : <div></div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Popup;
