export const debounce = (func = '', delay = 300) => {
	if (func) {
		let timer;
		return function(...args) {
			const context = this;

			window.clearTimeout(timer);
			
			timer = window.setTimeout(() => {
				func.apply(context, args);
			}, delay);
		}
	} else {
		return () => {
		};
	}
};

export const throttle = (func = '', delay = 300) => {
	if (func) {
		let timer,
			canExe = true;

		return function(...args) {
			if (canExe) {
				const context = this;

				canExe = false;

				window.clearTimeout(timer);
				
				timer = window.setTimeout(() => {
					canExe = true;
				}, delay);
				
				func.apply(context, args);
			}
		}
	} else {
		return () => {
		};
	}
};