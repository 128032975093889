import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';
import 'noty/lib/themes/metroui.css';
// import 'animate.css/animate.min.css';
import Noty from 'noty';


export const showNotification = (data = {}) => {
	new Noty({
		theme: 'metroui',//s
		type: data.type ? data.type : 'error',
		layout: 'topRight',
		text: data.text,
		timeout: 6000, //Hardcoded Timeout changes for KweliTV Change this to api side ASAP and set default to 4000
		closeWith: ['click', 'button'],
		animation: {
			open: 'animated fadeInUp',
			close: 'animated fadeOutUpBig'
		}
	}).show();
}