import { Redirect } from "react-router-dom";
import React from 'react';

import { post } from './fetchRequest';
import { getApiDataV2 } from './apiUtils';
import { isToeknAvailable } from './authUtils';
import { debugLog } from "./logUtils";

let webSettings = false;
let baseAPIs = {};
export const ALL_APP_SETTINGS = 'all-app-settings';

export const arrayToChunks = (array, chunk = 4) => {
	let i, j, temparray, groupedCards = [];

	for (i = 0, j = array.length; i < j; i += chunk) {
		temparray = array.slice(i, i + chunk);
		groupedCards.push(temparray);
	}

	return groupedCards;
}

export const getRelativeUrl = (url) => {
	const urldetails = document.createElement('a');
	
	urldetails.href = url;
	return urldetails.pathname;
}

export const parseAPIResponse = (data = {}) => {
	return data.response || {};
}


export const getAPIData = (url, data, callback) => {
	debugLog("calling:", url);

	let user_details = getUserDetails();
	const postData = { ...data },
		vt_auth_token = getL('vt-auth-token') || {},
		{ token = "" } = vt_auth_token || {};

	postData.user_key = getUserKey();
	postData.app_version = getEnvVar('REACT_APP_API_VER') || '2'; //{app_version: '1.3.0'}

	if (!postData.user_id && user_details !== false) {
		// debugLog(user_details.id + ' ' + getL('webview_user_id')) // TODO ASYNC
		postData.user_id = user_details.id || getL('webview_user_id');
	}

	if (url.indexOf("ott/payment") !== -1 && url.indexOf("&planid=") === -1) {

		const planID = getL('payment_plan_id');
		const bundle_ids = getL('bundle_ids');
		const content_show_id = getL('show_id') || '',
			plan_id = getL('plan_id') || '',
			planid = getL('planid') || '',
			svod_plan_id = getL('svod_plan_id') || '',
			gift_card_id = getL('gift_card_id') || '',
			is_gift_card = getL('is_gift_card') || '';
		
		if (planID) {
			postData.planid = planID;
			postData.content_show_id = content_show_id;
		}
		if (bundle_ids) {
			postData.bundle_ids = bundle_ids;
		}
		if (plan_id) {
			postData.plan_id = plan_id;
		}
		if (planid) {
			postData.planid = planid;
		}
		if (svod_plan_id) {
			postData.svod_plan_id = svod_plan_id;
		}
		if (gift_card_id) {
			postData.gift_card_id = gift_card_id;
		}
		if (is_gift_card) {
			postData.is_gift_card = is_gift_card;
		}
	}
	if (url.indexOf("ott/stripePayment") !== -1 || url.indexOf("ott/payment") !== -1) {
		const videoID = getL('payment_plan_video_id'),
			start_date = getL('start_date') || '';

		if (videoID) {
			postData.video_id = videoID;
		} else {
			delete postData.video_id
		}
		if (start_date) {
			postData.start_date = start_date;
		}
	}

	if (getEnvVar('REACT_APP_USE_NEW_LOGIN')) {
		return getApiDataV2(url, postData, callback);
	}

	post(url, postData).then((response = {}) => {
		const parsedResponse = parseAPIResponse(response);
		callback(parsedResponse);
	}).catch((exception = '') => {
		debugLog('getApiData Failed:', exception);
	});
}

export const getUserDetails = () => {
	var user = getL('user');

	if (user && typeof (user) === 'object' && user.id) {
		return user;
	} else {
		clearL('user');
		return false;
	}
}

export const setUserDetails = (user = {}) => {
	if (user.user_id) {
		const active_services = user.user_active_services.map(function (a) {
			return a.plan_id
		});

		setL('user', {
			id: user.user_id,
			name: user.user_name,
			email: user.user_email,
			phone: user.user_phone,
			nickname: user.nick_name,
			active_services,
		});
	} else {
		return false;
	}
}

export const clearUserDetails = () => {
	resetUserKey();

	clearL('user');
	clearL('payment_plan_id');
	clearL('payment_plan_course_id');
	clearL('payment_plan_event_id');
	clearL('payment_plan_id_2'); //Arun - payment_plan_id_2 is an alternative to store plan id for Master Class
	clearL('payment_plan_video_id');
	clearL('deviceConnectFlow');
	clearL('start_date');
	clearL('show_id');
	clearL('category_guid');
	clearL('expert_id');
	clearL('class');
}

export const clearAutoPopupCooldown = () => {
	clearLC("AutoPopup");
}

export const isLoggedIn = () => {
	const user = getUserDetails(),
		tokenAvailable = isToeknAvailable();

	if ((user && user.id) || tokenAvailable) {
		return true;
	} else {
		return false;
	}
}

export const loginRequiredRedirect = () => {
	if (isLoggedIn()) {
		return;
	} else {
		return <Redirect push to='/login' />;
	}
}

export const redirectTo = ($url) => {
	return <Redirect push to={$url} />;
}

export function createLoginSession(userResponse = {}, redirectObj = {}) {
	const active_purchase_flow_plan_id = getL('payment_plan_id'),
		deviceConnectFlow = getL('deviceConnectFlow'),
		postLoginRedirectUrl = getL('postLoginRedirectUrl'),
		destinationPagePath = getL('destination_page_path'),
		redirectURL = redirectObj.path || '';

	debugLog('login session');
	debugLog(redirectObj);
	setUserDetails(userResponse.data || {});

	debugLog('FF@!', postLoginRedirectUrl, destinationPagePath);

	if (redirectURL) {
		window.location.href = redirectURL;
	} else if (deviceConnectFlow) {
		window.location.href = '/connect';
	} else if (active_purchase_flow_plan_id) {
		window.location.href = '/payment';
	} else if (postLoginRedirectUrl) {
		clearL('postLoginRedirectUrl');
		var user_details = getUserDetails();
		if (postLoginRedirectUrl === '/plans' && user_details.active_services.length > 0) {
			window.location.href = '/';
		} else {
			window.location.href = postLoginRedirectUrl;
		}
	} else if (destinationPagePath) {
		debugLog('<><><>', destinationPagePath);
		window.location.href = destinationPagePath;
	} else {
		window.location.href = '/';
	}
}

export function getAPIURL(api) {
	var domain = getEnvVar('REACT_APP_API_DOMAIN');
	var app_key = getEnvVar('REACT_APP_APP_KEY');
	var app_id = getEnvVar('REACT_APP_APP_ID');

	if (!domain || !app_key || !app_id) {
		debugLog('ENV ER: not set');
		return '';
	}

	return domain + '/v1/ott/' + api + '/' + app_key + '/' + app_id;
}

export function getEnvVar(key) {
	// debugLog('****************');
	// debugLog(process);
	// debugLog(process.env);
	// debugLog('****************');
	if (typeof process.env[key] !== 'undefined') {
		return process.env[key];
	}
	return false;
}

export function getLC(key) {
	var now = Date.now();
	var expiresIn = getL(key + '_expiresIn');
	if (expiresIn === undefined || expiresIn === null) {
		expiresIn = 0;
	}

	if (expiresIn < now) {
		clearLC(key);
		return false;
	} else {
		return getL(key);
	}
}

export function setLC(key, value, expires = null) {
	// Expects expires in Minutes
	if (expires === undefined || expires === null) {
		expires = (1 * 60); // Default 1 hour
	} else {
		expires = Math.abs(expires);
	}
	var now = Date.now();
	var schedule = now + expires * 1000 * 60;

	setL(key, value);
	setL(key + '_expiresIn', schedule);
}

export function clearLC(key) {
	clearL(key);
	clearL(key + '_expiresIn');
}

export function getLString(key) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '',
		data = localStorage ? localStorage.getItem(key) : null;
	if (data !== null) {
		return data;
	} else {
		return false;
	}
}

export function getL(key) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '',
		data = localStorage ? localStorage.getItem(key) : null;

	if (data !== null) {
		return JSON.parse(data);
	} else {
		return false;
	}
}

export function setL(key, value) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '';

	if (localStorage) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

export function clearL(key) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '';

	if (localStorage) {
		localStorage.removeItem(key);
	}

}

export function getS(key) {
	const win = (typeof window === 'object') ? window : {},
		sessionStorage = win.sessionStorage || '',
		data = sessionStorage ? sessionStorage.getItem(key) : null;

	if (data !== null) {
		return JSON.parse(data);
	} else {
		return false;
	}
}

export function setS(key, value) {
	const win = (typeof window === 'object') ? window : {},
		sessionStorage = win.sessionStorage || '';

	if (sessionStorage) {
		sessionStorage.setItem(key, JSON.stringify(value));
	}
}

export function clearS(key) {
	const win = (typeof window === 'object') ? window : {},
		sessionStorage = win.sessionStorage || '';

	if (sessionStorage) {
		sessionStorage.removeItem(key);
	}

}

export const parseQryString = (qry) => {
	var qry_obj = {};
	if (qry.substr(0, 1) === '?') {
		qry = qry.substr(1);
	}
	var items = qry.split('&');
	items.map((it) => {
		var para = it.split('=');
		qry_obj[para[0]] = para[1];
		return false;
	});
	return qry_obj;
}

export const generateRandomID = (digits = 4) => {
	const idDigits = parseInt(digits) || 4,
		charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	let id = '', i;

	for (i = 0; i < idDigits; i++) {
		id += charSet.charAt(Math.floor(Math.random() * charSet.length));
	}

	return id;
}

export const generateDID = () => {
	const id = `D-${new Date().getTime()}-${generateRandomID()}`;

	return id;
}

export const getRandomArbitrary = (min = 10000, max = 99999) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
}

const USER_KEY = 'user_key',
	makeUserKey = (keyLength = 14) => {
		const pKeyLength = parseInt(keyLength) || 14,
			ranNum = Math.floor(getRandomArbitrary(pKeyLength <= 4 ? 100 : 1, Math.pow(10, pKeyLength) - 1)),
			ranNumStr = String(ranNum),
			ranNumLength = ranNumStr.length,
			zeroString = String(Math.pow(10, pKeyLength - ranNumLength)).substr(1);

		return zeroString + ranNumStr;
	},
	resetUserKey = () => {
		// clear and reser user key
		clearL(USER_KEY);
		getUserKey();
	};

export const getUserKey = () => {
	const userKey = getL(USER_KEY) || makeUserKey();

	setL(USER_KEY, userKey);

	return userKey;
}

export const getThemeID = () => {
	const themeID = parseInt(getEnvVar('REACT_APP_THEME') || 1) || 1;

	return themeID;
}


export const scrollToTop = () => {
	window.scrollTo(0, 0);
}

export const getAllAppSettings = (postData = {}) => {
	const respondToPromise = (resolve, response = {}) => {
		const { web_settings: webSettingsL = '', base = {}, manifest = {} } = response;
				
		if (webSettingsL) {
			webSettings = webSettingsL;
		}
		
		baseAPIs = base;
		
		resolve(manifest);
	};

	return new Promise((resolve) => {
		const allAppSettings = getLC(ALL_APP_SETTINGS),
			allAppSettingsAPI = process.env.REACT_APP_ALL_SETTINGS_API_PATH;

		if (allAppSettings) {
			respondToPromise(resolve , allAppSettings);
			// since cache is a probelm incase clients make changs to color and otehr settings, 
			// the following is a work around. In case all app settings is cached, we fetch it after a timeout
			// and store it for the next round of refresh
			window.setTimeout(() => {
				getAPIData(allAppSettingsAPI, { ...postData }, (response = {}) => {
					setLC(ALL_APP_SETTINGS, response, 2 * 60); // 2 hours cache only
				});
			}, 5 * 1000);
		} else {
			
			
			getAPIData(allAppSettingsAPI, { ...postData }, (response = {}) => {
				setLC(ALL_APP_SETTINGS, response, 2 * 60); // 2 hours cache only
				respondToPromise(resolve , response);
			});
		}
	});
}

export const getSettingsAPI = () => {
	return new Promise((resolve) => {
		resolve(webSettings)
	});
}

export const getUrlFromBaseAPI = (key = '') => {
	if (typeof baseAPIs[key] !== "undefined") {
		return baseAPIs[key];
	} else {
		debugLog('BASE ER: ' + key + ' Not found');
		return false;
	}
}
