import React from 'react';

export const HeaderContext = React.createContext({
	isWebview: false,
	pageLoading: false,
	firstPageLoaded: false,
	headerLoaded: false,
	siteLogo: '',
	widget: {},
	pageMeta: {},
	videoDetails: {},
	settingsData: '',
	page: '',
	hideCount: 0,
	branding: {},
	primaryMenu: {},
	secondaryMenu: {},
	setPageMeta: () => {},
	setPageLoading: () => {},
	setFirstPageLoaded: () => {},
	setPath: () => {},
	unLoad: () => {},
	load: () => {},
	hide: () => {},
	hidden: false,
	hideHeader: () => {}
});