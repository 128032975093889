import React, { useState, useEffect } from 'react';
import { useInterval } from '../../../../util/hooksUtils';
import './scss/TimeProgressBar.scss';

const DELAY = 1000;

const timeToSeconds = (timeString = '') => {
	const [hours, minutes, seconds] = String(timeString).split(':');
	return (parseInt(hours) * 3600) + (parseInt(minutes) * 60);
}

const TimeProgressBar = ({ durationInSeconds = 0, elapsedInSeconds = 0, onProgressEndsCallback = false, setNeedsRefresh = false }) => {

	const [elapsedTime, setElapsedTime] = useState(elapsedInSeconds),
		[progressPercent, setProgressPercent] = useState('');

	//update timer on refresh
	useEffect(() => {
		setElapsedTime(elapsedInSeconds);
	}, [elapsedInSeconds, durationInSeconds])


	useEffect(() => {
		setProgressPercent((elapsedTime / durationInSeconds) * 100);
	}, [elapsedTime]);

	useInterval(() => {
		if (progressPercent < 100) {
			setElapsedTime(prevTime => prevTime + 1);
		}
	}, DELAY);

	useEffect(() => {
		let epgTimeout = 0,
			progressEnds = 0;

		if (progressPercent >= 100) {
			progressEnds = setTimeout(onProgressEnds, 3000);
		}
		if (setNeedsRefresh && progressPercent >= 100) {
			epgTimeout = setTimeout(() => {
				if(setNeedsRefresh){
					setNeedsRefresh(false);
				}
			}, 10000);
			return () => {
				clearTimeout(epgTimeout);
				epgTimeout = 0;
				clearTimeout(progressEnds);
				progressEnds = 0;
			}
		}

	}, [progressPercent]);

	const onProgressEnds = () => {
		if (onProgressEndsCallback) {
			onProgressEndsCallback();
		}
	}
	return (
		<div className="time-progress-bar">
			{/* <p style={{"color": "#fff"}}>{`${elapsedTime} ${durationInSeconds} ${progressPercent}`}</p> */}
			<div className="time-progress-bar-fill" style={{ width: `${progressPercent}%` }}></div>
		</div>
	);
};

export default TimeProgressBar;