import React from 'react';
import './scss/DotLoader.scss';

const DotLoader = (props = {}) => {
  const styleBackground = props.styleBackground || '';

  return (
    <div className={`dotloader ${styleBackground === "popup"? 'background':''}`} >
        <span></span>
        <span></span>
        <span></span>
    </div>
  );
}

export default DotLoader;