import { createContext } from "react";

const VideoPlayerStore = createContext({
	playerActive: '',
	setPlayerActive: false,
	commentBox: '',
	setCommentBox: false,
	videoDetails: {},
	setVideoDetails: false,
	inPageTakeOver: false,
	closeClickCount: 0,
	onCloseClickCount: false,
	setInPageTakeOver: () => {

	}
});

export default VideoPlayerStore;