import { debugLog } from "./logUtils";

export const parsePlayerBeacons = (beaconsData = {}) => {
	const playerBeacons = beaconsData.player || [];

	return Array.isArray(playerBeacons) ? playerBeacons : [];
}

export const processBeacons = (beacons = []) => {
	beacons.forEach((beacon) => {
		debugLog('Beacons = ', beacon);
		sendBeacon(beacon);
	});
	return null;
};

export const sendBeacon = (beacon = '') => {
	if (beacon) {
		if (navigator.sendBeacon) {
			navigator.sendBeacon(beacon);
		} else {
			const document = window.document,
				image = document.createElement('img');
				
			image.src = beacon;
		}
	}
};

