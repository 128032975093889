import React from 'react';
import { getLC, setLC } from '../../../../util/lib';
import PopupV2 from '../v2/PopupV2';

const setCoolDown = (timeInSeconds = 0,key = "AutoPopup") => {
  console.log('setCoolDown:', key,timeInSeconds);
  setLC(key,true, (timeInSeconds/60));
  },
isInCoolDown = (key = "AutoPopup") => {
  console.log('isInCoolDown:', key, getLC(key));
  return getLC(key) || false;
}

const AutoPopup = (props = {}) => {
    const {data:popupData = {}, id="popup"} = props,
      {meta:popupMeta = {}} = popupData,
      {coolDownTime = (24 * 60 * 60)} = popupMeta,
      showPopup = !(Object.keys(popupData).length === 0) && !isInCoolDown("AutoPopup");
      console.log("Auto pp:", popupData, showPopup); 

      


      const onCloseHandler = () => {
        setCoolDown(coolDownTime,"AutoPopup")
      }

      if(showPopup) {
        return (
          <PopupV2
              reloadRequired={`yes`}
              data={popupData}
              key={`${id}-REDEEM_PLAN`}
              negativeCallback={onCloseHandler}
              popupStatus={showPopup} />
        );
      }else {
        return <></>
      }
}

export default AutoPopup;