import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { getAPIData } from '../../../../util/lib';
import { showNotification } from '../../../../util/notification';
import SpinnerLoader from '../../AnimatedComponents/SpinnerLoader';
import popupStore from '../../context/popupContext/popupStore';
import WidgetFactory from '../../factory/WidgetFactory';
import ButtonView from '../../util/ButtonView';
import LinkWrapper from '../../util/LinkWrapper';
import './scss/PopupV2.scss';
import Close from '../../../svg/IconV2-2/Close';
import { getBorderFromAllAppSettings } from '../../../../util/classMapperUtils';


const PopupV2 = (props = {}) => {
    const { data: Wdata = {}, popupStatus = false, popup = {}, popupView = false,negativeCallback = false } = props,
        popupData = popup.data || {},
        popupMeta = popup.meta || {},
        downgradePopupCanShow = popupMeta.canShow || false,
        { data = {}, meta = {} } = Wdata,
        { title = "", description = "", actionUrl = {}, action_buttons = {}, customAction = "" } = data,
        popupTitle = popupData.title || "",
        subline = popupData.subline || "",
        alertText = popupData.alertText || "",
        footnote = popupData.footnote || "",
        { primary: primaryActionbutton = {},
         secondary: secondaryActionbutton = {} } = action_buttons,
        action = props.action || {},
        { label: primaryActionbuttonLabel = "", url: primaryActionUrlObj = {},
        logEvent:primaryActionLogEvent = false } = primaryActionbutton,
        {url: primaryActionLogEventUrl = "", url_params: primaryActionLogEventUrlParams = {}} = primaryActionLogEvent,
        { url: primaryActionUrl = "", url_params: primaryActionUrlParams } = primaryActionUrlObj,
        { label: secondaryActionButtonLabel = "" } = secondaryActionbutton,
        { action_buttons: actionButtonsMeta = {} } = meta,
        { primary: primaryActionbuttonMeta = {}, secondary: secondaryActionbuttonMeta = {} } = actionButtonsMeta,
        { url = "", url_params = {} } = actionUrl || {},
        popupActionButton = popupData.action_buttons || {},
        primaryPopupActionButton = popupActionButton.primary || {},
        secondaryPopupActionButton = popupActionButton.secondary || {},
        secondaryLabel = secondaryPopupActionButton.label || "";
    //states
    const [isOpen, setIsOpen] = React.useState(popupStatus),
        [isLoading, setIsLoading] = React.useState(false),
        [isPrimaryActionLoading, setIsPrimaryActionLoading] = React.useState(false),
        [isError, setIsError] = React.useState(false),
        [errorMessage, setErrorMessage] = React.useState(''),
        [dataResponse, setDataResponse] = React.useState([]),
        borderClass = getBorderFromAllAppSettings();

    //contexts
    const { activePopup = '', setActivePopup } = React.useContext(popupStore);

    console.log(' popupActionButton', customAction);
    //functions and handlers
    const getPopupData = (apiUrl = '', urlParams = {}) => {
        console.log('getPopupData:', apiUrl, urlParams);
        if (apiUrl) {
            setIsLoading(true);
            getAPIData(apiUrl, urlParams, (response) => {
                const { data: responseData = {} } = response,
                    { widgets = [] } = responseData;;
                if (widgets.length > 0) {
                    setDataResponse(widgets);
                    setIsLoading(false);
                } else {
                    setIsError(true);
                    setIsLoading(false);
                    setErrorMessage(responseData.message || 'unable to load the content.');
                }
            });
        }
    },
        handleOnClose = (event) => {
            event.preventDefault();
            setIsOpen(false);
            setActivePopup('');
            if (popupView) {
                popupView(false);
            }
            if(negativeCallback) {
                negativeCallback();
            }
        },
        handlePrimaryAction = (event) => {
            event.preventDefault()
            setIsPrimaryActionLoading(true);
            if (primaryActionUrl) {
                if(primaryActionLogEvent) {
                    getAPIData(primaryActionLogEventUrl, primaryActionLogEventUrlParams);
                }
                getAPIData(primaryActionUrl,primaryActionUrlParams, (response) => {
                    setIsPrimaryActionLoading(false);
                    const { data: responseData = {}, type = "" } = response,
                        { message = '', successURL = {} } = responseData,
                        {url:successRedirectUrl = ""} = successURL;
                    if (type === "Success") {
                        showNotification({ type: "success", text: message });
                        setIsOpen(false);
                        setActivePopup('');
                        if(successRedirectUrl) {
                            window.location.href = successRedirectUrl;
                        }
                        if (props.reloadRequired === 'yes') {
                            window.location.reload(true);
                        }
                    } else {
                        showNotification({ type: "error", text: message });
                    }
                    console.log('handlePrimaryAction:', response);
                });
            }
        };

    //useEffects
    useEffect(() => {
        if (isOpen) {
            console.log(`PopupV2: useEffect: ${isOpen}`);
            getPopupData(url, url_params);
        }
    }, [isOpen]);

    useEffect(() => {
        console.log("inpopup:", activePopup)
        if (activePopup === customAction) {
            setIsOpen(true);
        }
    }, [activePopup]);

    useEffect(() => {
        console.log("inpopup:", activePopup)
        console.log("popup data:", dataResponse);
    }, [dataResponse]);
    return (
        <div className={`popup-backdrop popup-backdrop-v2 ${isOpen ? 'open' : ''}`}>
            <div className='popup-container'>
                <div className='popup-header'>
                    {title && <span className='popup-title'>{title}</span>}
                    {downgradePopupCanShow && popupTitle && <span className='popup-title'>{popupTitle}</span>}
                    <button className='popup-close' onClick={handleOnClose}>
                        <Close />
                    </button>
                </div>
                <div className='popup-body'>
                    {description && <p className='popup-description' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description, { ADD_ATTR: ['target'] }) }} />}
                    {downgradePopupCanShow && subline && <p className='popup-subline' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subline, { ADD_ATTR: ['target'] }) }} />}
                    {alertText && <p className='popup-alert' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(alertText, { ADD_ATTR: ['target'] }) }} />}
                    {isLoading && !(dataResponse.length > 0) ? <div className='popup-loader'>
                    <SpinnerLoader />Loading
                    </div> : ''}
                    {isError ? <div className='popup-error'>{errorMessage}</div> : ''}
                    {dataResponse.length > 0 &&
                        <WidgetFactory widgets={[...dataResponse]} />}
                    {/* <ActionButtons data = {popupActionButton} meta = {popupActionButtonMeta} /> */}
                    {downgradePopupCanShow &&
                        <div className='flexed-buttons'>
                            {<LinkWrapper action={action}>{<ButtonView primary={true} buttonData={primaryPopupActionButton} buttonMeta={actionButtonsMeta} />}</LinkWrapper>}
                            {/* {<ButtonView primary={true} buttonData={primaryPopupActionButton} buttonMeta={actionButtonsMeta}/>} */}
                            {<button className={`btn vt-btn btn-lg vt-btn-secondary ${borderClass}`} onClick={handleOnClose}> {secondaryLabel}</button>}
                        </div>}
                    {downgradePopupCanShow && footnote && <p className='popup-footnote' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footnote, { ADD_ATTR: ['target'] }) }} />}
                </div>
                {primaryActionbuttonMeta.canShow && <div className={`popup-footer ${secondaryActionbuttonMeta.canShow ? 'space-between' : ''}`}>
                    {primaryActionbuttonMeta.canShow &&
                        <button className={`btn vt-btn vt-btn-primary btn-sm btn-with-loader ${borderClass}`}
                            disabled = {isPrimaryActionLoading}
                            onClick={handlePrimaryAction}>
                            {isPrimaryActionLoading && <SpinnerLoader
                                onPrimary = {true}
                                size = {'xs'}
                             />}
                            {primaryActionbuttonLabel}
                        </button>}
                        {secondaryActionbuttonMeta.canShow &&
                        <button className={`btn vt-btn vt-btn-secondary btn-sm ${borderClass}`}
                            onClick={handleOnClose}>
                            {secondaryActionButtonLabel}
                        </button>}
                </div>}
            </div>
        </div>
    );
}

export default PopupV2;