import React from 'react';

import './scss/FooterLinksJ.scss'
import { NavLink } from 'react-router-dom';
import { getRelativeUrl } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';

const processData = (links = []) => {
		const pLinks = Array.isArray(links) ? links : [links],
			length = pLinks.length,
			divider = (length > 3) ? (length > 6) ? 3 : 2 : 1,
			groupCount = Math.ceil(length / divider),
			linkGroups = pLinks.reduce((group = [], val = {}, index = 0) => {
				const groupIndex = (Math.ceil((index + 1) / groupCount)) - 1,
					groupArray = group[groupIndex] || [];
				
				group[groupIndex] = [...groupArray, val]

				return [...group];
			}, []);

			debugLog(linkGroups, divider, groupCount);
		return { linkGroups, divider };
	},
	getLinks = (gIndex) => (item = {}, index) => {
		const iData = item.data || {},
			meta = item.meta || {},
			linkObj = iData.link || {},
			linkURL = getRelativeUrl(linkObj.url || ''),
			icon = (typeof iData.icon === 'string') ? iData.icon || 'dflt' : 'dflt',
			textSize = iData.text_size || '';

		debugLog(iData);

		if (item.type === 'Link') {
			return (
				<li key={'nv' + gIndex + '-' + index} className="nav-item">
					<NavLink to={linkURL} className={(meta.showIconYN ? icon + ' icon-link' : '') + (textSize ? ' ' + textSize : '')}>
						{iData.title || ''}
					</NavLink>
				</li>
			);
		}
		return false;
	},
	FooterLinksJ = (props = {}) => {
		const widget = props.widget || {},
			data = widget.data || {},
			// siteDescription = data.site_description || '',
			{ linkGroups, divider } = processData(data.links || []);

		debugLog(linkGroups, divider);

		return (<div className="footer-links container">
			<div className="all-links row">
				{/* changed the divder to hardcoded 3 for urbanflix demo */}
				{linkGroups.map((linkG = [], gIndex) => (
					<div key={'fl'+gIndex} className={'col-md-' + (12 / 3) + ' col-6'}>
						{linkG.map(getLinks(gIndex))}
					</div>
				))}
			</div>
		</div>);
			
		// return (<div className="footer-links container">
		// 	<div className="all-links row">
		// 		<div className="col-md-3">
		// 			<p>{siteDescription}</p>
		// 		</div>
		// 		<div className="col-md-9">
		// 			<div className="footer-links container">
		// 				<div className="row">
		// 					{linkGroups.map((linkG = [], gIndex) => (
		// 						<div className={'col-' + (12 / divider)}>
		// 							{linkG.map(getLinks(gIndex))}
		// 						</div>
		// 					))}
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>);
	};

export default FooterLinksJ;