import React from 'react';


const PlusCircleFilled = () => {
    return (
        <svg
            viewBox="0 0 93.934 93.934"
        >
            <g>
                <path d="M46.967,0C21.028,0,0,21.028,0,46.967c0,25.939,21.028,46.967,46.967,46.967c25.939,0,46.967-21.027,46.967-46.967
           C93.934,21.028,72.906,0,46.967,0z M74.812,53.924H53.924v20.889H40.01V53.924H19.121V40.01H40.01V19.121h13.914V40.01h20.889
           V53.924z"/>
            </g>
        </svg>
    );
};

export default PlusCircleFilled;