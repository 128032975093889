import React, { useEffect, useState, useContext } from 'react';
import TouchDetect from '../../util/touch/TouchDetect';
import TouchDiv from '../../util/touch/TouchDiv';

import './scss/MiniEPG.scss';
import ChannelThumb from './ChannelThumb';
import MiniEPGRow from './MiniEPGRow';
import RightArrowSlim from '../../../svg/RightArrow/RightArrowSlim';
import VideoPlayerStore from '../../videoPlayer/VideoPlayerStore';

const SQR = 5.5 + 0.5;

const MiniEPG = ({ data = [], onReload = '', touchEnabled = false }) => {
	const {	nextURL = false, channels = [] } = data,
		processedChannelData = Array.isArray(channels) ? channels : [],
		channelLength = processedChannelData.length || 0,  
		[ activeIndex, setActiveIndex ]  = useState(channelLength > 3 ? Math.floor(channelLength / 2) : 0), 
		[ activeChannel, setActiveChannel ]  = useState(''),
		[ preChannels, setPreChannels ] = useState([]), 
		[ postChannels, setPostChannels ] = useState([]),
		{ loadNextEpgData } = useContext(VideoPlayerStore),
		[needsRefresh, setNeedsRefresh] = useState(true);

	useEffect(() => {
		setNeedsRefresh(true);
	}, [needsRefresh]);

	useEffect(() => {
		let i;

		const preIndexRaw = activeIndex - 2,
			preIndex = preIndexRaw < 0 ? 0 : preIndexRaw,
			processedChannelDataLength = processedChannelData.length,
			postIndexRaw = activeIndex + 1,
			postIndex = postIndexRaw >= processedChannelDataLength ? processedChannelDataLength - 1 : postIndexRaw,
			preChannelsL = [],
			postChannelsL = [];

		if (preIndex < activeIndex) {
			for (i = preIndex; i < activeIndex; i++) {
				preChannelsL.push({...processedChannelData[i], thumbIndex: i});
			}
		}

		if (postIndex > activeIndex) {
			postChannelsL.push({...processedChannelData[postIndex], thumbIndex: postIndex});
		}
		setPreChannels([...preChannelsL].reverse());
		setPostChannels([...postChannelsL]);
		setActiveChannel(processedChannelData[activeIndex]);

		if (activeIndex >= channelLength - 2 && nextURL) {
			loadNextEpgData(nextURL);
		}
	}, [activeIndex, channels]);


	return <div className='mini-epg'>
		<TouchDiv className='mini-epg-swipers'
			onSwipeUp={() => {
				if (activeIndex < processedChannelData.length - 1){
					setActiveIndex(activeIndex + 1);
				}
			}}
			onSwipeBottom={() => {
				if (activeIndex > 0) {
					setActiveIndex(activeIndex - 1);
				}
			}}
		>
		{!touchEnabled && activeIndex > 0 && <div className='up-arrow-container' onClick={() => setActiveIndex(activeIndex - 1)}>
			<RightArrowSlim />
		</div>}
		{preChannels.map((channel = {}, index = 0) => {
			const thumbIndex = channel.thumbIndex || 0,
				style = {
					bottom: (((index) * SQR) + 11) + 'rem',
					opacity: (1 / (index + 1) / 3),
				};

			return <div style={style} key={`mepg-ct-${index}`} className={`channel-thumb-wrpr above card-${index}`} onClick={() => {
				setActiveIndex(thumbIndex);
			}}>
				<ChannelThumb data={channel} title={channel.title}/>
			</div>;
		})}

		{activeChannel ? needsRefresh && <>
			{needsRefresh && <MiniEPGRow onReload={onReload} data={activeChannel} title={activeChannel.title} setNeedsRefresh={setNeedsRefresh}/>}
		</> : null}

		{postChannels.map((channel = {}, index = 0) => {
			const thumbIndex = channel.thumbIndex || 0,
				style = { 
					top: (((index) * SQR) + 11) + 'rem',
					opacity: (1 / (index + 1)/3)
				};

			return <div style={style} key={`mepg-ct-${index}`} className='channel-thumb-wrpr below' onClick={() => {
				setActiveIndex(thumbIndex);
			}}>

				<ChannelThumb data={channel} title={channel.title} />
			</div>;
		})}
		{!touchEnabled && activeIndex < processedChannelData.length-1 && <div className='down-arrow-container' onClick={() => setActiveIndex(activeIndex + 1)}>
			<RightArrowSlim />
		</div>}
		</TouchDiv>
	</div>;
}

export default TouchDetect(MiniEPG);