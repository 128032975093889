import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const CodeSnippetsWidget = (props = {}) => {
	const [rendered, setRendered] = useState(false),
		widget = props.widget || {},
		data = widget.data || {},
		tags = data.tags || [],
		filteredSrcTags = tags.filter((filterSrc) => {
			const checkSrc = /<script src/,
				isPresent = checkSrc.test(filterSrc);

			return isPresent;
		}),
		filteredSrc = filteredSrcTags.map((filteredInnerSrc) => {
			const srcStringToReplace = /(<script src=*)|(><\/script>)/g,
				filter = filteredInnerSrc.replace(srcStringToReplace, ''),
				filterQuotes = filter.replace(/(^"|"$)/g, '');

			return filterQuotes;
		}),
		filteredHtmlTags = tags.filter((filterHtml) => {
			const checkHtml = /<script>|<script type="text\/javascript">/,
				isPresent = checkHtml.test(filterHtml);

			return isPresent;
		}),
		filteredHtml = filteredHtmlTags.map((filteredInnerHtml) => {
			const htmlStringToReplace = /<\!--.*?-->/g,
				removeComments = filteredInnerHtml.replace(htmlStringToReplace, ''),
				filter = removeComments.substring(
					removeComments.indexOf('>') + 1,
					removeComments.lastIndexOf('<')
				);

			return filter;
		});

	useEffect(() => {
		const timer = setTimeout(() => {
			console.log("Bulbasaur");
			setRendered(true); 
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, []);



	return (
		<div className="code-snippets-widget">
			{rendered && 
				filteredHtml.map((HTML, i = 0) => {
					return (
						<Helmet
							key={'csw-html-' + i}
							script={[
								{
									type: 'text/javascript',
									innerHTML: `${HTML}`,
								},
							]}
						/>
					);
				})}
			{rendered && 
				filteredSrc.map((SRC, i = 0) => {
					return (
						<Helmet
							key={'csw-src-' + i}
							script={[
								{
									type: 'text/javascript',
									src: `${SRC}`,
								},
							]}
						/>
					);
				})}
		</div>
	);
};

export default CodeSnippetsWidget;
