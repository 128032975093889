import { ga4Event } from '../components/js/trackers/GA4Tracker.js';
import { post } from './fetchRequest.js';
import { clearAutoPopupCooldown, clearL, clearUserDetails, createLoginSession, getAPIData, getEnvVar, getL, getLC, getUrlFromBaseAPI, parseAPIResponse, setL, setUserDetails } from './lib.js';
import { debugLog } from './logUtils.js';
import { showNotification } from './notification.js';


export const sessionStrategy = ['localstore', 'cookie'];
export const publicRoutes = ['/login', '/register', '/base', '/manifest', '/undefined'];

const OAUTH_COOKIE_CLEANUP_URL_BASEKEY = 'oAuthLogoutRedirectionUrl';
const DEFAULT_TOKEN_EXPIRY_TIME = 3600; //in seconds (1 hour);

//module level variables 
let refreshAuthTokenApi = '';

export const cacheRefteshTokenApi = () => {
	refreshAuthTokenApi = refreshAuthTokenApi ? refreshAuthTokenApi : getUrlFromBaseAPI('refresh_token') || '';
}

export const createUserSession = (strategy, data) => {
	if (sessionStrategy.includes(strategy)) {
		switch (strategy) {
			case 'localstore':
				if (data.token && data.refreshToken) {
					data.timestamp = new Date();
					data.expires_in = getEnvVar('REACT_APP_JWT_EXPIRY_TIME') || DEFAULT_TOKEN_EXPIRY_TIME;
					debugLog("expiry time:", data.expires_in);
					localStorage.setItem('vt-auth-token', JSON.stringify(data));

					return true;
				} else {
					debugLog('Error: Token or refresh token Not available');
					return false;
				}
			case 'cookie':
				if (data.token && data.refreshToken) {
					// Cookies.set('user', JSON.stringify(data));
					//todo implement cookie strategy
				}
				break;
			default:
				debugLog('Error: Invalid strategy');
		}
	} else {
		debugLog('Invalid session strategy');
	}
}

export const preserveUserData = ({ data }) => {
	if (data) {
		debugLog("userrrr:data:", JSON.stringify(data));
		setUserDetails(data);
	}
}

export const setCookies = (key, value, options) => {
	console.log("setting cookie... > incomming data:", key, value);
	if (key && value) {
		let updatedCookie = '';
		console.log("setting cookie...:", updatedCookie);
		updatedCookie += encodeURIComponent(key) + "=" + encodeURIComponent(value);
		console.log("setting cookie... > token:", updatedCookie);
		for (let optionKey in options) {
			updatedCookie += "; " + optionKey;
			let optionValue = options[optionKey];
			if (optionValue !== true) {
				updatedCookie += "=" + optionValue;
			}
		}
		document.cookie = updatedCookie;
		console.log("setting cookie... > completed:", updatedCookie);
		console.log("setting cookie... > completed [doc]:", document.cookie);

	}
}

export const clearUserData = () => {
	localStorage.removeItem('user');
}

export const authRedirection = (redirectionObj) => {
	const active_purchase_flow_plan_id = getL('payment_plan_id'),
		deviceConnectFlow = getL('deviceConnectFlow'),
		postLoginRedirectUrl = getL('postLoginRedirectUrl'),
		destinationPagePath = getL('destination_page_path'),
		redirectURL = redirectionObj.path || '',
		location = window.location,
		currentPathWithQuery = (location.pathname+location.search);

	console.log('Redirection object', redirectionObj);

	if (redirectURL) {
		debugLog('Redirecting to', redirectURL);
		window.location.href = redirectURL;
	} else if (window.location.pathname !== '/login' && window.location.pathname !=='' && window.location.pathname !=="/register") {
		window.location.href = currentPathWithQuery;
	} else if (postLoginRedirectUrl) {
		console.log('Redirecting to post login flow');
		window.location.href = postLoginRedirectUrl;
	} else if (deviceConnectFlow) {
		debugLog('Redirecting to device connect flow');
		window.location.href = '/connect';
	} else if (active_purchase_flow_plan_id) {
		debugLog('Redirecting to payment flow');
		window.location.href = '/payment';
	} else if (destinationPagePath) {
		debugLog('Redirecting to destination page');
		window.location.href = destinationPagePath;
	} else {
		debugLog('Redirecting to home page');
		window.location.href = '/';
	}
}

export const isSecureLogin = () => {
	const vt_auth_token = getL('vt-auth-token') || {};

	if (vt_auth_token.token) {
		debugLog('Secure login:', vt_auth_token);
		return true;
	}

	debugLog('no Secure login:', vt_auth_token);

	return false;
}

export const isTokenAlive = () => {
	const vt_auth_token = getL('vt-auth-token') || {};

	if (!vt_auth_token.token) {
		return false;
	}

	if (vt_auth_token.timestamp) {
		const currentTime = new Date(),
			tokenTime = new Date(vt_auth_token.timestamp);

		debugLog("diff:", currentTime - tokenTime);
		debugLog("exp in:", vt_auth_token.expires_in * 1000);
		debugLog("isexp:", currentTime - tokenTime < (vt_auth_token.expires_in * 1000));

		return (currentTime - tokenTime < (vt_auth_token.expires_in * 1000))
	}
}

export const clearTokens = () => {
	localStorage.removeItem('vt-auth-token');
}

export const refreshAuthToken = () => {
	return new Promise((resolve, reject) => {
		try {
			const vt_auth_token = getL('vt-auth-token') || {};

			debugLog('Refreshing token:', refreshAuthTokenApi);

			refreshAuthTokenApi = refreshAuthTokenApi ?
				refreshAuthTokenApi : (getUrlFromBaseAPI('refresh_token') || getLC('refresh-token') || "");

			if (vt_auth_token.refreshToken && vt_auth_token.token) {
				const postData = {
					app_version: getEnvVar('REACT_APP_API_VER') || '2',
					token: vt_auth_token.token,
					refresh_token: vt_auth_token.refreshToken
				};

				debugLog('Refreshing token: all tokend available');

				if (refreshAuthTokenApi) {
					debugLog('refreshAuthTokenApi url available');

					post(refreshAuthTokenApi, postData).then((response = {}) => {
						const parsedResponse = parseAPIResponse(response);
						debugLog('Refresh token response:', parsedResponse);
						if (parsedResponse.error) {
							reject({
								status: false
							});
						} else if (parsedResponse.data) {
							const { token = '', refresh_token = '' } = parsedResponse.data || {};
							if (token && refresh_token) {
								resolve({
									status: true,
									message: 'Token refreshed successfully',
									token,
									refresh_token
								});
							} else {
								debugLog('Error: Token or refresh token Not available');

								reject({
									status: false,
								});
							}
						}
					}).catch((exception = '') => {
						debugLog('refresh token post:', exception);
					});
				} else {
					debugLog("not a valid refresh token url, please check base api store");

					reject({
						status: false
					});
				}
			}
		}
		catch (exception) {
			debugLog('refreshAuthToken  Failed:', exception);

			reject(exception)
		}
	});
}


export const isToeknAvailable = (path) => {
	const vt_auth_token = getL('vt-auth-token') || {};

	if (vt_auth_token.token && vt_auth_token.refreshToken) {
		debugLog('Token available');

		return true;
	}

	debugLog('Token not available');

	return false;
}

export const LogoutUser = () => {
	debugLog('Logout user');

	const location = window.location,
		pathname = location.pathname,
		isDeleteSuccessPage = pathname === "/deleteAccountSuccess";

	clearTokens();
	clearUserData();
	clearUserDetails();
	clearAutoPopupCooldown();
	if (!clearOAuthCookiesServerSide()) {
		window.location.reload(true);
	}
	if(isDeleteSuccessPage){
		window.location.href = '/';
	}
}
export const LogoutUserWithoutRedirect = () => {
	console.log('Logout user without redirect');

	clearTokens();
	clearUserData();
	clearUserDetails();
}


//User management
export const registerNewUser = (payload, actionUrl, redirectapp, setIsLoading = false) => {
	console.log('registerNewUser called', payload);
	if (!actionUrl) {
		return false;
	}
	getAPIData(actionUrl, payload, (response) => {
		console.log('response', response);
		if (response.type === 'Success') {
			const { data: responseData = {} } = response,
				{ thirdPartyOauth: thirdPartyOauthObj = {} } = responseData,
				{ url: thirdPartyOauthURL = '' } = thirdPartyOauthObj,
				lastVideoLocation = getL('lastVideoLocation');
			if (lastVideoLocation) {
				setL('destination_page_path', lastVideoLocation);
				clearL('payment_plan_id');
			}
			ga4Event('register', { method: 'Ventuno' });
			if (responseData.token && responseData.refresh_token) {
				const { token = '',
					refresh_token = '',
					next_page_url = {} } = responseData;
				if (createUserSession('localstore', {
					token,
					refreshToken: refresh_token
				})) {
					preserveUserData(responseData.user);
					if (thirdPartyOauthURL) {
						authRedirectionWithMiddleApi(thirdPartyOauthURL, redirectapp);
					} else {
						authRedirection(next_page_url);
					}
				}
			} else {
				console.log('Token not available Try old login');
				//deprecated function need to be removed once all partners are migrated to new login flow (JWT)
				createLoginSession(responseData.user || {}, responseData.next_page_url || {});
			}
		} else {
			const { data: responsedata = {} } = response,
				{ message = '' } = responsedata;
			if (setIsLoading) {
				setIsLoading(false);
			}
			showNotification({ text: message, type: 'error' });
			return false;
		}
	});
}


export const loginUser = (payload, actionUrl, redirectapp, redirectionURLFinal, setIsLoading = false) => {
	console.log('registerNewUser called', payload, redirectionURLFinal);
	if (!actionUrl) {
		return false;
	}
	getAPIData(actionUrl, payload, (response) => {
		console.log('response', response);
		if (response.type === 'Success') {
			const { data: responseData = {} } = response,
				{ thirdPartyOauth: thirdPartyOauthObj = {} } = responseData,
				{ url: thirdPartyOauthURL = '' } = thirdPartyOauthObj,
				lastVideoLocation = getL('lastVideoLocation');

			
			console.log("oauth url:", responseData);
			console.log("oauth url:", thirdPartyOauthObj);
			console.log("oauth url:", thirdPartyOauthURL);
			if (lastVideoLocation) {
				setL('destination_page_path', lastVideoLocation);
				clearL('payment_plan_id');
			}
			ga4Event('login', { method: 'Ventuno' });
			if (responseData.token && responseData.refresh_token) {
				const { token = '',
					refresh_token = '',
					next_page_url = {} } = responseData;
				if (createUserSession('localstore', {
					token,
					refreshToken: refresh_token
				})) {
					preserveUserData(responseData.user);
					if (thirdPartyOauthURL) {
						authRedirectionWithMiddleApi(thirdPartyOauthURL, redirectapp, redirectionURLFinal);
					} else {
						authRedirection(next_page_url || redirectionURLFinal);
					}
				}
			} else {
				//deprecated function need to be removed once all partners are migrated to new login flow (JWT)
				createLoginSession(responseData.user || {}, responseData.next_page_url || {});
			}
		} else {
			const { data: responsedata = {} } = response,
				{ message = '' } = responsedata;
			if (setIsLoading) {
				setIsLoading(false);
			}
			showNotification({ text: message, type: 'error' });
			return false;
		}
	});
}


export const authRedirectionWithMiddleApi = (oauthRedirecitonURL, redirectapp, redirectionURLFinal) => {
	console.log("authRedirectionWithMiddleApi", oauthRedirecitonURL, redirectapp, redirectionURLFinal);
	const active_purchase_flow_plan_id = getL('payment_plan_id'),
		deviceConnectFlow = getL('deviceConnectFlow'),
		postLoginRedirectUrl = getL('postLoginRedirectUrl'),
		destinationPagePath = getL('destination_page_path'),
		redirectURL = oauthRedirecitonURL.path || '',
		location = window.location,
		currentPathWithQuery = (location.pathname+location.search);
		
		console.log("auth redirection middleware:", (location.pathname+location.search));

	if (redirectionURLFinal) {
		window.location.href = `${oauthRedirecitonURL}&callback=${redirectionURLFinal}`;
	} else if (window.location.pathname !== '/login' && window.location.pathname !=='' && window.location.pathname !=="/register") {
		window.location.href = `${oauthRedirecitonURL}&callback=${currentPathWithQuery}`;
	} else if (redirectapp) {
		window.location.href = `${oauthRedirecitonURL}&redirectapp=${redirectapp}`;
	} else if (redirectURL) {
		console.log('Redirecting to', `${oauthRedirecitonURL}&callback=${redirectURL}`);
		window.location.href = `${oauthRedirecitonURL}&callback=${redirectURL}`;
	} else if (postLoginRedirectUrl) {
		console.log('Redirecting to post login flow:', `${oauthRedirecitonURL}&callback=${postLoginRedirectUrl}`);
		window.location.href = `${oauthRedirecitonURL}&callback=${postLoginRedirectUrl}`;
	} else if (deviceConnectFlow) {
		console.log('Redirecting to device connect flow:', `${oauthRedirecitonURL}&callback=/connect`);
		window.location.href = `${oauthRedirecitonURL}&callback=/connect`;
	} else if (active_purchase_flow_plan_id) {
		console.log('Redirecting to payment flow:', `${oauthRedirecitonURL}&callback=/payment`);
		window.location.href = `${oauthRedirecitonURL}&callback=/payment`;

	} else if (destinationPagePath) {
		console.log('Redirecting to destination page:', `${oauthRedirecitonURL}&callback=${destinationPagePath}`);
		window.location.href = `${oauthRedirecitonURL}&callback=${destinationPagePath}`;

	} else {
		console.log('Redirecting to home page:', `${oauthRedirecitonURL}&callback=/`);
		window.location.href = `${oauthRedirecitonURL}&callback=/`;
	}
}

export const clearOAuthCookiesServerSide = () => {
	const redirectionURL = getUrlFromBaseAPI(OAUTH_COOKIE_CLEANUP_URL_BASEKEY);
	if (redirectionURL) {
		window.location.href = `${redirectionURL}?callback=${window.location.pathname}`;
		return true;
	} else {
		return false;
	}
}

//send OTP
export const sendOTP = (payload, actionUrl, setIsLoading = false) => {
	
}