import React from 'react';
import EarthMaster from '../earth/EarthMaster';
import JupiterMaster from '../jupiter/JupiterMaster';
import VenusMaster from '../venus/VenusMaster';
import MarsMaster from '../Mars/MarsMaster';
import QuadradoMaster from '../Quadrado/QuadradoMaster';

const MasterLoader = (props) => {
	const themeID = parseInt(props.themeID || 1),
	font  = props.font || false;
	return (
		<>
			{(themeID === 1) && <VenusMaster />}
			{(themeID === 2) && <EarthMaster />}
			{(themeID === 3) && <JupiterMaster />}
			{(themeID === 4) && <MarsMaster />}
			{(themeID === 5) && <QuadradoMaster font={font} />}
		</>
	);
};

export default MasterLoader;


