import React, { useState, useEffect, useContext } from 'react';
import { getAPIData } from '../../../util/lib';
import DotLoader from '../AnimatedComponents/DotLoader';
import ThemeWrapper from './ThemeWrapper';
import widgetFactoryMapper from './widgetFactoryMapper';
import pageStore from '../context/PageContext/PageStore';

export const PAGE = 'Page';
export const SCROLL_TO = 'scroll-to-';

const WidgetFactory = (props = {}) => {
	const { widgets = '', splashScreen = null, nextAPI = '', parent = '', keyPrefix = '', meta = {}, pageCount: propsPageCount = 0, nextAPIParams = {}, onDataChange = '', slidInShut = "" } = props,
		[apiData, setAPIData] = useState(''),
		[pageCount, setPageCount] = useState(0),
		[pageContentLoading, setPageContentLoading] = useState(true),
		{setPageLoadingCtx = false} = useContext(pageStore);

	useEffect(() => {
		if(setPageLoadingCtx && pageContentLoading){
			setPageLoadingCtx(pageContentLoading);
		}
	},[pageContentLoading])

	useEffect(() => {
		if (nextAPI) {
			setPageCount(parseInt(propsPageCount) || 0);
			setPageContentLoading(true);
			getAPIData(nextAPI, { ...nextAPIParams, deferred_load_supported: true }, (response = {}) => {
				setPageContentLoading(false);
				setAPIData(response.data || {});
			});
		} else {
			setPageContentLoading(false);
		}
	}, [nextAPI]);

	useEffect(() => {
		if (apiData) {
			const widgetsL = apiData.widgets || [];

			if (pageCount === props.pageCount && onDataChange) {
				onDataChange(widgetsL, apiData.next_url || '', apiData.next_url_params || {});
			}
		}
	}, [apiData]);


	return (<>
		{widgets && widgets.map((item = {}, index = 0) => {
			console.log("widget comp:", index, widgets.length);
			if( index === (widgets.length - 1)) {
				setPageLoadingCtx(false);
			}

			const type = item.type || '',
				key = keyPrefix + type + index,
				spanProps = {
					key
				};

			if (parent === PAGE) {
				spanProps.id = `${SCROLL_TO}${index}`;
			}

			return <span {...spanProps}>{widgetFactoryMapper(type, {
				key,
				id: index,
				data: item,
				isInPopup: props.isInPopup || false,
				wIndex: index,
				parent,
				meta: meta,
				pageIndex: propsPageCount,
				slidInShut: slidInShut,
				splashScreen,
			}, {
				dontRenderPage: props.dontRenderPage || false,
				addFont: props.addFont,
				temp_other: widgets,
				onDataChange: onDataChange,
				fullProps: { ...props },
				handlers: props.handlers
			})}
			</span>;
		})}
		{pageContentLoading && <div className="loading-spinner">
			<DotLoader />
		</div>}
	</>);

}

export default ThemeWrapper(WidgetFactory);