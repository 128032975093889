import React from 'react';

const Play = () => {
	return (
		<svg viewBox="0 0 24 24">
			<path d="M8 6.82v10.36c0 0.79 0.87 1.27 1.54 0.84l8.14-5.18c0.142-0.090 0.26-0.215 0.341-0.363s0.124-0.314 0.124-0.482c0-0.169-0.043-0.335-0.124-0.482s-0.199-0.273-0.341-0.363l-8.14-5.17c-0.151-0.098-0.325-0.153-0.505-0.159s-0.357 0.035-0.515 0.121c-0.158 0.086-0.289 0.213-0.381 0.368s-0.14 0.331-0.139 0.511z"></path>
		</svg>
	);
}

export default Play;