import React, { Component } from 'react';

import "./../scss/PrivacyCheck.scss";
import { ALL_APP_SETTINGS, getAPIData, getL, getLC, setL } from '../../util/lib';
import { debugLog } from '../../util/logUtils';
import { TERMS_CONSENT_CHECK } from '../../util/universalConstans';
import ThemeWrapper, { DARK, LIGHT } from './factory/ThemeWrapper';
import { getBorderFromAllAppSettings, getBorderRadiusClass } from '../../util/classMapperUtils';

class PrivacyCheck extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active: true
		};

		this.onAcceptClick = this.onAcceptClick.bind(this);
	}

	componentDidMount() {

		this.setState({});
	}

	onAcceptClick() {
		const did = getL('did'),
			widget = this.props.widget || {},
			data = widget.data || {},
			consentLinkObj = data.update_terms_consent_link || {},
			consentLink = consentLinkObj.url || "";

		if (consentLink) {
			getAPIData(consentLink, { did }, ({type = '' } = {}) => {
				if (type === 'Success') {
					this.setState({ active: false });
					setL(TERMS_CONSENT_CHECK, true);
				}
			});
		}
	}

	render() {
		const active = !(!this.state.active),
			props = this.props,
			widget = props.widget || {},
			data = widget.data || {},
			labels = data.labels || {},
			termLabel = labels.terms_label || '',
			privacyLabel = labels.privacy_label || '',
			termsLinkObj = data.terms_link || {},
			termsLink = termsLinkObj.path || '',
			privacyLinkObj = data.privacy_link || {},
			privacyLink = privacyLinkObj.path || '',
			themeType = props.themeType === LIGHT ? LIGHT : DARK,
			borderClass = getBorderFromAllAppSettings();
			
		return (
			<div className={`privacy_check theme-${themeType}`} style={{ display: (active ? 'block' : 'none') }}>
				<div className="container-fluid">
					<div className="row" >
						<div className="col-11 col-sm-9 col-lg-10">
							<div className="label-hldr">
								{((labels.agree_message || '').replace((termLabel), ' ')).replace('.', '')}<a href={termsLink}>{termLabel}</a>
								{'. '}
								{((labels.personal_data_message || '').replace((privacyLabel), ' ')).replace('.', '')}<a href={privacyLink}>{privacyLabel}.</a>
							</div>
						</div>
						<div className="col-2 col-sm-3 col-lg-2 text-right">
							<div>
								<button type="button" className={`btn vt-btn-primary btn-lg vt-btn vt-btn-lg ${borderClass}`} onClick={this.onAcceptClick} >Accept</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ThemeWrapper(PrivacyCheck);