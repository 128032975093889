import React, { useEffect, useState } from 'react';
import { getSettingsAPI, getThemeID } from '../../../util/lib';

export const VENUS = 'venus';
export const JUPITER = 'jupiter';
export const EARTH = 'earth';
export const MARS = 'mars';
export const QUADRADO = 'quadrado';

export const LIGHT = 'light';
export const DARK = 'dark';

const ThemeWrapper = (Comp) => {
	return (props = {}) => {
		let theme = '';
		const themeID = getThemeID(),
			[ themeType, setThemeType ] = useState(false);

		useEffect(() => {
			getSettingsAPI().then((settings = {}) => {
				setThemeType(settings.theme_type === LIGHT ? LIGHT : DARK); 
			}).catch(() => {
				setThemeType(DARK);
			});
		}, [])

		switch (themeID) {
			case (3):
				theme = JUPITER;
				break;
			case (2):
				theme = EARTH;
				break;
			case (4):
				theme = MARS;
				break;
			case (5):
				theme = QUADRADO;
				break;
			default:
				theme = VENUS;
		}

		return (themeID && themeType ? <Comp {...{ themeID, theme, themeType, ...props }} /> : null);
	};

}

export default ThemeWrapper;