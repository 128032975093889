import { createUserSession, isSecureLogin, isToeknAvailable, isTokenAlive, LogoutUser, refreshAuthToken, setCookies } from "./authUtils";
import { post } from "./fetchRequest";
import { parseAPIResponse, getL, getAPIData } from "./lib";
import { debugLog } from "./logUtils";

export const getApiDataV2 = (url, data, callback) => {
	try {
		if (isSecureLogin() && isToeknAvailable() && !isTokenAlive()) {
			debugLog("token expired");

			refreshAuthToken().then((successData) => {
				debugLog("token refreshed:resolve");

				if (successData.status) {
					createUserSession('localstore', {
						token: successData.token,
						refreshToken: successData.refresh_token
					});
					setCookies("user_token", successData.token);
					console.log("calling api");
					apiCall(url, data, callback);
				}
			}, (errorData) => {
				debugLog("token refreshed:reject:", errorData);

				if (!errorData.status) {
					LogoutUser();
					debugLog("not able to get refresh token");
					window.location.href = '/login';
				}
			});
		} else {
			apiCall(url, data, callback);
		}
	} catch (exception) {
		debugLog('getApiDatav2 Failed:', exception);
	}

	debugLog("getapiv2 last line");
}

export const apiCall = (url, data, callback) => {
	const vt_auth_token = getL('vt-auth-token') || {},
		{ token = '' } = vt_auth_token;

	if (token) {
		data.token = token;
	}

	post(url, data).then((response = {}) => {
		const parsedResponse = parseAPIResponse(response);

		debugLog("parsedResponse111:", parsedResponse);

		checkForSessionErrors(parsedResponse);
		callback(parsedResponse);
	}).catch((exception = '') => {
		debugLog('getApiData Failed:', exception);
	});

	debugLog("getapiv2 api call");
}

export const checkForSessionErrors = (response = {}) => {
	const { type = '', data = {} } = response;

	if (type === 'Error') {
		if (data.message === 'Session Expired') {
			LogoutUser();
			window.location.href = '/login';
		}
	}
}

export const getSEOData = (options = {}) => {
    return new Promise((resolve, reject) => {
        const { route = '/', seoApi = '' } = options;
        if (seoApi) {
            getAPIData(seoApi, {
                url: route
            },(response = {}) => {
                console.log("seo api:", response);
                const { type = '', data = {} } = response;
                if (type === 'Success') {
                    resolve(data);
                }
                resolve({});
            });
        } else {
            reject(new Error("Couldn't find seo api url in manifest cache..."));
        }
    });
}


export const convertQueryParamsToObject = (url = '') => {
	if(url !=="") {
		const queryParamString = url.slice(1, url.length);
		const queryParamsArray = queryParamString.split('&');
		let queryParamsObject = {};
		for(const item of queryParamsArray) {
			const queryPairs = item.split('=');
			if(!queryPairs[1]){
				continue;
			}
			queryParamsObject = {
				...queryParamsObject,
				[queryPairs[0]] : queryPairs[1]
			}
		}
		console.log('convertQueryParamsToObject:', queryParamsObject);
		return queryParamsObject;
	}
}