import React, { useContext, useEffect, useState } from 'react';
import { getUrlFromBaseAPI, getAPIData } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';
import pageStore from '../context/PageContext/PageStore';
// import { FooterDemoData } from '../../../demoData/quadrado/FooterDemoData'; //~ REMOVE HARDCODE TO TEST

const FooterRequest = (Comp) => {
	// const hardCodedData = FooterDemoData; //~  REMOVE HARDCODE TO TEST

	return (props = {}) => {
		const [footerObj, setFooterObj] = useState(false);
		//const {pageLoading = false, setPageLoadingCtx } = useContext(pageStore);
		debugLog("footerDATA-1", Comp);

		useEffect(() => {
			const footerURL = getUrlFromBaseAPI('footer');

			debugLog("footer url:", footerURL);

			if (footerURL) {
				getAPIData(footerURL, {}, (response = {}) => {
					const footerData = response.data || '',
						footerMeta = response.meta || {},
						footerLayout = response.layout || 1;

					// // ~ REMOVE HARDCODE TO TEST
					// const footerData = hardCodedData.data || '',
					// 	footerMeta = hardCodedData.meta || {},
					//  	footerLayout = hardCodedData.layout || 1;

					console.log("footerDATA-", footerMeta, footerData, footerLayout);
					setFooterObj({ footerData, footerMeta, footerLayout });
				});
			}
		}, []);

		// useEffect(() => {
		// 	console.log("Watch pageStore:", pageLoading);
		// }, [pageLoading] )

		return (footerObj ? <Comp {...{ ...props, ...footerObj }} /> : null);
	};
};
export default FooterRequest;
