import React from 'react';
import './../scss/StaticQuantity.scss';

const StaticQuantity = (props) => {
	return (
		<div className="static-quantity">
			<span className="pr20">Quantity</span>
			<span className="qty-num">{props.quantity}</span>
		</div>
	);
}

export default StaticQuantity;