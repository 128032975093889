import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { isGDPREnabled, isOtherTechEnabledSibbo } from '../../../util/gdprUtils';


// note: we have to add GDPR here also
const initReactGA = (gaTrackerId = '') => {
	ReactGA.initialize(gaTrackerId, { debug: false }); //TEUX DEUX - chng to false for production 

	if (window) {
		window.ReactGA = ReactGA;
	}
},
	GATracker = (props = {}, prevProps = {}) => {
		const gaTrackerId = props.gaTrackerId || '',
			gdprEnabled = isGDPREnabled(),
			googleSibboConfig = isOtherTechEnabledSibbo("google");

		useEffect(() => {
			if (gdprEnabled) {
				window['gtag_enable_tcf_support'] = true;
				if (!googleSibboConfig) {
					return;
				}
			}
			initReactGA(gaTrackerId);
		}, [gaTrackerId, gdprEnabled, googleSibboConfig]);

		return <></>;
	};

export default GATracker;