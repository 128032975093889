
import React from 'react';

const Play = () => {  
	return(
		<svg viewBox="0 0 13 14">
			<g stroke="none" strokeWidth="1">
				<g 	transform="translate(-88.000000, -88.000000)">
					<g id="play-arrow" transform="translate(88.500000, 88.000000)">
						<path d="M0,0 L0,14 L11,7 L0,0 Z" id="Shape"/>
					</g>
				</g>
			</g>
		</svg>
	); 
}

export default Play;
