import React, { useLayoutEffect } from 'react';
import { PAGE } from '../factory/WidgetFactory';

export const SITE_BODY = 'site-body';
export const OVERIDE_PAGE_PAD = 'overide-page-pad';
export const PAGE_TOP_PADDING = 'page-top-padding';

const pagePaddingHackDecorator = (Comp = null) => {
	const PagePaddingHackDecorator = (props = {}) => {
		useLayoutEffect(() => {
			const { data = {}, wIndex = -1, parent = '' } = props;

			let removePadding = wIndex === 0 && parent === PAGE && data.type;
							
			if (data.type === 'HybridDetailWidget' && data.layout !== 5) {
				removePadding = false;
			}

			if (removePadding) {
				const siteBody = document.querySelector(`.${SITE_BODY}`);

				if (siteBody) {
					siteBody.classList.add(OVERIDE_PAGE_PAD);
				}

				return () => {
					if (siteBody) {
						siteBody.classList.remove(OVERIDE_PAGE_PAD);
					}
				}
			}
		}, []);

		return (Comp ? <Comp {...props} /> : null);
	};

	return PagePaddingHackDecorator;
};

export default pagePaddingHackDecorator;