import React, { useContext } from 'react';
import { SideMenuContext } from './SideMenuManager';
import HybridLink from '../hybrid/footer/HybridLink';

import './scss/SideMenu.scss';

const SideMenuList = ({ data = {}, margin = 0, mainMenuOpen = false }) => {
	const cards = data.cards || [];

	return (<div className="side-menu-list">
		{cards.map((card = {}, index = 0) => {
			const type = card.type || '',
				style = { marginLeft: margin + 'rem' },
				cardData = card.data || {},
				cardDataLabel = cardData.label || '';

			console.log("side m:", mainMenuOpen)

			return (<div key={`sml-${index}`} style={style} className="side-menu-item">
				{type === 'HybridLink' && <HybridLink tabIndex={mainMenuOpen ? 0 : -1} data={card} />}
				{type === 'DropDown' && cardDataLabel && <div className="n-link-container m-1">
					<span className="sub-link link">{cardDataLabel}</span></div>}
				{type === 'DropDown' && <SideMenuList mainMenuOpen={mainMenuOpen} data={cardData} margin={margin + 1} />}
			</div>);
		})}
	</div>);
};

const SideMenu = (props = {}) => {
	const { sideMenuData = {} } = useContext(SideMenuContext),
		{ primarySideMenu = {}, open = false } = sideMenuData,
		data = primarySideMenu ? primarySideMenu.data || '' : '';


	return <div className={`side-menu${open ? ' display' : ''} `} tabIndex={-1} >
		{data && <SideMenuList mainMenuOpen={open} data={data} />}
	</div>;
};

export default SideMenu;

