import React, { useState } from 'react';
import FooterStore from './FooterStore';

const FooterProvider = ({ children }) => {

	const [hidden, setHidden] = useState(true);

	return (
		<FooterStore.Provider
			value={{
				hidden,
				hideFooter: (val = true) => {
					setHidden(val);
				}
			}}>
			{children}
		</FooterStore.Provider>
	)
}

export default FooterProvider;