import React from 'react';

import './scss/ChannelThumb.scss';

const ChannelThumb = ({data = {}, title = ""}) => {
	const thumbnails = data.thumbnails || {},
		thum1_1 = thumbnails['1x1'] || thumbnails['16x9'] || {},
		actualThumbUrl = thum1_1['350'] || thum1_1['250'] || thum1_1['150'] || false;

	return <div className='channel-thumb'>
		<img src={actualThumbUrl || ''} className="channel-img" alt="tv-channel" title={title}/>
	</div>;
}

export default ChannelThumb;