import React from 'react';
import Loadable from '@react-loadable/revised';

const emptyWidgetFactoryMap = {
	HybridDetailWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/emptyWidgets/EmptyDetailsWidget.js'), loading: () => <div></div> }),
		idPrefix: "md_",
	},
	EpgWidgetV2: {
		type: Loadable({ loader: () => import('../epgV3/EmptyEpgWidgetV3.js'), loading: () => <div></div> }),
		idPrefix: "epgw2",
	}
};
export default emptyWidgetFactoryMap;