import React, { useEffect } from 'react';
import { ALL_APP_SETTINGS, getEnvVar, getLC } from '../../../util/lib';
import { JUPITER, EARTH, VENUS, MARS, QUADRADO } from './ThemeWrapper';

const getClassName = (id) => {
	switch (id) {
		case (3):
			return JUPITER;
		case (2):
			return EARTH;
		case (4):
			return MARS;
		case (5):
			return QUADRADO;
		default:
			return VENUS;
		}
};

const ClassWrapper = (Comp) => {
	return (props = {}) => {
		const className = getClassName(parseInt(props.themeID || 1)),
		allAppSettings = getLC(ALL_APP_SETTINGS),
		webSettings = allAppSettings.web_settings || {},
		fontConfig = (webSettings.font || "").toLowerCase();

		useEffect(() => {
			if (typeof window !== 'undefined') {
				const doc = window.document || '', 
					fontClass = String(getEnvVar('REACT_APP_CUSTOM_FONT') || fontConfig || '').toLowerCase();


				if (doc) {
					const body = doc.querySelector('body');

					body.className = `${className}${fontClass ? ' ' + fontClass : ''}`;
				}
			}
		}, []);

		return (<Comp {...{ className, ...props }}></Comp>);
	};
};

export default ClassWrapper;
