import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { getEnvVar } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';
import { loadFireBase } from '../../../util/firebaseUtils';

const LOAD_COUNT = 50,
	FireBaseWrapper = (Comp) => {
		class FireBaseWrapperComp extends Component {
			constructor (props) {
				super(props);

				console.log('FF@1', props);
		
				this.state = {
					messages: [],
					app: '',
					newAdd: false,
					newMessageCount: 0,
					start: null,
					end: null,
					listners: []
				};
		
				this.loadMore = this.loadMore.bind(this);
			}
			
			getMessages (query, onLoad = false) {
				console.log('livechat: getMessages firebase:');

				const unsubscribe = query.onSnapshot((snapshot) => {
					if (onLoad) {

						/** WARNING */
						//Ideally right way to retrive data from firestore in a realtime way is to listen for changes and get the new data changes alone from the server
						//But the below commented code is not doing that properly and it keeps polluting the data by reordering it randomly for every event fire and 
						//duplicating it
						//since react state and reconsilation is efficient and we are keeping only one day log i replaced this logic with whole retrieval, we should not
						//see any major changes in how this widget works
						// however this will be fixed soon as a part of live change enhancement task
						/************ */

						// snapshot.docChanges().forEach((change, index) => {
						// 	console.log("Firebase wrapper: getMessages:", change);
						// 	if (change.type === 'removed') {
						// 		this.setState({newAdd: !0});
						// 	} else {
						// 		const data = change.doc.data(),
						// 			state = this.state;
						// 		console.log("testtt>:",data);
						// 		if (state.newAdd) {
						// 			this.setState({newAdd: !1}, () => {
						// 				const lState = this.state;
						// 				this.setState({
						// 					newMessageCount: lState.newMessageCount + 1,
						// 					messages: [...lState.messages, data]
						// 				});
						// 			});
						// 		} else {
						// 			this.setState({
						// 				newMessageCount: state.newMessageCount + 1,
						// 				messages: [...state.messages, data]
						// 			});
						// 		}
						// 	}
						// });
						
						
						const snapDocs = snapshot.docs || [];
						const messages = snapDocs.map(doc => doc.data());


						console.log(this.state.messages, messages, 'deeee 1');
						this.setState({ 
							newMessageCount: this.state.newMessageCount + 1,
							messages: messages
						});

					} else {
						const snapshotData = snapshot.docs || [],
							snapshotDataFiltered = snapshotData.filter((doc = '') => {
								return doc && (typeof doc.data === 'function');
							}),
							messages = snapshotDataFiltered.map((doc) => {
								return doc.data() || {};
							});

						console.log(this.state.messages, messages, 'deeee 2');

						this.setState({
							messages: [...messages, ...this.state.messages]
						});
					}
				});

				return unsubscribe;
			}

			queryFireBase (onLoad = false) {
				console.log('livechat: query firebase:');
				const state = this.state,
					data = this.props.data || {},
					fireBaseStore = data.firebase_cloudstore || {},
					fireBaseCollection = fireBaseStore.collection || '',
					app = state.app || '';
				
					console.log("livechat: queryFirebase: ",{
						fireBaseCollection,
						app,
						instance:app.firebase
					});

				// asc & desc 
				if (fireBaseCollection && app && app.firestore) {
					console.log('livechat: query firebase > inside if');
					const orderedCollection = app.firestore().collection(fireBaseCollection).orderBy("timestamp", "desc"),
						query = onLoad ? orderedCollection.limit(LOAD_COUNT) : orderedCollection.startAfter(state.start).limit(LOAD_COUNT),
						unsubscribe = query.onSnapshot((snapshot) => {
							const docs = snapshot.docs || [],
								len = docs.length;

							unsubscribe();
							// if (len) {
							const start = docs[len - 1] || 0,
								end = this.state.start;

							this.setState({start, end}, () => {
								const orderedCollection = app.firestore().collection(fireBaseCollection).orderBy("timestamp"),
									query = onLoad ? orderedCollection.startAt(start) : orderedCollection.startAt(start).endBefore(end),
									msgUnsubscribe = this.getMessages(query, onLoad);
							
								this.setState({listners: [...this.state.listners, msgUnsubscribe]});
							});
							// }
						});
					
				}
			}

			

			loadMore () {
				this.queryFireBase();
			}

			componentDidMount () {
				const app = loadFireBase();
				console.log('livechat: componentDidMount:', app);
				if(app) {	
					this.setState({app}, () => {
						this.queryFireBase(true);
					});
				}
			}
			componentWillReceiveProps (prevProps) {
				console.log("FWP:componentDidUpdate:",this.state.app);
				// if()
				const {listners = []} = this.state;
				console.log("FWP:componentDidUpdate:",this.state.listners.length,);

				console.log("FWP:componentDidUpdate:",!listners.length > 0);
				if(!listners.length > 0){
					console.log("No listeners found");
					this.queryFireBase(true);
				}
			}

			componentWillUnmount () {
				const { app, listners } = this.state;
					
				listners.forEach((unsubscribe) => {
					unsubscribe();
				});
			}

			render () {
				const messages = this.state.messages;

				return (<Comp messages={messages} newMessageCount={this.state.newMessageCount} loadMore={this.loadMore}></Comp>);
			}
		};

		return FireBaseWrapperComp;
	};

export default FireBaseWrapper;