import React, { useState, useEffect } from 'react';
import '../scss/SearchForm.scss';
import { generateRandomID, getAPIData } from './../../util/lib';
import { showNotification } from './../../util/notification';
import { ga4Event } from './trackers/GA4Tracker';
import { debugLog } from '../../util/logUtils';
import Search from '../svg/Search/Search';
import { debounce } from '../../util/limitingUtilis';

const DEFAULT_SEARCH_LABEL = 'Search',
	SEARCH_LIST = 'search-list',
	loadSearchPage = (searchValue = '') => {
		ga4Event('search', { search_term: searchValue });
		window.location.href = `/search?qry=${encodeURIComponent(searchValue)}`;
	};

const SearchForm = (props) => {
	const propsData = props.data || {},
		data = propsData.data || {},
		{labels = {}} = data,
		qry = data.qry || '',
		id = props.id,
		title = data.title || null,
		containerYN = props.inline ? '' : ' container',
		inPage = props.inline ? '' : ' in-page',
		actionURL = data.actionURL || {},
		action = actionURL.path,
		{search_label_small = "", search_label_large = ""} = labels,
		[prevValue, setPrevValue] = useState(qry),
		[value, setValue] = useState(qry),
		[searchLabelSmall] = useState(search_label_small || DEFAULT_SEARCH_LABEL),
		[searchLabelBig] = useState(search_label_large || DEFAULT_SEARCH_LABEL),
		[searchLabel, setSearchLabel] = useState(search_label_large || DEFAULT_SEARCH_LABEL),
		[searchId] = useState(`vtn-src-bx-${generateRandomID(6)}`),
		[searchListId] = useState(`vtn-${SEARCH_LIST}-${generateRandomID(6)}`),
		[searchSuggestions, setSearchSuggestions] = useState([]),
		getAPIDataDebounce = debounce(getAPIData, 2000);

	useEffect(() => {
		if (data) {
			const searchSuggestionsURLObj = data.searchSuggestionsURL || {},
				searchSuggestionsURL = searchSuggestionsURLObj.url || '';

			if (value && searchSuggestionsURL) {
				getAPIDataDebounce(searchSuggestionsURL, { 
					qry: value 
				}, (response = {}) => {
					const responseData = response.data || {},
						widgetsRaw = responseData.widgets || [],
						widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [],
						widget = widgets.length ? widgets[0] || {} : {},
						wData = widget.data || {},
						data = wData.data || [],
						searchSuggestionsL = Array.isArray(data) ? data : [];

					debugLog(widget);

					setSearchSuggestions(searchSuggestionsL);
				});
			}
		}
	}, [data, value]);

	useEffect(() => {
		if (value !== qry && searchSuggestions && searchSuggestions.length && searchSuggestions.indexOf(value) >= 0) {
			loadSearchPage(value);
		}
	}, [value, searchSuggestions])

	useEffect(() => {
		const checkSize = () => {
			const searchBox = document.querySelector(`#${searchId}`) || {},
				searchWidth = searchBox.offsetWidth || 0,
				newSearchLabel = searchWidth <= 0 || searchWidth >= 255 ? searchLabelBig : searchLabelSmall;
	
			setSearchLabel(newSearchLabel);
		};

		checkSize();
		window.addEventListener('resize', checkSize);
		
		return () => {
			window.removeEventListener('resize', checkSize);
		};
	}, []);


	return (
		<div id={id} className={`v-widget search-form${containerYN}`}>
			{title && (
				<div className="widget-title">
					<h2>{title}</h2>
				</div>
			)}
			<form 
				id="sf" 
				action={action} 
				className={`form-inline${inPage}`} 
				onSubmit={(event) => {
					const searchValue = value,
						wData = props.data || {},
						data = wData.data || {},
						labels = data.labels || {},
						searchTextMissing = labels.search_text_missing || 'Search is empty!',
						prevValueL = prevValue;
			
					setPrevValue(value);
			
					if (searchValue === '') {
						showNotification({ text: searchTextMissing });
					} else if (searchValue === prevValueL) {
						event.preventDefault();
					} else {
						loadSearchPage(searchValue);
					}
			
					event.preventDefault();
				}}
			>
				<div className={`input-group${inPage}`}>
					<input
						className="form-control search-box"
						id={searchId}
						placeholder={searchLabel}
						type="search"
						aria-label="Search"
						name="qry"
						value={value}
						onChange={(event = {}) => {
							const target = event.target || {}, 
								newValue = target.value || '',
								searchSuggestions = searchSuggestions || [];

							setValue(newValue);
						}}
						autoComplete="off"
						list={searchListId}
					/>
					{<datalist className={SEARCH_LIST} id={searchListId}>
						{searchSuggestions.map((suggestion = '', index = 0) => {
							return <option key={`op-${index}`} value={String(suggestion)} />;
						})}
					</datalist>}
					<button className="btn search-button" type="submit">
						<Search />
					</button>
				</div>
			</form>
		</div>
	);
};

export default SearchForm;
