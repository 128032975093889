import React from 'react';
import { getSizeClass } from '../../../../util/classMapperUtils';
// import { debugLog } from '../../../../util/logUtils';
import LinkWrapper from '../../util/LinkWrapper';
import { iconMapper } from '../maps/iconMapper';


const HybridLink = (props) => {
	const wdata = props.data || {},
		tabIndex = props.tabIndex || 0,
		className = props.className || '',
		onClick = props.onClick || '',
		data = wdata.data || {},
		meta = wdata.meta || {},
		label = data.label || "",
		lableRestriction = parseInt(props.lableRestriction) || false,
		restrictedLabel = lableRestriction ? label.substring(0, lableRestriction) : label,
		icon = data.icon || "Facebook",
		action = data.action || {},
		primary = action.primary || {},
		srText = data.label || primary.label || data.icon || "",
		title = data.title || "",
		logo = data.logo || "",
		description = data.description || "",
		copyrightText = data.copyrightText || "",
		labelMeta = meta.label || {},
		logoMeta = meta.logo || {},
		descriptionMeta = meta.description || {},
		iconMeta = meta.icon || {},
		copyrightTextMeta = meta.copyrightText || {},
		alignment = meta.alignment || '',
		labelSize = labelMeta.size || "small",
		iconSize = iconMeta.size || "md",
		labelSizer = (labelSize === "large") ? "label-lg" : "label-sm",
		iconClass = getSizeClass(iconSize || "md"),
		IconComp = iconMapper[icon],
		lableAdditionalClass = props.labelAdditionalClass || "",
		linkAdditionalClass = props.linkAdditionalClass || "";
	console.log("tabb:", tabIndex, label)
	return (
		<div data-testid="h-link-container" className={`h-link-container m-1${(alignment === 'center' ? ' text-center' : '')}${copyrightTextMeta.canShow ? ' copyright' : ''}${className ? ` ${className}` : ''} ${primary.url ? '' : ' link-disabled'}`}>
			{logoMeta.canShow && <LinkWrapper tabIndex={tabIndex} className={`link ${linkAdditionalClass}`} action={primary} onClick={(evt) => {
				if (onClick) {
					onClick(evt);
				}
			}}>
				<img className="" src={logo} alt={label} title={label}></img>
			</LinkWrapper>}
			{descriptionMeta.canShow && <div className="vt-description">{description}</div>}

			{(iconMeta.canShow && IconComp) && <LinkWrapper tabIndex={tabIndex} className={`link ${linkAdditionalClass}`} action={primary} onClick={(evt) => {
				if (onClick) {
					onClick(evt);
				}
			}}>
				{/*using linkwrapper so we shuld nt use anchor tag*/}
				<span className='vt-sr-only'>{srText}</span>
				<span className={`svg-icon-wrapper icon ${iconClass}`}> <IconComp /> </span>
			</LinkWrapper>}

			{labelMeta.canShow && <LinkWrapper tabIndex={tabIndex} className={`link ${lableAdditionalClass} ${linkAdditionalClass}`} action={primary} onClick={(evt) => {
				if (onClick) {
					onClick(evt);
				}
			}}>
				<span className={`link ${labelSizer}`} >{restrictedLabel}</span>
			</LinkWrapper>}
			{copyrightTextMeta.canShow &&
				<span className='d-inline-block'>
					<span>{title && <span className='domain-name'>{title + ' '}</span>}{copyrightText || ''} </span>
				</span>
			}
		</div>
	);
}


export default HybridLink;