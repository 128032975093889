import DOMPurify from 'dompurify';
import React from 'react';
import './scss/PageLevelPopup.scss';
import { iconMapper } from '../maps/iconMapper';
import { getL } from '../../../../util/lib';

const PageLevelPopup = (props = {}, popupStatus = true,) => {
    const widget = props.widget || {},
        wData = widget.data || {},
        // { customAction = '' } = wData,
        wMeta = widget.meta || {},
        // labels = wData.labels || {},
        // loginMessage = labels.loginMessage || "",
        // resetMessage = labels.resetMessage || "",
        title = wData.title || "",
        steps = wData.steps || [],
        widgetCanShow = wMeta.canShow || false,
        hideMigrationPopup = getL('hideMigrationPopup') || false,
        socialLinksPageMeta = wMeta.socialLinks || {},
        socialLinksCanShow = socialLinksPageMeta.canShow,
        titleMeta = wMeta.title || "";

    //states
    const [isOpen, setIsOpen] = React.useState(widgetCanShow && !hideMigrationPopup);

    const handleOnClose = (event) => {
        event.preventDefault();
        setIsOpen(false);
    }

    console.log(props, "fffprops");

    return (
        <div className={`page-level-popup ${isOpen ? 'open' : ''}`}>
            <div className='popup'>
                <div className={`popup-container`}>
                    <div className='popup-header'>
                        {titleMeta.canShow && <div className='popup-title'>
                            {title}
                        </div>}
                        <button className='popup-close' onClick={handleOnClose}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                    <div className='popup-body'>

                        {steps.map((step, index) => {
                            const { label = '',
                                socialLinks = {} } = step,
                                socialLinksData = socialLinks.data || {},
                                socialLinksCards = socialLinksData.cards || [];
                            return (
                                <div key={index} className='popup-step'>
                                    {label && <div className='popup-step-label'
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label, { ADD_ATTR: ['target'] }) }} />}


                                    {socialLinksCanShow && socialLinksCards.length > 0 && <div className='social-share'>
                                        {socialLinksCards.map((card = {}, index = 0) => {
                                            const Icon = iconMapper[card.icon || ''] || null,
                                                iconLink = card.link || "",
                                                iconLabel = card.label || "";

                                            return (<div className='social-link-card' key={`slc-${index}`} title={iconLabel} onClick={() => window.open(iconLink, "_blank")}>
                                                <div className='icon-div'>{<Icon />}</div>
                                            </div>)
                                        })}
                                    </div>}
                                </div>
                            );
                        })}


                        {/* {loginMessage && <div className='login-message'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(loginMessage, { ADD_ATTR: ['target'] }) }} />}
                        {socialLinksCanShow && <div className='social-share'>
                            {socialLinksCards.map((card = {}, index = 0) => {
                                const Icon = iconMapper[card.icon || ''] || null,
                                    iconLink = card.link || "",
                                    iconLabel = card.label || "";

                                return (<div className='social-link-card' key={`slc-${index}`} title={iconLabel} onClick={() => window.open(iconLink, "_blank")}>
                                    <div className='icon-div'>{<Icon />}</div> 
                    </div>) */}
                        {/* })}
                        </div>}
                        {resetMessage && <div className='reset-message'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resetMessage, { ADD_ATTR: ['target'] }) }} />} */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PageLevelPopup