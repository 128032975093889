const tagsDict = {},
	loadJSTagInternal = (tag) => {
		return new Promise((resolve, reject) => {
			const doc = ((typeof document) !== 'undefined') ? document : '';

			if (doc) {
				const script = doc.createElement('script');
				script.async = true;
				script.onload = function () {
					resolve(true);
				};
				script.src = tag;
				document.head.appendChild(script);
			} else {
				reject(false);
			}
		});
	},
	loadJSTag = (tag) => {
		return new Promise((resolve, reject) => {
			const tagObj = tagsDict[tag] || {
				tag,
				loaded: false
			};

			tagsDict[tag] = tagObj;

			if (tagObj.loaded) {
				resolve(true);
			} else {
				tagObj.tagLoader = loadJSTagInternal(tag).then(() => {
					tagsDict[tag].loaded = true;
					resolve(true);
				}).catch(() => {
					reject(false);
				});
			}
		});
	};

export default loadJSTag;