import React from 'react';

import '../../scss/venus/VenusMaster.scss';
import { Helmet } from 'react-helmet';
import ErrorBoundaryDefault from '../ErrorBoundaries/ErrorBoundaryDefault';
import { getEnvVar } from '../../../util/lib';

const VenusMaster = () => {
	const fontType = getEnvVar('REACT_APP_CUSTOM_FONT') || '';

	return (
		<ErrorBoundaryDefault>
			<Helmet>
				{/* <link href="/fonts/fonts.scss" rel="stylesheet"></link> */}
				<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600" rel="stylesheet"></link>
				{fontType === 'made-tommy' && <link href="/fonts/made-tommy/made-tommy.css" rel="stylesheet"></link>}
			</Helmet>
		</ErrorBoundaryDefault>
	);
}

export default VenusMaster;