import React, { Component } from 'react';

import EmojiButton from '@joeattardi/emoji-button';

import '../../scss/videoComments/CommentField.scss';
import UserBox from './UserBox';
import Send from '../../svg/Send/Send';
import SpinnerLoader from './../AnimatedComponents/SpinnerLoader';
import { getAPIData } from '../../../util/lib';
import { getUserDetails } from '../../../util/lib';
import { showNotification } from '../../../util/notification';
import { debugLog } from '../../../util/logUtils';

const COMMENT_FIELD = 'comment-field',
	MSG_SOMETHING_WENT_WRONG = 'Sorry, something went wrong!',
	MAX_CHAR_DFLT = 150;

class CommentField extends Component {
	constructor(props) {
		const data = props.data || {},
			randomNum = Math.random().toString().replace('.', '');

		super(props);

		this.state = {
			id: COMMENT_FIELD + '-id-' + randomNum,
			formID: COMMENT_FIELD + '-' + randomNum,
			inputVal: '',
			picker: false,
			charLimit: parseInt(data.comments_char_limit) || MAX_CHAR_DFLT,
			isLoading: false,
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onEmojiClick = this.onEmojiClick.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onKeyDown(event = {}) {
		event.persist();
		event.stopPropagation();
	}

	onSubmit(evnt = {}) {
		const props = this.props,
			// userID = props.userID,
			data = props.data || {},
			actionURLObj = data.actionURL || {},
			apiURL = actionURLObj.url || '',
			inputVal = this.state.inputVal || '',
			userName = props.userName,
			{ isLoading = false } = this.state;

		if (!isLoading) {
			this.setState({ isLoading: true });
			if (apiURL) {
				getAPIData(
					apiURL,
					{
						user_name: userName,
						comments: inputVal,
						video_id: props.videoID
					},
					(response = {}) => {
						const responseData = response.data || {},
							message = responseData.message || '';

						if (response.type === 'Success') {
							this.setState({ inputVal: '', isLoading: false });
						} else {
							showNotification({ text: message || MSG_SOMETHING_WENT_WRONG });
							this.setState({ inputVal: '', isLoading: false });
						}
						debugLog(response);
					}
				);
			} else {
				showNotification({ text: MSG_SOMETHING_WENT_WRONG });
				this.setState({ inputVal: '', isLoading: false });
			}
		}

		evnt.preventDefault();
		return false;
	}

	onChange(evnt = {}) {
		const target = evnt.target || {},
			inputVal = String(target.value || '').substring(0, this.state.charLimit),
			{ isLoading = false } = this.state;
		if (!isLoading) {
			this.setState({ inputVal });
		}
	}

	onEmojiClick(evt = {}) {
		const picker = this.state.picker;

		if (picker) {
			picker.togglePicker(evt.target);
		}

		setTimeout(() => {
			const document = window.document;

			if (document) {
				const emojiPickerDom = document.querySelector('.emoji-picker'),
					emojiPickerDomParent = emojiPickerDom ? emojiPickerDom.parentElement || '' : '';

				if (emojiPickerDomParent && emojiPickerDomParent.style) {
					emojiPickerDomParent.style.zIndex = 99999;
				}
			}

		}, 300)
	}

	componentDidMount() {
		const picker = new EmojiButton({
			emojiVersion: '11.0',
			emojiSize: '1em',
			theme: 'dark',
			showPreview: false,
			showSearch: false
		}),
		field = document.getElementById(this.state.id);

		field.addEventListener('keypress', (evt) => {
			evt.cancelBubble = true;
			return evt;
		});

		picker.on('emoji', (emoji) => {
			const inputVal = this.state.inputVal + emoji;

			this.setState({ inputVal });
		});

		this.setState({ picker });
	}
	render() {
		const state = this.state,
			props = this.props,
			user = getUserDetails(),
			userName = user.nickname || props.userName,
			pData = props.data || {},
			profilePicture = pData.thumb || '',
			{ isLoading = false } = state;

		console.log('ccccccccc', user.id);

		return (
			<div className={COMMENT_FIELD}>
				<div className="form-loader-wrapper">
					{isLoading && <div className='loader-bg'>
						<SpinnerLoader />
						<p>Sending chat ...</p>
					</div>}
					<form id={state.formID} name={COMMENT_FIELD + '-form'} onSubmit={this.onSubmit}>

						<div className="header-box">
							<input
								name="field"
								type="text"
								required={true}
								value={state.inputVal}
								onChange={this.onChange}
								id={state.id}
								placeholder='Share your thoughts!'
							/>
							<div className="emoji-icon" onClick={this.onEmojiClick}>
								<span role="img" aria-label="smile">😀</span>
							</div>
							<button type="submit" form={state.formID || ''} value="Submit">
								<Send />
							</button>
						</div>
						<div className="input-wrp">

						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default CommentField;
