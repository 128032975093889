import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import './../scss/App.scss';

import VentunoPlayer from './videoPlayer/VentunoPlayer';
import VideoPlayerProvider from './videoPlayer/VideoPlayerProvider';

import Page from './Page';
import ShoppingCartManager from './ShoppingCartManager';
import GATracker from './trackers/GATracker';
import FBPixelTracker from './trackers/FBPixelTracker';

import { getEnvVar, getAPIData, getL, parseQryString, setUserDetails, clearUserDetails, setL, generateDID, setBaseAPIs, getSettingsAPI, getThemeID, getAllAppSettings } from '../../util/lib';

import MasterLoader from './factory/MasterLoader';
import HeaderManager from './header/HeaderManager';
import HeaderMaster from './header/HeaderMaster';
import ComingSoon from './comingsoon/ComingSoon';
import RouterWrapper from './router/RouterWrapper';
import GA4Tracker from './trackers/GA4Tracker';
import { cacheRefteshTokenApi } from '../../util/authUtils';
import FooterManager from './Footer/FooterManager/FooterManager';
import GDPRMaster from './GDPR/GDPRMaster';
import GDPRProvider from '../../contexts/gdprContext/GDPRProvider';
import { debugLog } from '../../util/logUtils';
import VersionAPI from './util/VersionAPI';
import SideMenuManager from './sideMenu/SideMenuManager';
import SideMenu from './sideMenu/SideMenu';
import PopupProvider from './context/popupContext/PopupProvider';
import LiblynxAuth from './AuthHelpers/LiblynxAuth';
import AutoDeviceConnect from './AuthHelpers/AutoDeviceConnect';
import VideoPlayerStore from './videoPlayer/VideoPlayerStore';
// import { RouterContext } from './router/RouterContext';
import { addPreconnectTag } from '../../util/preconnectScriptUtil';
// import PageProvider from './context/PageContext/PageProvider';
import { TERMS_CONSENT_CHECK } from '../../util/universalConstans';
import SiteColorConfigurer from './util/SiteColorConfigurer';
import FooterProvider from './hybrid/footer/FooterProvider';

const App = (props = {}) => {
	const [appStatus] = useState(getEnvVar('REACT_APP_STATUS')),
		[settingsData, setSettingsData] = useState(null),
		[isLoaded, setIsLoaded] = useState(false),
		[shopEnabled, setShopEnabled] = useState(false),
		[termsConsentCheck, setTermsConsentCheck] = useState(false),
		[canProceedWithAuthCallout, setCanProceedWithAuthCallout] = useState(false),
		[routingAPI, setRoutingAPI] = useState(''),
		[seoApi, setSeoApi] = useState(''),
		[apiURL, setApiURL] = useState(''),
		[ splashScreen, setSplashScreen ] = useState(null),
		[homeAction, setHomeAction] = useState(false);

	useEffect(() => {
		const currentLocation = props.loc || '',
			isWebview = currentLocation && currentLocation.search.indexOf('webview') !== -1;

		if (isWebview) {
			setL('webview', true);
		}
		if ((isWebview) && (currentLocation && currentLocation.search.indexOf('user_id') !== -1)) {
			const usr = parseQryString(currentLocation.search);
			if (parseInt(usr.user_id) > 0) {
				setL('webview_user_id', usr.user_id);
				getAPIData(process.env.REACT_APP_PROFILE_API_PATH, {
					'user_id': usr.user_id
				}, (response = {}) => {
					const rspData = response.data || {},
						widgets = rspData.widgets || [],
						widget = widgets.length ? widgets[0] || {} : {},
						widgetData = widget.data || {},
						user = widgetData.user;

					setUserDetails(user.data || {});
				})
			} else {
				clearUserDetails();
			}
		}
	}, []);

	useEffect(() => {

		if (appStatus !== "comingsoon") {
			const data = {};
			data.did = getL('did');

			if (!data.did) {
				data.did = generateDID();
				setL('did', data.did);
			}

			getAllAppSettings(data).then((response = {}) => {
				cacheRefteshTokenApi();

				getSettingsAPI().then((settings = {}) => {
					setSettingsData(settings);
					if(settings.ga_measurement_id){
						addPreconnectTag("https://www.googletagmanager.com");
					}
				}).catch(() => {
					console.error("Unable to get Settings Data")
				});

				// this.setState({
				// 	shopEnabled: !(!response.shopEnabledYN),
				// 	termsConsentCheck: !(!getL(TERMS_CONSENT_CHECK)),
				// 	canProceedWithAuthCallout: !(!response.canProceedWithAuthCalloutYN),
				// 	base_apis: response.base_apis,
				// 	routing_api: response.routing_api,
				// 	home_page: response.home_page,
				// 	api_url: response.home_page,
				// 	seoApi: response.seo_meta_api || '',
				// 	isLoaded: true,
				// });
				setShopEnabled(!(!response.shopEnabledYN));
			    setTermsConsentCheck(!(!getL(TERMS_CONSENT_CHECK)));
			    setCanProceedWithAuthCallout(!(!response.canProceedWithAuthCalloutYN));
				setSplashScreen(response.splash_screen);
                setIsLoaded(true);
				setHomeAction(response.home_action || false);
			    setRoutingAPI(response.routing_api || '');
			    setSeoApi(response.seo_meta_api || '');
			    setApiURL(response.home_page || '');
				
			}).catch(() => {
				console.error("Unable to get Settings Data");
			});


		} else {
			setIsLoaded(true);
		}
	}, []);


	if (appStatus === 'comingsoon') {
		return <ComingSoon />
	} else if (!settingsData) {
		return (null);
	} else {
		const themeID = getThemeID();

		return (<>
			{isLoaded && apiURL && <>
				<SiteColorConfigurer settingsData={settingsData} />
				<Switch>
					<Route
						path='/autoconnect'
						component={props => <AutoDeviceConnect  {...props} />}
					/>
					<Route
						path='/liblynxAuth'
						component={props => <LiblynxAuth  {...props} />} />
					<Route path='/' render={
						(props) => {
							const location = props.location,
								pathname = location.pathname,
								// keyname = props.history.length + "_" + pathname,
								isWebview = getL('webview') || location.search.indexOf('webview') !== -1;

							if (isWebview) {
								setL('webview', true);
							}

							return (<>
								<GDPRProvider>
									<GDPRMaster />
								</GDPRProvider>
								<FooterProvider>
									<VideoPlayerProvider>
										<SideMenuManager>
											<MasterLoader themeID={themeID} font = {settingsData.font || false} />
											<RouterWrapper>
												<HeaderManager isWebview={isWebview}>
													<ShoppingCartManager shopEnabled={shopEnabled} path={pathname}>
														<Helmet>
															{settingsData.favicon &&
																<link rel="shortcut icon" href={settingsData.favicon} />
															}
															{settingsData.google_webmaster_verification &&
																<meta name="google-site-verification" content={settingsData.google_webmaster_verification} />
															}
															<title></title>

														</Helmet>

														{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
														{settingsData.fb_pixel_id &&
															<FBPixelTracker fbpTrackerId={settingsData.fb_pixel_id} />
														}
														{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
														{settingsData.google_analytics &&
															<GATracker gaTrackerId={settingsData.google_analytics} />
														}
														{settingsData.ga_measurement_id &&
															<GA4Tracker gaMeasurementID={settingsData.ga_measurement_id} />
														}
														<HeaderMaster />
														{/* Player Script START*/}
														<div className="container">
															<div className='row'>
																<div id={'ram_vp2'}> </div>
															</div>
															<div className='row'>
																<VideoPlayerStore.Consumer>
																	{({ videoDetails = {}, onCloseClickCount = false, commentBox = '', setPlayerActive = false, epgData = '', setVideoDetails = false, setInPageTakeOver = '' }) => {
																		debugLog('weeeeee, wer', videoDetails);
																		return <VentunoPlayer
																			commentBox={commentBox}
																			targetID={videoDetails.videoTargetid}
																			preload={videoDetails.videoPreload}
																			videoId={videoDetails.videoId}
																			videoKey={videoDetails.videoKey}
																			ratio='16:9'
																			pageTakeOver={videoDetails.videoPageTakeOver}
																			limitedViewing={videoDetails.limitedViewing}
																			setVideoDetails={setVideoDetails}
																			resume={videoDetails.resume}
																			forceBegin={videoDetails.forceBegin}
																			exitOnComplete={videoDetails.exitOnComplete}
																			setPlayerActive={setPlayerActive}
																			epgData={epgData}
																			onCloseClickCount={onCloseClickCount}
																			setInPageTakeOver={setInPageTakeOver}
																			startMuted={videoDetails.startMuted}
																		/>;
																	}}
																</VideoPlayerStore.Consumer>
															</div>
														</div>
														{/* Player Script END*/}
														<PopupProvider>
															<Page {...props}
																homeAction={homeAction}
																routingAPI={routingAPI}
																canProceedWithAuthCallout={canProceedWithAuthCallout}
																settingsData={settingsData}
																themeID={themeID}
																splashScreen={splashScreen}
																seoApi={seoApi} />
														</PopupProvider>
														{!isWebview ? <FooterManager theme={themeID} settingsData={settingsData} termsConsentCheck={termsConsentCheck} /> : null}
													</ShoppingCartManager>
												</HeaderManager>
												{/* <SideMenu /> */}
											</RouterWrapper>
										</SideMenuManager>
									</VideoPlayerProvider>
								</FooterProvider>
							</>);
						}
					} />
				</Switch>
			</>}
		</>);
	}
};

export default VersionAPI(App);

