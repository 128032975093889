const avoidPageLevelPopupIN = [
    '/forgot',
    '/forgot/',
    '/loginas',
    '/loginas/',
    '/start',
    '/start/'
];

export const canShowPageLevelPopup = (route) => {
    return !avoidPageLevelPopupIN.includes(route);
}