import React from 'react';
import Loadable from '@react-loadable/revised';

export const iconMapper = {
	Facebook: Loadable({
		loader: () => import('../../../svg/Social/FbIcon'),
		loading: () => <></>
	}),
	Blog: Loadable({
		loader: () => import('../../../svg/Social/Blog'),
		loading: () => <></>
	}),
	Instagram: Loadable({
		loader: () => import('../../../svg/Social/InstaIcon'),
		loading: () => <></>
	}),
	Twitter: Loadable({
		loader: () => import('../../../svg/Social/TwitterIcon'),
		loading: () => <></>
	}),
	Play: Loadable({
		loader: () => import('../../../svg/PlayIcon/PlayIcon'),
		loading: () => <></>
	}),
	YoutubeIcon: Loadable({
		loader: () => import('../../../svg/Social/YoutubeIcon'),
		loading: () => <div></div>
	}),
	Youtube: Loadable({
		loader: () => import('../../../svg/Social/YoutubeIcon'),
		loading: () => <div></div>
	}),
	ClosedCaption: Loadable({
		loader: () => import('../../../svg/Play/ClosedCaption'),
		loading: () => <div></div>
	}),
	Share: Loadable({
		loader: () => import('../../../svg/Social/Share'),
		loading: () => <div></div>
	}),
	Star: Loadable({
		loader: () => import('../../../svg/Star/Star.js'),
		loading: () => <div></div>
	}),
	PlayVideo: Loadable({
		loader: () => import('../../../svg/Play/Play.js'),
		loading: () => <div></div>
	}),
	CirclePause: Loadable({
		loader: () => import('../../../svg/IconV2-2/CirclePause'),
		loading: () => <div></div>
	}),
	CirclePlay: Loadable({
		loader: () => import('../../../svg/IconV2-2/CirclePlay.js'),
		loading: () => <div></div>
	}),
	TickMark: Loadable({
		loader: () => import('../../../svg/WatchList/TickMark.js'),
		loading: () => <div></div>
	}),
	Search: Loadable({
		loader: () => import('../../../svg/Search/Search.js'),
		loading: () => <div></div>
	}),
	Collection: Loadable({
		loader: () => import('../../../svg/Collection/Collection'),
		loading: () => <div></div>
	}),
	HexagonWarning: Loadable({
		loader: () => import('../../../svg/Warning/HexagonWarning'),
		loading: () => <div></div>
	}),
	Android: Loadable({
		loader: () => import('../../../svg/Android/Android'),
		loading: () => <div></div>
	}),
	AndroidTV: Loadable({
		loader: () => import('../../../svg/AndroidTV/AndroidTV'),
		loading: () => <div></div>
	}),
	AppleTV: Loadable({
		loader: () => import('../../../svg/AppleTV/AppleTV'),
		loading: () => <div></div>
	}),
	IOS: Loadable({
		loader: () => import('../../../svg/IOS/IOS'),
		loading: () => <div></div>
	}),
	Roku: Loadable({
		loader: () => import('../../../svg/Roku/Roku'),
		loading: () => <div></div>
	}),
	FireTV: Loadable({
		loader: () => import('../../../svg/FireTv/FireTV'),
		loading: () => <div></div>
	}),
	Next: Loadable({
		loader: () => import('../../../svg/RightArrow/RightArrowSlim'),
		loading: () => <div></div>
	}),
	Previous: Loadable({
		loader: () => import('../../../svg/LeftArrow/LeftArrowSlim'),
		loading: () => <div></div>
	}),
	RedDot: Loadable({
		loader: () => import('../../../svg/RedDot/RedDot'),
		loading: () => <div></div>
	}),
	Premium: Loadable({
		loader: () => import('../../../svg/Premium/Premium'),
		loading: () => <div></div>
	}),
	NewIcon: Loadable({
		loader: () => import('../../../svg/NewIcon/NewIcon'),
		loading: () => <div></div>
	}),
	Registered: Loadable({
		loader: () => import('../../../svg/Registered/Registered'),
		loading: () => <div></div>
	}),
	HeartOutline: Loadable({
		loader: () => import('../../../svg/HeartEmpty/HeartEmpty'),
		loading: () => <div></div>
	}),
	HeartFilled: Loadable({
		loader: () => import('../../../svg/HeartFilled/HeartFilled'),
		loading: () => <div></div>
	}),
	SlimQ: Loadable({
		loader: () => import('../../../svg/quadrado/RightArrowSlim'),
		loading: () => <div></div>
	}),
	Slim: Loadable({
		loader: () => import('../../../svg/RightArrow/RightArrowSlim'),
		loading: () => <div></div>
	}),
	Compact: Loadable({
		loader: () => import('../../../svg/RightArrow/RightArrowCompact'),
		loading: () => <div></div>
	}),
	Clock: Loadable({
		loader: () => import('../../../svg/Clock/Clock'),
		loading: () => <div></div>
	}),
	Cart: Loadable({
		loader: () => import('../../../svg/cart/CartFilled.js'),
		loading: () => <div></div>
	}),
	TikTok: Loadable({
		loader: () => import('../../../svg/Social/Tiktok.js'),
		loading: () => <></>
	}),
	X: Loadable({
		loader: () => import('../../../svg/Social/TwitterIcon'),
		loading: () => <></>
	}),
}