//key codes
const ENTER_CODE = 13,
    SPACE_CODE = 32,
    ESCAPE_CODE = 27,
    LEFT = 37,
    RIGHT = 39;

export const keyPressHandler = (event, callback) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === ENTER_CODE || keyCode === SPACE_CODE) {
        event.preventDefault();
        console.log("key:", event.keyCode, event.which);

        if (callback) {
            callback(event);
            return;
        }
    }

    return false;
}

export const escKeyPressWrapper = (event, callback) => {
    const keyCode = event.keyCode || event.which;
    console.log("key:", event.keyCode, event.which);
    if (keyCode === ESCAPE_CODE) {
        event.preventDefault();
        if (callback) {
            callback(event);
            return
        }
    }
    return false;
}


export const arrowKeyPressWrapper = (event, leftCallback, rightCallback) => {
    const keyCode = event.keyCode || event.which;
    console.log("key:", event.keyCode, event.which);
    if (keyCode === LEFT) {
        event.preventDefault();
        leftCallback(event);
        return;
    } else if (keyCode === RIGHT) {
        event.preventDefault();
        rightCallback(event);
        return;
    }
    return false;
}
