import React from 'react';

import './scss/PageLoaderAnimation.scss';
import CoverScreen from './CoverScreen';

const PageLoaderAnimation = (props = {}) => {
	return (<div className="vtn-page-loader-anim">
		<CoverScreen show={true}/>
		<div className="anim-line" />
	</div>);
}

export default PageLoaderAnimation;