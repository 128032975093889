import React, { useState } from 'react';
import { debugLog } from '../../../util/logUtils';
import VideoPlayerStore from './VideoPlayerStore';
import { getAPIData } from '../../../util/lib';

const processNextURL = (nextUrlObj = {}) => {
	if (nextUrlObj.url) {
		return {
			url: nextUrlObj.url,
			urlParams: nextUrlObj.url_params || {},
		}
	} else {
		return false;
	}
};

const VideoPlayerProvider = ({ children }) => {

	const [playerActive, setPlayerActive] = useState(false),
		[commentBox, setCommentBox] = useState(false),
		[videoKey, setVideoKey] = useState(''),
		[epgData, setEpgData] = useState(''),
		[epgDataLoading, setEpgDataLoading] = useState(false),
		[videoDetails, setVideoDetails] = useState({
			videoKey: videoKey,
            videoTargetid: "",
            videoPreload: false,
            videoPageTakeOver: false,
            limitedViewing: false,
		}),
		[inPageTakeOver, setInPageTakeOver] = useState(false),
		[ closeClickCount, setCloseClickCount ] = useState(0);

	return (
		<VideoPlayerStore.Provider
			value={{
				closeClickCount,
				onCloseClickCount: () => {
					setCloseClickCount(closeClickCount + 1);
				},
				inPageTakeOver,
				setInPageTakeOver,
				playerActive,
				setPlayerActive,
				commentBox,
				setCommentBox,
				videoDetails,
				epgData,
				setEpgData,
				loadNextEpgData: (urlObj) => {
					if (!epgDataLoading) {
						const { url, urlParams } = urlObj;

						setEpgDataLoading(true);
						getAPIData(url, urlParams, (response) => {
							if (response.type !== 'Error') {
								const { data: paginationData = {}, meta: paginationMeta = {} } = response,
									{ EpgCardV2: newChannelsSet = [] } = paginationData,
									{ isEnd: paginationIsEnd = false } = paginationMeta;

								setEpgData({
									channels: [...epgData.channels || [], ...newChannelsSet],
									nextURL: paginationIsEnd ? false : processNextURL(paginationData.next_url || false)
								});

								setEpgDataLoading(false);
								// console.log("state change check - 3", epgCategory, epgTabIndex);
								// const { data: paginationData = {}, meta: paginationMeta = {} } = response,
								// 	{EpgCardV2:channels = []} = paginationData,
								// 	{ isEnd: paginationIsEnd = false } = paginationMeta;

								// // setIsLoading(false);
								// setEpgContainerData([
								// 	...epgContainerData,
								// 	...channels,
								// ]);

								// setNextUrlObj(paginationIsEnd ? false : processNextURL(paginationData.next_url || {}));

							} else {
								setEpgDataLoading(false);
							}
						});
					}
				},
				setVideoDetails: (videoDetailsL = {}) => {
					const videoKeyL = videoDetailsL.video_key || "",
               			videoId = videoDetailsL.video_id || 0;

					if (videoKeyL !== videoKey) {
						setVideoKey(videoKeyL);
						setVideoDetails({
							videoId,
							videoKey: videoKeyL,
							videoTargetid: videoDetailsL.video_targetid || "",
							videoPreload: videoDetailsL.video_preload || false,
							videoPageTakeOver: videoDetailsL.video_pageTakeOver || false,
							limitedViewing: videoDetailsL.video_limitedViewing || false,
							resume: videoDetailsL.video_resume || false,
							forceBegin: videoDetailsL.video_forceBegin || false,
							exitOnComplete: videoDetailsL.exitOnComplete,
							startMuted: !(!videoDetailsL.startMuted)
						});
					}
				}
			}}>
			{children}
		</VideoPlayerStore.Provider>
	)
}

export default VideoPlayerProvider