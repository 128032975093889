import React from 'react';

import './scss/JupiterMaster.scss';
import { Helmet } from 'react-helmet';

const JupiterMaster = () => {

	return (<Helmet>
		<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"></link>
	</Helmet>);
};

export default JupiterMaster;