import React, { useContext, useEffect, useState } from 'react';
import TimeProgressBar from "./../../../js/hybrid/uiUtils/TimeProgressBar";
import ButtonView from '../../util/ButtonView';
import RightArrowSlim from '../../../svg/RightArrow/RightArrowSlim';


import './scss/MiniEPGCard.scss';
import VideoCardWrapper, { FULLSCREEN_FORCED } from '../../videoPlayer/VideoCardWrapper';
import VideoPlayerStore from '../../videoPlayer/VideoPlayerStore';


const MiniEPGCard = ({ data = {}, cthumbnails = {}, fullscreenVideo = '', active, onReload = '', initIndex, hoverTitle = "", setNeedsRefresh = false, ...rest }) => {
	const title = data.title || "",
		interval = data.interval || {},
		duration = interval.formatted_text || "",
		showEndEpocsTime = interval.end_value || "",
		showStartEpocsTime = interval.start_value || "",
		thumbnails = cthumbnails || {},
		thum1_1 = thumbnails['1x1'] || thumbnails['16x9'] || {},
		actualThumbUrl = thum1_1['350'] || thum1_1['250'] || thum1_1['150'] || false,
		primaryFullscreenVideo = fullscreenVideo ? fullscreenVideo.primary || {} : {},
		button = primaryFullscreenVideo.button || {},
		buttonMeta = button.meta || {},
		buttonData = button.data || {},
		{ setVideoDetails } = useContext(VideoPlayerStore),
		currentTimeEpochs = Math.floor(Date.now() / 1000),
		durationInSeconds = showEndEpocsTime - showStartEpocsTime,
		elapsedInSeconds = currentTimeEpochs - showStartEpocsTime;

	return <div className='mini-epg-card'>
		<div className='card-inner'>
			<div className='card-inner-top'>
				{initIndex && <div className='card-inner-left'>
					<img src={actualThumbUrl || ''} className="channel-img" alt="tv-channel" title={hoverTitle}/>
				</div>}
				<div className='card-inner-right'>
					<div className='text-container-mini'>
						<div className='mini-epg-title'>{title}</div>
						<div className='mini-epg-duration'>{duration}</div>
					</div>
                    {initIndex && <div className='button-holder'>
						<ButtonView
							className="btn vt-btn btn-lg vt-btn-primary icon-button"
							buttonData={buttonData}
							buttonMeta={buttonMeta}
							onClick={() => {
								const { initVideoPlayer = '' } = rest,
									videoKey = primaryFullscreenVideo.video_key || primaryFullscreenVideo.videoKey || '';
		
								if (initVideoPlayer && videoKey) {
									if (onReload) {
										onReload();
									}
									initVideoPlayer('', true, false, true, FULLSCREEN_FORCED, videoKey, true, setVideoDetails);
								}
								
							}}
						/>
					</div>}
					
				</div>
			
			</div>
			<div className='card-inner-bottom'>
				{initIndex && <TimeProgressBar durationInSeconds={durationInSeconds} elapsedInSeconds={elapsedInSeconds} setNeedsRefresh={setNeedsRefresh}/>}
			</div>
		</div>
		{/* <div className='card-outer'>
			<RightArrowSlim />
		</div> */}
	</div>;
}

export default VideoCardWrapper(MiniEPGCard);