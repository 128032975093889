import React, { useEffect, useState } from 'react';

import './scss/MiniEPGRow.scss';
import MiniEPGCard from './MiniEPGCard';

const MiniEPGRow = ({ data = {}, title = "", onReload = '', setNeedsRefresh=false }) => {
	const { cards = [], fullscreen_video: fullscreenVideo = '' } = data,
		[ activeIndex, setActiveIndex ]  = useState(0),
		[ renderCards, setRenderCards ] = useState([]),
		thumbnails = data.thumbnails || {};

	useEffect(() => {
		let i;

		const renderCardsL = [];

		for (i = activeIndex; i <= activeIndex + 2; i++) {
			if (i < cards.length) {
				renderCardsL.push(cards[i]);
			}
		}

		setRenderCards([...renderCardsL]);
	}, [activeIndex, cards]);

	useEffect(() => {
		cards.forEach((card, indexL) => {
			const interval = card.interval || {},
				showEndEpocsTime = interval.end_value || "",
			 	showStartEpocsTime = interval.start_value || "",
			 	currentTimeEpochs = Math.floor(Date.now() / 1000);

			if (currentTimeEpochs >= showStartEpocsTime && currentTimeEpochs < showEndEpocsTime) {
				setActiveIndex(indexL);
			}
		});
	}, [cards, activeIndex]);

	return <div className='mini-epg-row'>
		{renderCards.map((card = {}, index = 0) => {
			return <MiniEPGCard onReload={onReload} key={`mepg-crd-${index}`} fullscreenVideo={fullscreenVideo} data={card} cthumbnails={thumbnails} active={activeIndex} initIndex={index === 0} hoverTitle={title} setNeedsRefresh={setNeedsRefresh}></MiniEPGCard>;
		})}
	</div>;
}

export default MiniEPGRow;