import React from 'react';

import './scss/QuadradoMaster.scss';
import { Helmet } from 'react-helmet';
import ErrorBoundaryDefault from '../ErrorBoundaries/ErrorBoundaryDefault';
import { fontMapper } from '../FontFactory/FontMapper';

const QuadradoMaster = (props = {}) => {
	const font = props.font || "Prompt";
	console.log("feent:",fontMapper, font, fontMapper[font], props);

	return (<ErrorBoundaryDefault>
		<Helmet>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link href={fontMapper[font]} rel="stylesheet" />
		</Helmet>
	</ErrorBoundaryDefault>);
};

export default QuadradoMaster;