import React, { useContext } from 'react';
import { setL } from '../../../util/lib';
import { VT_URL_PARAMS } from '../../../util/universalConstans';
import RouterLink from '../router/RouterLink';
import { SideMenuContext } from '../sideMenu/SideMenuManager';

const closeHeaderNavbar = () => {
	const headerMenu = document.querySelector('#header-navbar');

	if (headerMenu && headerMenu.classList) {
		headerMenu.classList.remove('show');
	}
},
	LinkWrapper = (props = {}) => {
		const action = props.action || {},
			link = action.path || action.url || '',
			className = props.className || '',
			tabIndex=props.tabIndex || 0,
			onClick = props.onClick || '',
			type = action.type || '',
			tab = action.tab || "",
			url = action.url || '',
			queryParam = props.queryParam || '',
			linkWithQuery = queryParam ? `${link}${queryParam}` : link,
			popup_uri = action.popup_uri || (typeof (url) === 'object' && url.popup_uri),
			{ sideMenuData = {} } = useContext(SideMenuContext),
			{ setOpenState: setSideMenuOpenState = '' } = sideMenuData,
			target = tab === "same" ? "":"_blank";

		return (<>
			{type === 'ExtNavURL' && <a href={url} className={className} target={target} rel="noopener noreferrer nofollow" onClick={closeHeaderNavbar} tabIndex={tabIndex}>{props.children}</a>}
			{type !== 'ExtNavURL' && <>
				{linkWithQuery || url ?<RouterLink tabIndex={tabIndex} className={className} route={{ ...(action.route || '') }} to={popup_uri || linkWithQuery} title={props.title || ''} onClick={(evt) => {
					const urlParams = action.url_params || '';

					if (urlParams) {
						let urlParamsStr = '',
							urlParamsObj = {};
						for (let param in urlParams) {
							const val = urlParams[param];

							if (param === 'planid') {
								setL('payment_plan_id', val);
							} else if (param === 'plan_id') {
								setL('payment_plan_id_2', val); //Arun - payment_plan_id_2 is an alternative to store plan id for Master Class
							} else if (param === 'video_id') {
								setL('payment_plan_video_id', val);
							} else if (param === 'course_id') {
								setL('payment_plan_course_id', val);
							} else if (param === 'event_id') {
								setL('payment_plan_event_id', val);
							} else if (param === 'destination_page_path') {
								setL('destination_page_path', window.location.origin + val);
							} else {
								urlParamsObj[param] = val;
								setL(param, val);
							}
						}

						urlParamsStr = urlParamsStr + JSON.stringify(urlParamsObj);
						setL(VT_URL_PARAMS, urlParamsStr);
					}
					if (setSideMenuOpenState) {
						setSideMenuOpenState(false);
					}
					if (onClick) {
						onClick(evt);
					}
					closeHeaderNavbar();
				}}>
					{props.children}
				</RouterLink> : <>
					{props.children}
				</>}
			</>}
		</>);

	};

export default LinkWrapper;