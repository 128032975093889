import React, { Component } from 'react';

import { NavLink, Link } from 'react-router-dom';

import SearchForm from '../SearchForm';
import PopupMaster from '../popup/PopupMaster';
import { ShoppingCartContext } from '../ShoppingCartContext';

import '../../scss/Header.scss';
import { getRelativeUrl, clearUserDetails, setL } from '../../../util/lib';
import { LogoutUser } from '../../../util/authUtils';
import { ga4Event } from '../trackers/GA4Tracker';
import { debugLog } from '../../../util/logUtils';
import AutoPopup from '../popup/AutoPopup/AutoPopup';

const
	DD_MENU_ID = 'dropdown-menu',
	NAVBAR_DROPDOWN_MENU_LINK = 'NavbarDropdownMenuLink',
	NAVBAR_SUPPORTED_CONTENT = 'navbarSupportedContent',
	getURL4Item = (item = {}) => {
		const data = item.data || {},
			link = data.link || {}

		return link.url || '';
	};

class Header extends Component {
	constructor(props) {

		super(props);

		this.state = {
			error: null,
			header: null,

			settingsData: null,

			canShowHeaderLinksYN: null,
			canShowSearchBoxYN: null,
			showExploreButtonYN: null,
			showFreeTrailButtonYN: null,
			canShowUserInfoYN: null,
			canShowShoppingCartYN: false,
			hidden: false
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.hideCount !== prevProps.hideCount) {
			const document = window.document || '',
				navBar = (document && document.querySelector) ? document.querySelector(`#${NAVBAR_SUPPORTED_CONTENT}`) || '' : '';

			debugLog(this.props.hideCount);

			if (navBar) {
				navBar.className = String(navBar.className).replace('show', '');
			}
		}
	}

	render() {
		const state = this.state || {},
			props = this.props;

		if (!props.header) {
			return null;
		} else if (!props.settingsData) {
			return (null);
		} else {
			const settingsData = props.settingsData || {},
				logo = settingsData.logo,
				addFont = settingsData.accent_font || '',
				header = props.header || {},
				data = header.data || {},

				links = data.links || false,

				siteLogo = props.siteLogo || {},
				siteLogoData = siteLogo.data || {},
				siteLogoLinkObj = siteLogoData.link || {},
				siteLogoMetaObj = siteLogo.meta || {},
				siteLogoMeta = siteLogoMetaObj.logo || {},
				userInfo = data.user_info || false,

				searchWidget = data.search_widget || false,
				exploreButton = data.explore_button || false,
				premiumButton = data.premium_button || false,
				startTrialBtn = data.start_trial_button || data.start_trail_button || false,
				popups = data.popups || false,
				autoPopup = data.AutoPopup || false,
				logoClass = siteLogoMeta.size === 'large' ? 'app-logo-lg' : 'app-logo';

			return (
				<React.Fragment>
					<nav className="navbar navbar-expand-lg navbar-dark  site-header sticky-top">

						<Link to={getRelativeUrl(siteLogoLinkObj.url || '')} className="navbar-brand">
							<img src={logo} className={logoClass} alt="logo" />
						</Link>

						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#${NAVBAR_SUPPORTED_CONTENT}`} aria-controls={NAVBAR_SUPPORTED_CONTENT} aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon vt vt-menu"></span>
						</button>

						<div className="collapse navbar-collapse" id={NAVBAR_SUPPORTED_CONTENT}>
							<ul className="navbar-nav mr-auto all-links main-nav">
								{state.canShowHeaderLinksYN && links && links.map((item, index) => {
									const type = item.type;

									if (type === 'Link' || type === 'DropDown') {
										const iData = item.data || {},
											linkkObj = iData.link || {},
											linkURL = getRelativeUrl(linkkObj.url || ''),
											isActive = (type === 'Link' && props.page === '' && linkURL === '/home'),
											title = iData.title || '',
											clsName = 'nav-link' + (addFont ? ' ' + addFont : '') + (isActive ? ' active' : '') + ((type === 'DropDown') ? ' ' : ''),
											subMenu = iData.sub_menu || [];

										return (
											<li key={'lnk-' + index} className="nav-item">
												{type === 'Link' ? <NavLink to={linkURL} className={clsName + ' true-link'} activeClassName="active"
													onClick={() => {
														setL('lastVideoLocation', '');
													}}
												>
													{title}
												</NavLink> : <><a className={clsName + ' true-link'} href='/' id={NAVBAR_DROPDOWN_MENU_LINK + '-' + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span className="dropdown-toggle">{title}</span>
												</a>
													<div className={DD_MENU_ID + ' sub-link'} aria-labelledby={NAVBAR_DROPDOWN_MENU_LINK + '-' + index}>
														{subMenu.map((subLink, subIndex) => {
															const slData = subLink.data || {},
																sLinkkObj = slData.link || {},
																sLinkURL = getRelativeUrl(sLinkkObj.url || '');

															return (
																<Link key={DD_MENU_ID + subIndex} to={sLinkURL} className="dropdown-item">{slData.title}</Link>
															);
														})}
													</div></>}
											</li>
										);
									}
								})}
							</ul>

							{state.canShowSearchBoxYN && searchWidget &&
								<SearchForm data={searchWidget} inline={true} id={"lf_111"} onDataChange={() => { return; }} />
							}

							{state.showExploreButtonYN && exploreButton && ((item) => {
								const linkURL = getRelativeUrl(getURL4Item(item));
								if (item.type === 'Button') {
									// d-none d-lg-block
									return (
										<span className="navbar-text explore-button">
											<Link to={linkURL} className="btn btn-lg vt-btn vt-btn-link nav-link">
												{item.data.text} <i className="vt vt-browse " aria-hidden="true"></i>
											</Link>
										</span>
									);
								}
							})(exploreButton)}

							{state.showFreeTrailButtonYN && startTrialBtn && ((item) => {
								const linkURL = getRelativeUrl(getURL4Item(item));
								if (item.type === 'Button') {
									// d-none d-lg-block
									return (
										<span className="navbar-text start-trial-button">
											<Link to={linkURL} className="btn btn-lg vt-btn vt-btn-primary">
												{item.data.text}
											</Link>
										</span>
									);
								}
							})(startTrialBtn)}

							{premiumButton && ((item) => {
								const linkURL = getRelativeUrl(getURL4Item(item));
								if (item.type === 'Button') {
									// d-none d-lg-block
									return (
										<span className="navbar-text start-trial-button">
											<Link to={linkURL} className="btn btn-lg vt-btn vt-btn-primary">
												{item.data.text}
											</Link>
										</span>
									);
								}
							})(premiumButton)}

							{state.canShowUserInfoYN && userInfo && ((item) => {
								const linkURL = getRelativeUrl(getURL4Item(item)),
									profileDropDownID = 'profile' + NAVBAR_DROPDOWN_MENU_LINK,
									iData = item.data || {},
									name = iData.name || '';

								if (item.type === 'Link') {
									const popupURL = iData.link.popup_url || "";

									return (
										<span className="navbar-text login-button">
											{popupURL === "" &&

												<Link to={linkURL} className="btn btn-lg vt-btn vt-btn-primary">
													{iData.title}
												</Link>
											}
											{popupURL !== "" &&
												<Link to={popupURL}
													className="btn btn-lg vt-btn vt-btn-primary">
													{iData.title}
												</Link>
											}
										</span>
									);
								}
								if (item.type === 'ProfilePicture') {
									return (
										<ul className="navbar-nav user-profile-button">
											<li className="nav-item dropdown">
												<a className="nav-link dropdown-toggle user-icon" href="/" title={name} id={profileDropDownID} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span className="user-name">{name}</span>
													<i className="vt vt-usersolid" aria-hidden="true"></i>
												</a>
												<div className={DD_MENU_ID} aria-labelledby={profileDropDownID}>
													<span className="dropdown-item dd-name" onClick={(e = {}) => {
														e.preventDefault();
													}}>{name}</span>
													{iData.sub_menu.map((dItem, index) => {
														const dItemData = dItem.data || {},
															dLink = dItemData.link || {},
															smLinkURL = getRelativeUrl(dLink.url || '');

														if (dItem.type === 'Link') {
															if (smLinkURL.indexOf('profile') !== -1) {
																return (
																	<Link key={DD_MENU_ID + index} to={linkURL} className="dropdown-item">
																		{dItemData.title}
																	</Link>
																);
															} else if (smLinkURL.indexOf('logout') !== -1) {
																// debugger;
																return (
																	<span key={DD_MENU_ID + index} className="dropdown-item" onClick={() => {
																		ga4Event('logout', {method: "Ventuno"});
																		clearUserDetails();
																		LogoutUser();
																		window.location.reload(true);
																	}}>{dItemData.title}</span>
																);
															} else if (smLinkURL.indexOf('order') !== -1) {
																const orderLinkObj = dItemData.link || {},
																	orderLink = getRelativeUrl(orderLinkObj.url);

																return (
																	<Link key={DD_MENU_ID + index} to={orderLink} className="dropdown-item">{dItemData.title}</Link>
																)
															} else if (smLinkURL.indexOf('popular') !== -1) {
																const orderLinkObj = dItemData.link || {},
																	orderLink = getRelativeUrl(
																		orderLinkObj.url
																	);

																return (
																	<Link
																		key={DD_MENU_ID + index}
																		to={orderLink}
																		className="dropdown-item"
																	>
																		{dItemData.title}
																	</Link>
																);
															}else if (smLinkURL.indexOf('myClasses') !== -1) {
																const orderLinkObj = dItemData.link || {},
																	orderLink = getRelativeUrl(
																		orderLinkObj.url
																	);

																return (
																	<Link
																		key={DD_MENU_ID + index}
																		to={orderLink}
																		className="dropdown-item"
																	>
																		{dItemData.title}
																	</Link>
																);
															}
														}
														return false;
													})}
												</div>
											</li>
										</ul>
									);
								}
							})(userInfo)}

							<ShoppingCartContext.Consumer>
								{({ shopEnabled, itemCount, toggleCart, animateIcon }) => {
									return shopEnabled && state.canShowShoppingCartYN ? (<span className="navbar-icon">
										<div onClick={toggleCart} className={'vt-btn-like' + (animateIcon ? ' vn-shake' : '')}>
											<div className="shop-icon">
												<i className="fas fa-shopping-cart"></i>
											</div>
											{(itemCount && itemCount > 0) ? <div className="shop-item-count">{itemCount}</div> : ''}
										</div>
									</span>) : '';
								}}
							</ShoppingCartContext.Consumer>

						</div>
					</nav>
					{popups && <PopupMaster popups={popups} />}
					{autoPopup && <AutoPopup id="header-autopopup" data={autoPopup} />}
				</React.Fragment>
			);
		}
	}

	static getDerivedStateFromProps(props, state) {

		if (Object.keys(props.pageMeta).length > 0) {

			const header = props.header || '';

			const settingsData = props.settingsData || {};

			const hidden = props.hidden || false;

			const canShowShoppingCartYN = !(!props.canShowShoppingCartYN);

			const showExploreButtonYN = (typeof (props.pageMeta.showExploreButtonYN) !== 'undefined')
				? props.pageMeta.showExploreButtonYN
				: false;

			const canShowSearchBoxYN = (typeof (props.pageMeta.canShowSearchBoxYN) !== 'undefined')
				? props.pageMeta.canShowSearchBoxYN
				: true;

			const canShowHeaderLinksYN = (typeof (props.pageMeta.canShowHeaderLinksYN) !== 'undefined')
				? props.pageMeta.canShowHeaderLinksYN
				: true;

			const showFreeTrailButtonYN = (typeof (props.pageMeta.showFreeTrailButtonYN) !== 'undefined')
				? props.pageMeta.showFreeTrailButtonYN
				: false;

			const canShowUserInfoYN = (typeof (props.pageMeta.canShowUserInfoYN) !== 'undefined')
				? props.pageMeta.canShowUserInfoYN
				: true;

			return {
				header,
				settingsData,
				showExploreButtonYN,
				canShowSearchBoxYN,
				canShowHeaderLinksYN,
				showFreeTrailButtonYN,
				canShowUserInfoYN,
				canShowShoppingCartYN,
				hidden
			}
		}

		return null;
	}
}

export default Header;
