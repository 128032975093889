import {getEnvVar} from "./lib";

let dataFormData;
const OPTIONS = {
		method: 'POST',
		mode: 'cors'
	},
	SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG!',
	convertToFormData = (data = {}) => {
		try {
			const urlSearchParams = new URLSearchParams();
			let entry;

			for (entry in data) {
				urlSearchParams.append(entry, data[entry]);
			}
			return urlSearchParams;
		} catch (exception) {
			console.log(SOMETHING_WENT_WRONG);
		}
	},
	formData = (data, useFormData) => {
		if (useFormData === true) {
			dataFormData = new FormData();
			for (const name in data) {
				dataFormData.append(name, data[name]);
			}

			return dataFormData;
		} else {
			dataFormData = convertToFormData(data);
			return dataFormData;
		}
	};

export const post = (url = '', data = {}, useFormData = false) => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			...buildOptions(OPTIONS),
			// ...OPTIONS,
			body: formData(data, useFormData)
		})
			.then((response = {}) => {
				if (response.status === 200) {
					response
						.json()
						.then((data = {}) => {
							resolve(data);
						})
						.catch((exception = '') => {
							reject(exception);
						});
				} else {
					reject(response.status);
				}
			})
			.catch((exception = '') => {
				reject(exception);
			});
	});
};

export const buildOptions = (options = {}) => {
	const credentialsConfig = getEnvVar('REACT_APP_FETCH_CREDENTIALS');
	if(credentialsConfig && ['omit','same-origin','include'].includes(credentialsConfig)) {
		console.log('type:', typeof credentialsConfig, credentialsConfig);
		options =  {
			...options,
			credentials: credentialsConfig
		}
	}
	return options;
}