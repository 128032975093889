import React, { useState } from 'react';
import EpgStore from './EpgStore';

const EPGProvider = ({ children }) => {

	const [epgActive, setEpgActive] = useState(false);

	return (
		<EpgStore.Provider
			value={{
				epgActive,
				setEpgActive,
			}}>
			{children}
		</EpgStore.Provider>
	)
}

export default EPGProvider