import React from 'react';

import '../../scss/earth/EarthMaster.scss';
import { Helmet } from 'react-helmet';

const EarthMaster = () => {

	return (<Helmet>
		<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,800&display=swap" rel="stylesheet"></link>
	</Helmet>);
}

export default EarthMaster;