
import * as firebase from 'firebase/app';
import { getEnvVar } from './lib';
import { debugLog } from './logUtils';



export let firebaseInitStatus = false;
export let firebaseApp = false;

export const loadFireBase = () => {
	const configRaw = getEnvVar('REACT_APP_FIREBASE_CONFIG');

	console.log("Load firebase:",firebaseInitStatus);
	debugLog('%%%%%%%%%%%%%%%%%%%%%%', configRaw);

	if (configRaw) {
		let configParsed = '';
		try {
			configParsed = JSON.parse(String(configRaw));
		} catch (err) {
			debugLog(err);
			configParsed = '';
		}
		if (configParsed) {
			if(!firebaseInitStatus) {
				firebaseApp = firebase.initializeApp(configParsed);
				if (firebaseApp) {
					firebaseInitStatus = true;
					console.log("Load firebase:firebase status after:",firebaseInitStatus);
				}
			}
			return firebaseApp;
		}
	}
}

export const loadCaptcha = (captchaContainer = '', success, expired) => {
	const recaptchaConfig = {};
	if (!recaptchaConfig.recaptchaVerifier) {
		debugLog('laod captcha');

		recaptchaConfig.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(captchaContainer, {
			'size': 'normal',
			'callback': (response) => {
				debugLog("recaptcha solved");
				success();
			},
			'expired-callback': () => {
				debugLog("recaptcha not solved or expired");
				expired();
			}
		});
	}
	if (!recaptchaConfig.recaptchaWidgetId) {
		recaptchaConfig.recaptchaVerifier.render().then((widgetId) => {
			recaptchaConfig.recaptchaWidgetId = widgetId;
		});
	}
	return recaptchaConfig;
}

export const sendMobileOTP = (data = {}, success, failed) => {
	const dialCode = data.dialCode || '',
		phone = data.phone || '',
		appVerifier = data.appVerifier || '';
	if (dialCode && phone && appVerifier) {
		firebase.auth().signInWithPhoneNumber(dialCode + phone, appVerifier)
			.then((confirmationResult) => {
				debugLog("firebase ph otp sent");
				window.confirmationResult = confirmationResult;
				success();
			}).catch((error) => {
				failed(error);
				debugLog("firebase ph otp sent:", error);
			});
	} else {
		debugLog("send otp fb missing data");
	}
}

export const verifyMobileOTP = (data = {}, success, failed) => {
	if (window.confirmationResult) {
		const otp = data.otp || '';
		window.confirmationResult.confirm(otp).then((result) => {
			if (success) {
				success(result);
			}
		}).catch((error) => {
			if (failed) {
				failed(error);
			}
		});
	} else {
		debugLog("verify otp failed");
	}
}