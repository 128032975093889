import React, { Component } from 'react';
import './../scss/ProductQuantity.scss';
import PlusCircleFilled from '../svg/PlusCircle/PlusCircleFilled';
import MinusCircleFilled from '../svg/Minus/MinusCircleFilled';

const BTN_CLASS_TXT = 'btn btn-qty',
	genQuantityModifier = (add = true, quantity = 1) => {
		return () => {
			return add ? quantity + 1 : (quantity > 1) ? quantity - 1 : 1;
		};
	};

class ProductQuantity extends Component {
	constructor(props) {
		super(props);

		this.state = {
			quantity: props.quantity
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ quantity: nextProps.quantity });
	}

	render() {
		const onChange = this.props.onChange || '',
			quantity = parseInt(this.state.quantity) || 1;

		return (
			<div className="product-quantity">
				<span className="pr20">Quantity</span>
				<div className={BTN_CLASS_TXT + ((quantity <= 1) ? ' vt-disabld' : '')} field="quantity" onClick={() => {
					if (onChange) {
						onChange(genQuantityModifier(false, quantity)());
					}
				}} >
					<MinusCircleFilled/>
				</div>
				<span className="qty-num">{quantity}</span>
				<div className={BTN_CLASS_TXT} field="quantity" onClick={() => {
					if (onChange) {
						onChange(genQuantityModifier(true, quantity)());
					}
				}} >
					<PlusCircleFilled />
				</div>
			</div>
		);
	}
};

export default ProductQuantity;