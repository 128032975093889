import React from 'react';
import './scss/SpinnerLoader.scss';

const SpinnerLoader = (props) => {
    const { onPrimary = false,
        size = '' } = props,
        actualClassName = `spinner-loader ${onPrimary ? 'onPrimary' : ''} ${size}`;
    return (
        <div className={actualClassName}></div>
    )
}

export default SpinnerLoader