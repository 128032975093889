import React from 'react';

import './scss/FooterMenuJ.scss';

const FooterMenuJ = (props = {}) => {
	const widget = props.widget || {},
		data = widget.data || {},
		copyrightText = data.copyright_text || {},
		siteName = copyrightText.site_name || '',
		year = copyrightText.year || '';

	return (<div className="site-footer-j all-links d-flex flex-sm-row">
			<span className='d-inline-block'>
				<span className='site-name'>{(siteName ? siteName + ' ' : '')}</span><span>&copy; <span>{(year ? year + ' ' : ' ')} </span>{copyrightText.text || ''} </span>
			</span>
		</div>);
};

export default FooterMenuJ;