import React from 'react';
import { RouterContext } from './RouterContext'

const routerState = { // TEUX DEUX ~ after updating React move to useEffects
		route: '',
		setRoute: (route = '') => {
			routerState.route = route;
		}
	},
	RouterWrapper = (props = {}) => {
		const RouterProvider = RouterContext.Provider;

		return <RouterProvider value={routerState}>{props.children}</RouterProvider>
	};

export default RouterWrapper;