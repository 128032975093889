import React from 'react';
import { debugLog } from '../../../util/logUtils';
import widgetFactoryMap from './widgetFactoryMap'
import emptyWidgetFactoryMap from './emptyWidgetFactoryMap';

const getComponent = (type) => {
		if (type) {
			return widgetFactoryMap[type] ? widgetFactoryMap[type] : false;
		}
		return false;
	},
	getEmptyComponent = (type) => {
		if (type) {
			return emptyWidgetFactoryMap[type] ? emptyWidgetFactoryMap[type] : false;
		}
		return false;
	},
	widgetFactoryMapper = (type, props, optionalProps) => {
		let lProps = { ...props };


		if (type) {
			const component = optionalProps.dontRenderPage ? getEmptyComponent(type) : getComponent(type);

			debugLog("component:", component);
			debugLog("component:", lProps);

			if (component) {
				if (component.idPrefix) {
					lProps.id = component.idPrefix + lProps.id;
					lProps.key = component.idPrefix + lProps.key;
				}
				if (component.addFont) {
					lProps.addFont = optionalProps.addFont;
				}
				if (component.passType) {
					lProps.type = type;
				}
				if (component.onDataChange) {
					lProps.onDataChange = optionalProps.onDataChange;
				}
				if (component.temp_other) {
					lProps.temp_other = optionalProps.temp_other;
				}
				if (component.handlers) {
					lProps.handlers = optionalProps.handlers;
				}
				if (component.passFullProps) {
					//pass down all the props content to the component with id,key and others
					lProps = {
						...optionalProps.fullProps,
						...lProps
					}
				}

				return React.createElement(component.type, { ...lProps });
			}
		}
		return false;
	};



export default widgetFactoryMapper;