import React from 'react';

import '../../scss/earth/FooterMenuE.scss';
import { Link } from 'react-router-dom';
import { getRelativeUrl } from '../../../util/lib';

const FooterMenuE = (props = {}) => {
	const widget = props.widget || {},
		data = widget.data || {},
		copyrightText = data.copyright_text || {},
		year = copyrightText.year || '',
		siteLogoObj = data.site_logo || {},
		siteLogoData = siteLogoObj.data || {},
		siteLogoLink = siteLogoData.link || {},
		siteLogoURL = siteLogoLink.url || '';	

	return (<div className="site-footer-e all-links container d-flex flex-sm-row justify-content-between">
		<span className='d-inline-block'>
			{/* {//TEUX DEUX} */}
			{siteLogoURL && <Link to={getRelativeUrl(siteLogoURL)} >
				<img src="https://v2s3z9v2.stackpathcdn.com/videos/3337/17-03-2020/ait_150x64__WR5V8SXD.png" className="app-logo" alt="logo"/>
			</Link>}
			
				<span>&copy; <span>{(year ? year + ' ' : '') + (copyrightText.site_name || '')}. </span>{copyrightText.text || ''} </span>
			</span>
		</div>);
};

export default FooterMenuE;