import React, { useContext, useEffect, useState } from 'react'
import HybridFooter from '../../hybrid/footer/HybridFooter';
import FooterJ from '../../jupiter/FooterJ';
import FooterE from '../../earth/FooterE';
import Footer from '../../Footer';
import { HeaderContext } from '../../header/HeaderContext';
import FooterStore from '../../hybrid/footer/FooterStore';

const FooterManager = (props = {}) => {
	const { theme, settingsData = {}, termsConsentCheck } = props,
		{ isFooterV2 = false } = settingsData,
		{ headerLoaded, firstPageLoaded } = useContext(HeaderContext),
		[canRender, setCanRender] = useState(false),
		{hidden} = useContext(FooterStore);

	useEffect(() => {
		if (!canRender && firstPageLoaded && headerLoaded) {
			setCanRender(true);
		}
	}, [headerLoaded, firstPageLoaded]);

	let footerJSX = '';

	if (isFooterV2) {
		footerJSX = <HybridFooter termsConsentCheck={termsConsentCheck} settingsData={settingsData} hidden={hidden}/>;
	} else {
		if (theme === '2') {
			footerJSX = <FooterE termsConsentCheck={termsConsentCheck} settingsData={settingsData} />;
		} else if (theme === '3') {
			footerJSX = <FooterJ termsConsentCheck={termsConsentCheck} settingsData={settingsData} />;
		} else { //default theme = 1
			footerJSX = <Footer termsConsentCheck={termsConsentCheck} settingsData={settingsData} />;
		}
	}

	return <>
		{canRender ? footerJSX : null}
	</>;

};

export default FooterManager;
