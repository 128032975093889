import React, { useState } from 'react';
import popupStore from './popupStore';

const PopupProvider = ({ children }) => {

	const [activePopup, setActivePopup] = useState('');

	return (
		<popupStore.Provider
			value={{
				activePopup,
				setActivePopup,
			}}>
			{children}
		</popupStore.Provider>
	)
}

export default PopupProvider