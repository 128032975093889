import React, { Component } from 'react';
import { generateRandomID, getEnvVar } from '../../../util/lib';
import { gaEvent } from '../../../util/trackerUtil';
import { ga4Event } from '../trackers/GA4Tracker';
import LimitedViewingPopup from './LimitedViewingPopup';

import './scss/VentunoPlayer.scss';
import CommentBoxWrapper from './CommentBoxWrapper';
import LeftArrow from '../../svg/IconV2-2/LeftArrow';
import LiveChat from '../../svg/IconV2-2/LiveChat';
import MiniEPGWrapper from './MiniEPGWrapper';


var playerLoadKey, //usefull states to check for playerStateadd chng on player load
	playerState, //holds the players states like ratio, video key etc
	watchedInterval = 0,

	watchedSecond = 0,
	resizeTimer = 0,
	toggleTimer = 0;

const VIDEO = 'video',
	START = 'start',
	COMPLETE = 'complete',
	CONTENT = 'content',
	AD = 'ad',
	CONTENT_COMPLETED = CONTENT + '_' + COMPLETE,
	CONTENT_START = CONTENT + '_' + START,
	AD_COMPLETED = AD + '_' + COMPLETE,
	AD_START = AD + '_' + START,
	COMPLETED = 'completed',
	PAUSE = 'pause',
	RESUME = 'resume',
	RESIZE = 'resize',
	IDLE = 'idle',
	LANDSCAPE = 'landscape',
	PORTRAIT = 'portrait',
	// CLOSE_BTN_SVG = <svg style={SVG_STYLE} viewBox="0 0 34 33"><path d="M17.6808838,21.9940853 L7.16833976,28.0635054 C4.99395212,29.3188887 4.25104177,32.1000894 5.50750863,34.2763538 C6.76141884,36.44819 9.54469022,37.1994531 11.7184088,35.9444561 L22.2309528,29.875036 L28.300373,40.38758 C29.5557562,42.5619677 32.336957,43.304878 34.5132214,42.0484112 C36.6850576,40.7945009 37.4363207,38.0112296 36.1813237,35.837511 L30.1119036,25.324967 L40.6244476,19.2555468 C42.7988352,18.0001635 43.5417456,15.2189628 42.2852787,13.0426984 C41.0313685,10.8708622 38.2480971,10.1195991 36.0743786,11.3745961 L25.5618345,17.4440162 L19.4924144,6.9314722 C18.2370311,4.75708456 15.4558304,4.0141742 13.2795659,5.27064107 C11.1077298,6.52455128 10.3564667,9.30782265 11.6114637,11.4815412 L17.6808838,21.9940853 Z" transform="rotate(-15 -6.189 46.745)"></path> </svg>,
	//util
	ratioCheck = (_ratio = '16:9') => {
		return (_ratio === '4:3') ? '4:3' : (_ratio === '1:1') ? '1:1' : '16:9';
	},
	processLimitedViewing = (data = '') => {

		const limit = parseInt(data.limit) || 0, // TEUX DEUX
			interval = parseInt(data.interval) || 0, // TEUX DEUX
			recurring = !(!(interval && data.recurring));

		if (data && limit) {
			return { ...data, limit, interval, recurring };
		} else {
			return false
		}
	},
	endResizeTimer = () => {
		clearInterval(resizeTimer);
		resizeTimer = 0;
	},
	clearWatchedInterval = () => {
		window.clearInterval(watchedInterval);
		watchedInterval = 0;
	},
	clearToggleTimer  = () => {
		clearInterval(toggleTimer);
		toggleTimer = 0;
	};

class VentunoPlayer extends Component {
	// constructor
	constructor(props) {
		const nameSpace = 'vtn-rp-',
			ranIDNum = generateRandomID(7),
			preload = props.preload || false;

		super(props);

		this.state = {
			holderDivId: nameSpace + "h-" + ranIDNum,
			wrprDivId: nameSpace + "w-" + ranIDNum,
			playerDivId: nameSpace + "p-" + ranIDNum,
			sideDivId: nameSpace + "s-" + ranIDNum,
			topPannelId: nameSpace + "tp-" + ranIDNum,
			limitedViewing: false,
			limitedViewingCycleDone: false,
			showLimitedViewingPopup: false, // use as flag to show LimitedViewingPopup,
			playbackStarted: false,
			playerRendered: false,
			exitOnComplete: true,
			toggleOpenCommentBox: false, 
			showCommentBox: false,
			openCommentBox: false,
			expandCommentBox: false,
			orientation: LANDSCAPE
		};
		playerState = {
			videoKey: preload ? IDLE : props.videoKey || IDLE,
			ratio: ratioCheck(props.ratio),
			pageTakeOver: props.pageTakeOver || false,
			targetID: props.targetID || '',
		}

		playerLoadKey = playerState.videoKey;

		this.getPlayerAPI = this.getPlayerAPI.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onCloseClick = this.onCloseClick.bind(this);

		this.onCompleted = this.onCompleted.bind(this);
		this.onContentCompleted = this.onContentCompleted.bind(this);
		this.onStart = this.onStart.bind(this);
		this.onResume = this.onResume.bind(this);
		this.onPause = this.onPause.bind(this);
		this.onContinue = this.onContinue.bind(this);
		this.onGenericTrackingEvent = this.onGenericTrackingEvent.bind(this);

		this.setUpView = this.setUpView.bind(this);
		this.renderPlayer = this.renderPlayer.bind(this);
		this.resetPlayer = this.resetPlayer.bind(this);
		this.onChatToggleClick = this.onChatToggleClick.bind(this);

		// player config hack for website player comm
		window.__vtnOttWebPlayerConfig = window.__vtnOttWebPlayerConfig || {};
		window.__vtnOttWebPlayerConfig.disableKeyboardControls = true;
	}

	//lifecycle methods

	componentDidMount() {
		this.setUpView()
		// this.renderPlayer();
	}

	componentWillUnmount() { //clear stuff
		const t = this,
			// btn = t.state.button || '',
			player = t.state.player || '';

		if (player) {
			player.stop();

			t.removePlayerTrackers(player);
		}

		endResizeTimer();

		window.removeEventListener(RESIZE, t.onResize);
	}



	componentDidUpdate() {
		const t = this,
			state = t.state,
			props = t.props,
			player = (state.player && state.player.loadVideo) ? state.player : '',
			oldKey = playerState.videoKey,
			preload = props.preload || false,
			realKey = props.videoKey || IDLE,
			newKey = preload ? IDLE : realKey,
			resume = props.resume || false,
			forceBegin = props.forceBegin || false,
			exitOnComplete = !!props.exitOnComplete,
			showCommentBox = props.commentBox && state.playerRendered && playerState.pageTakeOver && playerState.videoKey && playerState.videoKey !== IDLE;
		
		if (state.showCommentBox !== showCommentBox) {
			t.setState({
				showCommentBox
			}, () => {
				t.onResize();
			});
		}

		if (newKey && (oldKey !== newKey)) {
			let needTransition = false;
			const setInPageTakeOver = props.setInPageTakeOver || false, 
				setPlayerActive = props.setPlayerActive || null,
				ratio = ratioCheck(props.ratio || state.ratio),
				pageTakeOver = newKey === IDLE ? false : props.pageTakeOver || false,
				limitedViewing = newKey === IDLE ? false : processLimitedViewing(props.limitedViewing),
				targetId = props.targetID ? props.targetID : false,
				targetDiv = targetId ? document.querySelector('#' + targetId) || '' : '',
				hldr = document.querySelector('#' + state.holderDivId),
				topPannel = document.querySelector('#' + state.topPannelId);

			if (setPlayerActive) {
				props.setPlayerActive(!(newKey === IDLE));
			}

			playerState = {
				...playerState, ...{
					videoKey: newKey,
					ratio,
					pageTakeOver,
					targetID: targetId,
				}
			};
			topPannel.style.display = 'none';
			
			if (pageTakeOver) {
				topPannel.style.display = 'block';
			}

			if (setInPageTakeOver) {
				setInPageTakeOver(pageTakeOver ? true : false);
			}

			if (pageTakeOver && targetDiv) {
				const targetDivRect = targetDiv.getBoundingClientRect() || {};
				
				hldr.style.position = 'fixed';
				hldr.style.width = (targetDivRect.width || 0) + 'px';
				hldr.style.height = (targetDivRect.height || 0) + 'px';
				hldr.style.top = (targetDivRect.top || targetDivRect.y || 0) + 'px';
				hldr.style.left = (targetDivRect.left || targetDivRect.x || 0) + 'px';

				needTransition = true;
			}

			hldr.className = `ventuno-player ${state.orientation} ${needTransition ? ' anim-transition' : ''}`;

			setTimeout(() => {
				this.setState({
					limitedViewingCycleDone: false,
					showLimitedViewingPopup: false,
					limitedViewing, resume,
					forceBegin,
					exitOnComplete
				}, () => {
					t.setState({
						showCommentBox: t.props.commentBox && t.state.playerRendered && playerState.pageTakeOver && playerState.videoKey && playerState.videoKey !== IDLE
					}, () => {
						if (player) {
							window.setTimeout(() => {
								t.resetPlayer(player, newKey, resume, forceBegin);
							}, 300);
						} else {
							t.onResize();
							playerLoadKey = playerState.videoKey;
							setTimeout(() => {
								t.renderPlayer();
							}, 400);
						}
					});
				});
			}, needTransition ? 300: 1);
		}
	}

	//listners
	// onGenericTrackingEvent2 (evt = {}) {
	// 	console.log('HTML', evt.type);
	// 	console.log('HTML', evt);
	// }

	onChatToggleClick(evt = {}) {
		
		this.setState({toggleOpenCommentBox: !this.state.toggleOpenCommentBox}, () => {
			const state = this.state,
				toggleOpenCommentBox = state.toggleOpenCommentBox;

			console.log(toggleOpenCommentBox, 'feee');
			clearToggleTimer();

			if (toggleOpenCommentBox) {
				this.setState({openCommentBox: true});
				this.onResize();
				toggleTimer = window.setTimeout(() => {
					this.setState({expandCommentBox: true});	
					this.onResize();
					clearToggleTimer();
					toggleTimer = window.setTimeout(() => {
						this.onResize();
						clearToggleTimer();
					}, 500);
				}, 200);
			} else {
				this.setState({expandCommentBox: false});	
				this.onResize();
				toggleTimer = window.setTimeout(() => {
					this.setState({openCommentBox: false});	
					this.onResize();
					clearToggleTimer();
					toggleTimer = window.setTimeout(() => {
						this.onResize();
						clearToggleTimer();
					}, 500);
				}, 200);
			}
		})
	} 

	onGenericTrackingEvent(evt = {}) {
		const type = evt.type,
			state = this.state,
			props = this.props,
			playbackStart = () => {
				if (!state.playbackStarted) {
					this.setState({ playbackStarted: true }, () => gaEvent('PlaybackStart', VIDEO));
				}
			};


		switch (type) {
			case CONTENT_START:
				// expandCommentBox
				gaEvent('ContentStart', VIDEO);
				ga4Event('video_content_start', {
					content_type: 'Video',
					video_id: props.videoId
				});
				playbackStart();
				break;
			case CONTENT_COMPLETED:
				
				gaEvent('ContentCompleted', VIDEO);
				ga4Event('video_content_complete', {
					content_type: 'Video',
					video_id: props.videoId
				});
				gaEvent('PlaybackCompleted', VIDEO);
				this.setState({ playbackStarted: false });
				break;
			case AD_START:
				gaEvent('AdStart', VIDEO);
				ga4Event('video_ad_start', {
					content_type: 'Video',
					video_id: props.videoId
				});
				playbackStart();
				break;
			case AD_COMPLETED:
				gaEvent('AdCompleted', VIDEO);
				ga4Event('video_ad_complete', {
					content_type: 'Video',
					video_id: props.videoId
				});
				break;
			default: 
				break;
		}
	}

	onCompleted() {
		const { exitOnComplete = true } = this.state;
		if (exitOnComplete) {
			this.onCloseClick();
		}
	}

	onContentCompleted() {
		this.setState({ showLimitedViewingPopup: false }, () => {
			clearWatchedInterval();
		});
	}

	onStart() {
		watchedSecond = 0;
		this.startWatchedInterval();
	}

	onResume() {
		this.startWatchedInterval();
	}

	onPause() {
		clearWatchedInterval();
	}

	onContinue() {
		this.setState({ showLimitedViewingPopup: false }, () => {
			const state = this.state,
				player = (state.player && state.player.loadVideo) ? state.player : '';

			watchedSecond = 0;
			clearWatchedInterval();

			if (player) {
				this.startWatchedInterval();
				player.resume();
			}
		});
	}

	onCloseClick() {
		const t = this,
			state = t.state,
			onCloseClickCount = t.props.onCloseClickCount || false,
			player = (state.player && state.player.loadVideo) ? state.player : '';

		t.setState({ miniEpgData: false });

		clearToggleTimer();
		this.setState({toggleOpenCommentBox: false});
		this.setState({openCommentBox: false});
		this.setState({expandCommentBox: false});


		if (player) {
			if (onCloseClickCount) {
				onCloseClickCount();
			}
			player.stop();
			if (t.props.onIdle) {
				t.props.onIdle();
			}

			t.removePlayerTrackers(player);

			watchedSecond = 0;
			clearWatchedInterval();

			t.props.setVideoDetails({});
			t.onResize();
		}
	}

	onResize() {
		const t = this,
			state = t.state,
			player = (state.player && state.player.loadVideo) ? state.player : '',
			idle = playerState.videoKey === IDLE,
			pageTakeOver = idle ? false : playerState.pageTakeOver,
			padding = 31, //state.showCommentBox ? 0 : 39, 16
			fillUp = '100%',
			targetDiv = playerState.targetID ? document.querySelector('#' + playerState.targetID) || '' : '',
			hldr = document.querySelector('#' + state.holderDivId),
			heightRatio = (playerState.ratio === '4:3') ? 3 / 4 : (playerState.ratio === '1:1') ? 1 : 9 / 16,
			wrapper = document.querySelector('#' + state.wrprDivId),
			sidePannel = document.querySelector('#' + state.sideDivId);
			// btn = state.button || '';

		let focusTargetDiv = false,
			wrpWidth = pageTakeOver ? window.innerWidth : idle ? 1 : (hldr.clientWidth || hldr.offsetWidth || hldr.scrollWidth),
			wrprHeight = pageTakeOver ? idle ? 1 : window.innerHeight : wrpWidth * heightRatio,
			targetTop = 0,
			targetLeft = 0,
			targetDivRect,
			windowScrollY = parseInt(window.scrollY) || 0;

		window.__vtnOttWebPlayerConfig.pageTakeOver = !(!pageTakeOver);

		if (targetDiv) {
			if (idle) {
				targetDiv.style.width = targetDiv.style.height = 0;
				targetDiv.style.display = 'none';
			} else if (pageTakeOver) {
				targetDiv.style.visibility = 'hidden';
			} else {
				
				targetDiv.style.visibility = 'visible';
				targetDiv.style.display = 'block';
				targetDiv.style.width = '100%';
				wrpWidth = targetDiv.clientWidth || targetDiv.offsetWidth || targetDiv.scrollWidth;
				wrprHeight = wrpWidth * heightRatio;
				targetDiv.style.height = wrprHeight + 'px';

				targetDivRect = targetDiv.getBoundingClientRect();

				targetTop = (targetDivRect.top || 0) + windowScrollY;
				targetLeft = targetDivRect.left || 0;

				focusTargetDiv = true;
			}
		}

		t.setState({orientation : (wrpWidth > wrprHeight) ? LANDSCAPE : PORTRAIT }, () => {
			const tState = this.state,
				tOrientation = tState.orientation || LANDSCAPE;

			hldr.style.position = pageTakeOver ? 'fixed' : 'absolute';
			hldr.style.width = (pageTakeOver || focusTargetDiv) ? wrpWidth + 'px' : idle ? 0 : fillUp;
			hldr.style.height = wrprHeight + 'px';
			hldr.style.top = targetTop + 'px';
			hldr.style.left = targetLeft + 'px';
			hldr.style.zIndex = pageTakeOver ? 9999 : focusTargetDiv ? 999 : 'auto';
			
			wrapper.style.height = pageTakeOver ? (tOrientation === PORTRAIT && tState.showCommentBox && tState.expandCommentBox) ? '60%' : (wrprHeight - (padding)) + 'px' : idle ? 0 : fillUp;
			wrapper.style.top = 0;
			
			if (sidePannel) {
				sidePannel.style.height = (pageTakeOver && tOrientation === PORTRAIT && tState.showCommentBox && tState.expandCommentBox) ? '35%' : tOrientation === PORTRAIT ? 0 : (wrprHeight - (padding)) + 'px';
				sidePannel.style.top = (pageTakeOver && tOrientation === PORTRAIT && tState.showCommentBox && tState.expandCommentBox) ? '65%' : tOrientation === PORTRAIT ? '100%' : 0;
			}
			
			document.body.style.overflow = pageTakeOver ? "hidden" : "auto";
			
			if (player && player.resize) {
				player.resize();
			}
		});
	}

	//limited watching
	startWatchedInterval() {
		clearWatchedInterval();
		// watchedInterval = window.setInterval(() => {
		// 	this.checkLimitedWatching();
		// }, 1000);
	}

	checkLimitedWatching() {
		const state = this.state,
			{ limitedViewingCycleDone, limitedViewing } = state,
			player = (state.player && state.player.loadVideo) ? state.player : '';

		if (limitedViewing && player) {
			if (!limitedViewingCycleDone) {
				const limit = limitedViewing.limit;
				watchedSecond += 1;

				// if (watchedSecond >= 3) { // TEUX DEUX
				if (watchedSecond >= limit) {
					watchedSecond = 0;
					this.setState({
						showLimitedViewingPopup: true,
						limitedViewingCycleDone: true
					});
					player.fullScreenOff();
					// player.pause();
				}
			} else if (limitedViewing.recurring) {
				const interval = limitedViewing.interval;
				watchedSecond += 1;
				// if (watchedSecond >= 5) { // TEUX DEUX
				if (watchedSecond >= interval) {
					watchedSecond = 0;
					this.setState({
						showLimitedViewingPopup: true
					});
					player.fullScreenOff();
				}
			}
		}
	}

	//view methods

	setUpView() {
		const t = this;
		
		window.addEventListener(RESIZE, t.onResize);
		// resizeTimer = setInterval(() => {
		// 	t.onResize();
		// }, 500);

		t.onResize();
	}

	//player methods
	setPlayerTrackers(player) {
		const t = this;

		if (playerState.pageTakeOver) {
			player.addEventListener(COMPLETED, t.onCompleted);
		}

		if (t.state.limitedViewing) {
			player.addEventListener(CONTENT_START, t.onStart);
			player.addEventListener(CONTENT_COMPLETED, t.onContentCompleted);
			player.addEventListener(RESUME, t.onResume);
			player.addEventListener(PAUSE, t.onPause);
		}

		player.addEventListener(CONTENT_START, t.onGenericTrackingEvent);
		player.addEventListener(CONTENT_COMPLETED, t.onGenericTrackingEvent);
		player.addEventListener(AD_START, t.onGenericTrackingEvent);
		player.addEventListener(AD_COMPLETED, t.onGenericTrackingEvent);
		player.addEventListener('replay', t.onGenericTrackingEvent);
		// player.addEventListener('completed', t.onGenericTrackingEvent2);
		// player.addEventListener('ad_complete', t.onGenericTrackingEvent2);
		// player.addEventListener('content_complete', t.onGenericTrackingEvent2);
	}

	removePlayerTrackers(player) {
		const t = this;

		player.removeEventListener(COMPLETED, t.onCompleted);

		player.removeEventListener(CONTENT_START, t.onStart);
		player.removeEventListener(CONTENT_COMPLETED, t.onContentCompleted);
		player.removeEventListener(RESUME, t.onResume);
		player.removeEventListener(PAUSE, t.onPause);

		player.removeEventListener(CONTENT_START, t.onGenericTrackingEvent);
		player.removeEventListener(CONTENT_COMPLETED, t.onGenericTrackingEvent);
		player.removeEventListener(AD_START, t.onGenericTrackingEvent);
		player.removeEventListener(AD_COMPLETED, t.onGenericTrackingEvent);
	}

	resetPlayer(player, newKey, resume = false, forceBegin = false,) {
		const t = this;

		t.removePlayerTrackers(player);

		watchedSecond = 0;
		clearWatchedInterval();

		t.setState({ playbackStarted: false }, () => {
			if (newKey === IDLE) {
				player.stop();
				if (t.props.onIdle) {
					t.props.onIdle();
				}
			} else {
				t.setPlayerTrackers(player);

				setTimeout(() => {
					player.loadVideo(newKey, '', '', resume, forceBegin);
				}, 400);
			}
		});

		t.onResize();
	}

	getPlayerAPI(_player) {
		var t = this,
			videoKey = playerState.videoKey;

		_player.addEventListener('reload_ready', (_event) => {
			var evt = (typeof _event === 'object' && _event),
				type = evt.type || '',
				data = evt.data || {};

			if (type === 'reload_ready') {
				let videoData = data.videoData || {},
					vKey = videoData.vKey || '';

				if (false) {
					console.log(vKey);
				}
			}
		});

		t.setPlayerTrackers(_player);
		t.setState({ player: _player });

		//in case the key has changed before player load
		if (playerLoadKey !== videoKey) {
			t.resetPlayer(_player, videoKey);
		}
	}
	
	renderPlayer () {
		const t = this,
			state = t.state;
			
		if (!state.playerRendered) {
			t.setState({playerRendered: true}, () => {
				const vKey = playerState.videoKey,
					preload = vKey === IDLE,
					resume = state.resume || false,
					forcedBegin = state.forceBegin || false,
					autoStartObj = t.props.startMuted ? {
						'autoplay': 'true',
						'muted': 'true'
					}: {},
					qaPlayer = getEnvVar('REACT_APP_ENV') === 'development' || getEnvVar('REACT_APP_ENV') === 'test';

				let jsTag;

				window.__ventunoplayer = window.__ventunoplayer||[];
				window.__ventunoplayer.push({
					'resume' : resume,
					'forcedBegin' : forcedBegin,
					'preload' : preload,
					'video_key': preload ? '' : vKey,
					'holder_id': t.state.playerDivId,
					'player_type': 'vp',
					'async': 1,
					'layout': 'fillfit',
					'get_player_api' : t.getPlayerAPI,
					...autoStartObj
				});
				
				jsTag = document.createElement("script");
				jsTag.type = "application/javascript";
				jsTag.src = qaPlayer ? 'https://www.ventunotech.com/plugins/webplayer/ventunoSmartPlayer.qa.js' : 'https://pl.ventunotech.com/plugins/cntplayer/ventunoSmartPlayer.js'; //TEUX DEUX
				// jsTag.src = "https://www.ventunotech.com/plugins/webplyer.dev2/ventunoSmartPlayer.dev.js"; //TEUX DEUX
				// jsTag.src = "https://www.ventunotech.com/plugins/webplayer.dev/ventunoSmartPlayer.dev.js"; //TEUX DEUX
				// jsTag.src = "https://pl.ventunotech.com/plugins/cntplayer/ventunoSmartPlayer.js"; //TEUX DEUX
				// jsTag.src = "https://www.ventunotech.com/plugins/webplayer.dev/ventunoSmartPlayer.dev.js"; //TEUX DEUX
				document.body.appendChild(jsTag);
			});
		}
	}

	//react component method

	render() {
		const btnStyle = {
			position: 'absolute',
			width: '25px',
			height: '25px',
			cursor: 'pointer',
			right: '8px',
			top: '8px',
			fill: 'black',
			backgroundColor: 'rgba(255,255,255,0.7)',
			borderRadius: '4px',
			display: 'none'
		},
			btnStyleInner = {
				position: 'absolute',
				width: '15px',
				height: '15px',
				left: '5px',
				top: '5px',
				lineHeight: '0.5em'
			},
			wrprStyle = {
				top: 0,
				left: 0,
				width: '100%',
				position: 'absolute',
			},
			hldrStyle = {
				...wrprStyle, ...{
					position: 'relative',
					padding: 0,
					backgroundColor: '#010101',
					zIndex: 'auto'
				}
			},
			props = this.props,
			epgData = props.epgData || false, state = this.state,
			limitedViewing = state.limitedViewing,
			commentBox = props.commentBox || false,
			showCommentBox = state.showCommentBox || false,
			openCommentBox = (showCommentBox && state.openCommentBox) || false,
			expandCommentBox = (openCommentBox && state.expandCommentBox),
			orientation = state.orientation,
			holderDivClassName = 'ventuno-player ' + orientation;

		return (
			<div id={state.holderDivId} className={holderDivClassName}>
				<div className={`player-wrapper${openCommentBox ? ' show-sp' : ''}${expandCommentBox ? ' expand-sp' : ''}`} id={state.wrprDivId}>
					<div id={state.playerDivId}></div>
					{epgData && state.playerRendered && playerState.videoKey && playerState.videoKey !== IDLE ? <MiniEPGWrapper data={epgData}></MiniEPGWrapper> : null}
					{limitedViewing && state.showLimitedViewingPopup && <LimitedViewingPopup data={limitedViewing} onContinue={this.onContinue}></LimitedViewingPopup>}
					{showCommentBox && <div className='comment-box-toggle' onClick={this.onChatToggleClick}>
						<LiveChat />
					</div>}
				</div>
				<div id={state.sideDivId} className={`side-pannel${openCommentBox ? ' show' : ''}${expandCommentBox ? ' expand' : ''}`}>
					{showCommentBox && openCommentBox ? <CommentBoxWrapper  data={commentBox} onClose={this.onChatToggleClick}></CommentBoxWrapper>: 'null'}
				</div>
				<div className='top-pannel' id={state.topPannelId}>
					<div className='close-button' onClick={this.onCloseClick}>
						<LeftArrow />
					</div>
				</div>
				
			</div>
		);
	}
}

export default VentunoPlayer;


// const trackerEvent = 'ViewContent';

// pixelTrack(trackerEvent);
// gaEvent(trackerEvent);