import React, { useEffect, useRef, useState } from 'react';
import { SWIPE_LEFT, SWIPE_RIGHT, TOUCH_END, TOUCH_MOVE, TOUCH_START } from './constants';
import TouchDetect from './TouchDetect';

const TouchDiv = ({touchEnabled = false, onSwipeLeft = '', onSwipeRight = '', ...rest}) => {
	const divRef = useRef(null),
		[swipeDir, setSwipeDir] = useState('');

	useEffect(()=> {
		if (swipeDir === SWIPE_LEFT && onSwipeLeft) {
			onSwipeLeft();
		} else if (swipeDir === SWIPE_RIGHT && onSwipeRight) {
		 	onSwipeRight();
		}
	}, [swipeDir]);

	useEffect(() => {
		const touchDiv = divRef && divRef.current ? divRef.current : null;
		if (touchEnabled && touchDiv) { // TEUX DEUX
			let startX = 0,
				distX = 0,
				elapsedTime = 0,
				startTime = 0;

			const threshold = 85, //required min distance traveled to be considered swipe
				allowedTime = 400, // maximum time allowed to travel that distance
				onTouchListner = (evt = {}) => {
					const type = evt.type || '',
						touchObj = evt.changedTouches && evt.changedTouches.length ? evt.changedTouches[0] : '';

					if (type === TOUCH_MOVE) {
						evt.preventDefault();
					} else if (type === TOUCH_START) {
						if (touchObj) {
							startX = touchObj.pageX;
						 	startTime = new Date().getTime();
							setSwipeDir('');
						}
					} else if (type === TOUCH_END) {
						if (touchObj) {
							distX = touchObj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
							elapsedTime = new Date().getTime() - startTime; // get time elapsed


							if (elapsedTime <= allowedTime){ // first condition for awipe met
								if (Math.abs(distX) >= threshold){ // 2nd condition for horizontal swipe met
									setSwipeDir((distX < 0) ? SWIPE_LEFT : SWIPE_RIGHT) // if dist traveled is negative, it indicates left swipe
								}
							}
						}
					}

					// 
				};

			touchDiv.addEventListener(TOUCH_START, onTouchListner, false);
			touchDiv.addEventListener(TOUCH_MOVE, onTouchListner, false);
			touchDiv.addEventListener(TOUCH_END, onTouchListner, false);

			return () => {
				touchDiv.removeEventListener(TOUCH_START, onTouchListner, false);
				touchDiv.removeEventListener(TOUCH_MOVE, onTouchListner, false);
				touchDiv.removeEventListener(TOUCH_END, onTouchListner, false);
			};
		}

	}, [touchEnabled, divRef]);

	return <div ref={divRef} {...rest}></div>
};


export default TouchDetect(TouchDiv);
