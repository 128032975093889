import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { createUserSession, preserveUserData } from '../../../util/authUtils';
import { getAPIData, getUrlFromBaseAPI } from '../../../util/lib';
import { showNotification } from '../../../util/notification';
import DotLoader from '../AnimatedComponents/DotLoader';

import './scss/CommonAuthHelper.scss';

const LiblynxAuth = (props = {}) => {
    const { message = 'Authenticating using Liblynx ...',
        location = {}, history = false } = props,
        { search = {} } = location,
        //using memo to prevent repeated parsing of query string
        parseSearchQuery = React.useMemo(() => new URLSearchParams(search), [search]),
        llch = parseSearchQuery.get('_llch'),  // set by Liblynx
        llca = parseSearchQuery.get('_llca'), // set by Liblynx
        suid = parseSearchQuery.get('suid') || '',
        postLoginRedirection = parseSearchQuery.get("postLoginRedirection");

    console.log('parseSearchQuery', parseSearchQuery);
    console.log('parseSearchQuery', llch, llca, suid, postLoginRedirection);

    useEffect(() => {
        if (llch && llca) {
            const authURL = getUrlFromBaseAPI("login_liblynx_user"),
                postData = {
                    _llch: llch,
                    _llca: llca,
                    suid
                };
            if (authURL) {
                getAPIData(authURL, postData, response => {
                    const { type = '', data: responseData = {} } = response,
                        {
                            token = "",
                            refresh_token = "",
                            user = {},
                            successURL = {} } = responseData,
                        { path = '/' } = successURL;
                    if (type === 'Success') {

                        if (createUserSession('localstore', {
                            token,
                            refreshToken: refresh_token
                        })) {
                            preserveUserData(user.data);
                            console.log("uuuu:", postLoginRedirection);
                            if (postLoginRedirection) {
                                window.location.href = postLoginRedirection;
                            } else if (history) {
                                history.replace(path);
                            }
                        }
                    } else {
                        const { message: errMessage = '' } = responseData;
                        showNotification({ type: "error", text: errMessage });
                    }
                });
            } else {
                console.error("liblynxAuth:", "authURL not found");
            }
        }
    }, [llch, llca]);

    return (
        <div className='fullpageloder'>
            <DotLoader />
            <p className="message">{message}</p>
        </div>
    )
}

export default withRouter(LiblynxAuth);