import React, { Component } from 'react';
import { getUserDetails } from '../../../util/lib';

import FireBaseWrapper from '../firebase/FireBaseWrapper';
import UserBox from './UserBox';

import '../../scss/videoComments/VideoComments.scss';
import { generateRandomID } from '../../../util/lib';

const VIDEO = 'video-',
	VIDEO_MESSAGE = VIDEO + 'message',
	VIDEO_COMMENTS = VIDEO + 'comments';

class VideoComments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollID: VIDEO_COMMENTS + '-' + generateRandomID(6),
			resetPos: 0,
			scrollTimeout: 0
		};

		this.onScroll = this.onScroll.bind(this);
	}

	onScroll(evt = {}) {
		const scrollDiv = evt.target || '';

		if (scrollDiv) {
			const scrollTop = scrollDiv.scrollTop;

			if (scrollTop === 0) {
				const resetPos = scrollDiv.scrollHeight || 0;
				scrollDiv.removeEventListener('scroll', this.onScroll);
				this.setState({ resetPos }, () => {
					this.props.loadMore();
				});
			}
		}
	}

	setScroll() {
		const scrollDiv = window.document.querySelector(`#${this.state.scrollID}`);

		if (scrollDiv) {
			scrollDiv.removeEventListener('scroll', this.onScroll);
			scrollDiv.addEventListener('scroll', this.onScroll);
		}
	}

	scrollBottom(overRide = 0) {
		const scrollDiv = window.document.querySelector(`#${this.state.scrollID}`);

		if (scrollDiv) {
			scrollDiv.scrollTop = scrollDiv.scrollHeight - overRide;
		}
	}

	componentDidMount() {
		this.setScroll();
	}

	componentDidUpdate({ newMessageCount }) {
		if (this.props.newMessageCount !== newMessageCount) {
			this.scrollBottom();
		} else {
			this.setScroll();
			this.scrollBottom(this.state.resetPos || 0);
		}
		
		// console.log('weeeee', this.props.messages.length, messages.length);
		
		//if (this.props.newMessageCount !== newMessageCount) {
			// console.log('weeeee', this.state.resetPos);
			//this.scrollBottom();
		//} else {
			// console.log('weeeee2', this.state.resetPos);
			//this.setScroll();
			//this.scrollBottom(this.state.resetPos || 0);
		//}
	}

	componentWillUnmount() {
		const scrollDiv = window.document.querySelector(`#${this.state.scrollID}`);

		if (scrollDiv) {
			scrollDiv.removeEventListener('scroll', this.onScroll);
		}
	}

	render() {
		const messages = this.props.messages || [],
			user = getUserDetails(),
			userId = user.id || "";
		console.log(userId, 'chat-test');

		return (
			<div className={VIDEO_COMMENTS} id={this.state.scrollID}>
				{messages.map((message = {}, index) => {
					const text = message.message || '',
						nickname = message.nickname || '',
						name = message.user_name || '',
						id = message.user_id || '',
						profilePicture = message.user_profile_image || '',
						time = message.time_formatted || '',
						displayName = nickname || name;

					console.log(id === userId, 'chat-test');

					return (
						<div className={VIDEO_MESSAGE + (id === userId ? " self-comments" : "")} key={'vc-' + index}>
							{id !== userId ? <div style={{ display: 'flex' }}>
								{profilePicture ? (
									<div className="profile-container">
										<img className="profile-image" alt="" src={profilePicture} />
									</div>
								) : (
									<div className="user-pic">
										<UserBox />
									</div>
								)}
								<div className="msg-area">
									<div className='wrapper-info'>
										<div className="msg-name">{displayName}</div>
										<div className="msg-text">{text}</div>
									</div>
									<div className="msg-time">{time}</div>
								</div>
							</div> :
								<div style={{ display: 'flex' }}>
									<div className="msg-area">
										<div className='wrapper-info'>
											<div className="msg-name">{displayName}</div>
											<div className="msg-text">{text}</div>
										</div>
										<div className="msg-time">{time}</div>
									</div>
									{profilePicture ? (<div className="profile-container">
										<img className="profile-image" alt="" src={profilePicture} />
									</div>
									) : (
										<div className="user-pic">
											<UserBox />
										</div>
									)}
								</div>}

						</div>
					);
				})}
			</div>
		);
	}
}

export default FireBaseWrapper(VideoComments);
