import React, { useEffect } from 'react';
import { isGDPREnabled } from '../../util/gdprUtils';
import { setL } from '../../util/lib';
import { GDPRStore } from './GDPRStore';



const GDPRProvider = ({ children }) => {

    const [gdprEnabled, setGdprEnabled] = React.useState(false),
        [tcData, setTcData] = React.useState({}),
        [otherVendors, setOtherVendors] = React.useState({});

    useEffect(() => {
        setGdprEnabled(isGDPREnabled(tcData));
        setL('cmp-tcdata', tcData);
    }, [tcData]);

    useEffect(() => {
        setL('cmp-othervendors', otherVendors);
    }, [otherVendors]);
    
    return (
        <GDPRStore.Provider
            value={{
                tcData,
                setTcData,
                gdprEnabled,
                setGdprEnabled,
                otherVendors,
                setOtherVendors
            }}>
            {children}
        </GDPRStore.Provider>
    );
}

export default GDPRProvider;