import React, { Component } from 'react';

import './../scss/ShoppingCart.scss';

import ShoppingCartContent from './ShoppingCartContent';
import { ShoppingCartContext } from './ShoppingCartContext';
import CoverScreen from './ui/CoverScreen';

class ShoppingCart extends Component {
	
	render() {			
		return (
			<ShoppingCartContext.Consumer>
				{({isOpen, close, cartContent}) => {	
					const { title, cards} = cartContent;

					return (<div className={'shopping-cart' + (isOpen ? ' open ' : '')}>
						<CoverScreen className="screen" onClick={close} show={isOpen} />
						<div className="cart-area">	
							<div className="ca-wrpr">
								<div className="container">
									<div className="row">
										<ShoppingCartContent title = {title} cards = {cards}/>
									</div>
									<span className="vt-btn-like" onClick={close}>
										<div className="close-icon">
											<i className="fas fa-times"></i>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>);
				}}
			</ShoppingCartContext.Consumer>
		);
	}
}

export default ShoppingCart;