import React, { Component } from 'react';

export class ErrorBoundaryDefault extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error("Error Boundary default: ",error);
    }

    render() {
        const { hasError } = this.state,
             {children} = this.props;

        if (hasError) {
            return (
                <></>
            );
        }
        return children;
    }
}

export default ErrorBoundaryDefault;
